import React, { useEffect, useState, useContext, useCallback } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR, enAU, es } from "date-fns/locale";

import moment from "moment";

import AsyncListSelect from "../../components/ListSelect/AsyncListSelect";
import { Search } from "./styles";

import { FormattedMessage } from "react-intl";

import Language from "../../context/language";

const SodexoFaturamento = ({ dataFilter }) => {
  const timeoutRef = React.useRef(null);
  const [loadingList, setLoadingList] = useState(true);
  const [dataDeState, setDataDeState] = useState("");

  const [labels, setLabels] = useState([]);
  const [graph, setGraph] = useState([]);

  const { language } = useContext(Language);

  let loadGraph = {
    series: graph,
    options: {
      chart: {
        type: "line",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          })
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "R$ " +  (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          },
        }
      },
      colors: ["#FE2E2E", "#FE9A2E", "#FACC2E", "#C8FE2E", "#64FE2E",
      "#2EFE64", "#00FF80", "#00FFBF", "#01A9DB", "#045FB4",
      "#2E2EFE", "#2F0B3A", "#5F04B4", "#FE2EF7",
      "#DF0174", "#585858", "#BDBDBD"],
      xaxis: {
        categories: labels,
      },
      grid: {
        padding: {
          left: 45,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
    },
  };


  const loadBase = useCallback(async (dataDeState, e) => {
    try {
      const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
      const dataAte = moment().format("YYYY-MM-DD 23:59:59");


      let baseFormatted = [];

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      if (e !== "") {
        const base = () => new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(async ()=> {
            timeoutRef.current = null;

            const { data: response } = await api.get("/utilitarios/get-base-faturado",
            {
              params: {
                data_de: !dataFilter?.dataDeFilter
                  ? dataDe
                  : dataFilter?.dataDeFilter + " 00:00:00",
                data_ate: !dataFilter?.dataAteFilter
                  ? dataAte
                  : dataFilter?.dataAteFilter + " 23:59:59",
              },
            }
          );

          baseFormatted = response.map((base) => {
            const objeto = {
              value: base.base_id,
              label: base.nome_base,
            };

            return objeto;
          });

            resolve(baseFormatted)

          }, 1000);

        }).then(
          resolve => {
            return resolve
          }
        ).catch(
          reject => {
            console.log(reject)
          }
        )

        await base()

        return new Promise((resolve) => {
          return resolve(
            baseFormatted?.filter((i) =>
              i?.label?.toLowerCase().includes(e?.toLowerCase())
            )
          );
        });
      } else {
        const { data: response } = await api.get("/utilitarios/get-base-faturado",
        {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
          },
        }
      );

      baseFormatted = response.map((base) => {
        const objeto = {
          value: base.base_id,
          label: base.nome_base,
        };

        return objeto;
      });

      return new Promise((resolve) => {
        return resolve(
          baseFormatted?.filter((i) =>
            i?.label?.toLowerCase().includes(e?.toLowerCase())
          )
        );
      });

      }
    } catch (error) {}
  }, [dataFilter]);

  useEffect(() => {
    (async () => {
      try {

        setDataDeState(dataFilter?.dataDeFilter)

        loadBase()

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, loadBase]);

  const handleFilter = useCallback(async (e) => {
    try {
      const dataDe = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
      const dataAte = moment().format("YYYY-MM-DD 23:59:59");

      const { data: response } = await api.get(
        `/dashboard/precos-medios-litros-basedistribuicao`,
        {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
            cpf_cnpj: dataFilter?.cnpjFilter
              ?.replaceAll(".", "")
              ?.replaceAll("/", "")
              ?.replaceAll("-", "")
              ?.replaceAll("_", ""),
            grupo_economico: dataFilter?.grupoEconomicoFilter,
            base_distribuicao_id: e?.value,
            municipio_id: dataFilter?.cidadeBasesDist?.value,
            estado_id: dataFilter?.estadoBasesDist?.value,
          },
        }
      );

      const dist = Object.keys(response);

      let formattedListData = [];
      dist.map((item) => {
        let data = [];
        response[item].forEach((i) => {
          data.push(i.preco_medio_litros);
        });

        return formattedListData.push({ name: item, data: data });
      });

      setGraph(formattedListData);

      let setLanguage = ptBR;
      if (language === "en") {
        setLanguage = enAU;
      } else if (language === "es") {
        setLanguage = es;
      } else if (language === "pt_BR") {
        setLanguage = ptBR;
      }

      let formattedListLabel = [];
      dist.map((item) => {
        let label = [];
        response[item].forEach((i) => {
          const date = i.data_group.split("-");
          label.push(
            format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
              locale: setLanguage,
            })
          );
        });

        return formattedListLabel.push({ label });
      });

      setLabels(formattedListLabel[0].label);

      setLoadingList(false);
    } catch (error) {
      setLoadingList(false);
    }
}, [dataFilter, language])

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoBasesDistPrecosMedios" />
            </PanelHeader>
            <PanelBody className="p-0">

              <Search>
                <AsyncListSelect
                  key={dataDeState}
                  placeholder={"Selecione uma base de distribuição para carregar o gráfico"}
                  onChange={(e) => {
                    handleFilter(e)
                  }}
                  loadOptions={(e) => {
                    return loadBase(dataDeState, e);
                  }}
                  cacheOptions
                  defaultOptions
                />
              </Search>

              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="400px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default SodexoFaturamento;

import styled from "styled-components";
import AsyncSelect from "react-select/async";

export const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
  position: relative;

  p {
    margin-bottom: 0px !important;
    padding-left: 10px;
    font-weight: bold;
    color: #221c46;
  }
`;

export const List = styled(AsyncSelect).attrs({})`
  width: 100% !important;
  margin-bottom: 10px;
  font-size: 13px;
  font-family: sans-serif;

  .css-6r6yav-control{
    border-color: #221c46!important;
    box-shadow: 0 0 0 1px #221C46!important

  }
  .css-6r6yav-control:hover{
    border-color: #221c46!important;
    box-shadow: 0 0 0 1px #221C46!important
  }

  .filter {
    &__option {

      &--is-selected {
        background-color: #FBDDDA!important;
        color: #FF7375!important;
        font-weight: bold
      }
    }
  }
`;

export const MessageError = styled.div`
  width: 100%;
  height: 100%;

  align-items: center;
  height: 10px !important;
  transition: all 0.7s;
  margin-top: -5px;

  p {
    width: max-content;
    height: 100%;
    font-size: 11px;
    color: #B30000;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    width: max-content;
  }
`;

import React from "react";

import { List, Container, MessageError } from "./styles";

const CreatableListSelect = React.forwardRef((props, ref) => {
  return (
    <Container>
      {props.labelPlaceholder && <p>{props.labelPlaceholder}</p>}
      <List
        ref={ref}
        {...props}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          border: "#d9e0e7",
          colors: {
            ...theme.colors,
            primary25: "#e05262 !important",
            primary: "#221c46 !important",
          },
        })}
      />
      {props.geterror && (
        <MessageError>
          <div>
              <p>{props.geterror}</p>
          </div>
        </MessageError>
      )}
    </Container>
  );
});

export default CreatableListSelect;

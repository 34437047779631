import React from "react";
import Loader from "./../../../assets/images/loader.svg";
import { Container } from "./styles";

const Screen = ({ children }) => {
  return (
    <Container>
      <img src={Loader} alt="loader" />
      {children}
    </Container>
  );
};

export default Screen;

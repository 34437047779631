import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useContext,
} from "react";
import { useTable, usePagination } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

import { format, parseISO } from "date-fns";
import { FormattedMessage } from "react-intl";

import { FiEdit, FiEye, FiTrash2 } from "react-icons/fi";
import { FaHistory } from "react-icons/fa";
import { Container } from "./styles";
import api from "./../../../../../services/api";
import MaskInput from "./../../../../../config/input-mask";
import iconFiltro from "./../../../../../assets/images/icone-filtro.png";
import UploadNFDistribuidor from "./../../../../../components/UploadNFDistribuidor";
import DownloadBoleto from "./../../../../../components/DownloadBoleto";
import TextTableFormatted from "./../../../../../components/TextTableFormatted";
import ButtonIcon from "./../../../../../components/ButtonIcon";
import InputMaskDefault from "./../../../../../components/InputMaskDefault";
import ButtonImage from "./../../../../../components/ButtonImage";
import OptionsList from "./../../../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../../../components/OptionsList/OptionsIconPlaceholder";
import InputLabel from "./../../../../../components/InputLabel";
import ListSelect from "./../../../../../components/ListSelect";
import ContentSpinner from "./../../../../../components/Spinner/Content";
import AsyncListSelect from "./../../../../../components/ListSelect/AsyncListSelect";
import iconVoltar from "./../../../../../assets/images/icone-voltar.svg";

import ExcelLogo from "./../../../../../assets/images/logo-excel.png";

import Paginate from "./../../../../../components/Paginate";

import AuthContext from "./../../../../../context/auth";

import ReactExport from "react-data-export";
//import AuthContext from "./../../../../../context/auth";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const PainelList = () => {
  // Estados da lista painel

  const [listaPainel, setListaPainel] = useState([]);

  const [loadingList, setLoadingList] = useState(false);
  const [loadingListFilter, setLoadingListFilter] = useState(false);

  const [filterNumeroPedido, setFilterNumeroPedido] = useState("");
  const [filterNumeroPedidoCliente, setFilterNumeroPedidoCliente] =
    useState("");
  const [filterDataPedido, setFilterDataPedido] = useState("");
  const [filterCliente, setFilterCliente] = useState("");
  // const [filterOperador, setFilterOperador] = useState("");
  const [filterProduto, setFilterProduto] = useState("");
  // const [filterDistribuidorTRR, setFilterDistribuidorTRR] = useState("");
  // const [filterPrazoPagamento, setFilterPrazoPagamento] = useState("");
  const [filterDataEntrega, setFilterDataEntrega] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  // const [filterIntegrado, setFilterIntegrado] = useState("");
  const [filterNotaDitribuidor, setFilterNotaDistribuidor] = useState("");
  // const [filterBoleto, setFilterBoleto] = useState("");
  const [filterRespondido, setFilterRespondido] = useState("");
  // const [responsavelOptions, setResponsavelOptions] = useState([]);
  // const [filterResponsavel, setFilterResponsavel] = useState("");
  const [filterButton, setFilterButton] = useState(false);
  const [filterCotacaoOriginal, setFilterCotacaoOriginal] = useState("");

  // Paginação
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const history = useHistory();

  const refExcel = createRef(null);

  const { user } = useContext(AuthContext);

  const statusOptions = [
    {
      value: "Análise Cliente",
      label: <FormattedMessage id="analiseCliente" />,
    },
    { value: "Análise Distribuidor/TRR", label: "Análise Distribuidor/TRR" },
    { value: "Análise Mesa de Operação", label: "Análise Mesa de Operação" },
    {
      value: "Reanálise Distribuidor/TRR",
      label: <FormattedMessage id="reanalise" />,
    },
    { value: "Aprovado", label: <FormattedMessage id="aprovado" /> },
    { value: "Reprovado", label: <FormattedMessage id="reprovado" /> },
    { value: "cancelado", label: <FormattedMessage id="cancelado" /> },
    { value: "faturado", label: <FormattedMessage id="faturado" /> },
  ];

  const notaDistribuidor = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const boleto = [
    { value: "s", label: <FormattedMessage id="opcaoSim" /> },
    { value: "n", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const handleHistory = useCallback(
    (pedido, cotacao) => {
      const data = {
        pedido,
        cotacao,
      };
      history.push("/orders/painel/history", data);
    },
    [history]
  );

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.numeroPedido || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.numeroPedidoCliente || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.numeroPedidoWizeo || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.dataPedido || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.cliente || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.operador || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },

        {
          value: item?.produtoExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },

        {
          value: item?.valorTotalExcel || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.distribuidorTRR || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.prazoPagamento || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.dataEntrega || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.status || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.integrado || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "Nº Pedido Sistema",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Nº Pedido Cliente",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Carga Wizeo",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Data",
            width: { wpx: 120 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Cliente",
            width: { wpx: 300 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Operador",
            width: { wpx: 120 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Produtos",
            width: { wpx: 250 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Volume M³",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Distribuidores/TRR",
            width: { wpx: 300 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Prazo de Pagamento",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Data Entrega",
            width: { wpx: 80 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },

          {
            title: "Status Pedido",
            width: { wpx: 150 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Integrado",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      const today = new Date();
      const formattedDate = format(today, "dd/MM/yyyy");

      localStorage.getItem("dataFilterStorage")

      var storedArray = localStorage.getItem("dataFilterStorage");
      const dataFilterStorage = JSON.parse(storedArray);

      var dataFilter = {}

      if (history.location?.state?.statusCard) {
        const status = history.location?.state?.statusCard
        dataFilter = {
          status_filtro_cotacao: status,
          "order_by[campo]": "id",
          "order_by[order]": "desc",
          data_criacao_de: moment().startOf('month').format('YYYY-MM-DD 00:00:00'),
          data_criacao_ate: moment().endOf('month').format('YYYY-MM-DD 23:59:59'),
          limit: 40,
        };

        localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

      }  else if (history.location?.state?.painelControle) {
        dataFilter = dataFilterStorage
      } else {
        dataFilter = {
          created_at: formattedDate,
          "order_by[campo]": "id",
          "order_by[order]": "desc",
        };
      }

      try {
        setLoadingList(true);
        const { data: response } = await api.post(
          "/pedido/filter",
          {
            cadastro_distribuidor_id: user.cadastro_id,
          },
          {
            params: dataFilter,
          }
        );

        // const responsavel = Object.keys(response.ultimo_editor_preco).map(
        //   (key) => {
        //     return {
        //       value: response.ultimo_editor_preco[key]?.id,
        //       label: response.ultimo_editor_preco[key]?.name,
        //     };
        //   }
        // );
        // setResponsavelOptions(responsavel);

        response.dados.data.forEach((pedido) => {
          let obj = {};

          pedido.cotacoes.forEach((cotacao) => {
            let produtoFormatted = [];
            let arrayQuantidadeTotal = [];
            let valorTotalFormatted = 0;

            arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
              return parseFloat(prod.quantidade / 1000)
                .toFixed(2)
                .replace(".", ",");
            });

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            produtoFormatted = cotacao?.produtos_cotacao.map((prod) => {
              return prod.produto.descricao;
            });

            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedido: pedido.numero_pedido_sistema,
              numeroCotacao: cotacao.numero_cotacao_sistema,
              numeroPedidoWizeo: cotacao.numero_pedido_sodexo,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy"),
              cliente:
               (pedido?.status_pedido === "Aprovado" ||
                pedido?.status_pedido === "Aguardando Faturamento" ||
                pedido?.status_pedido === "Faturado") && (
                  cotacao?.status_cotacao === "Aprovado" ||
                  cotacao?.status_cotacao === "Faturado"
                )
                  ? cotacao?.cliente?.cpf_cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    ) +
                    " - " +
                    cotacao?.cliente?.razao_social
                  : "",
              cotacaoOriginal: cotacao?.cotacao_original?.pedido?.numero_pedido_sistema,
              produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              // prazoPagamento: pedido?.prazo,
              dataEntrega: format(
                parseISO(pedido.data_entrega_desejada),
                "dd/MM/yyyy"
              ),
              status: cotacao?.status_cotacao,
              respondido:
                cotacao?.status_cotacao !== "Análise Distribuidor/TRR" &&
                cotacao?.status_cotacao !== "Reanálise Distribuidor/TRR"
                  ? "Sim"
                  : "Não",
              valorTotal: (
                <TextTableFormatted labelFormatted={valorTotalFormatted} />
              ),
              integrado:
                cotacao.status_integracao === 0
                  ? "Não"
                  : cotacao.status_integracao === 1
                  ? "Sim"
                  : "Erro",
              operador: pedido?.operador?.name,
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: (
                <FaHistory
                  size={18}
                  onClick={() => handleHistory(pedido.id, cotacao.id)}
                />
              ),
              notaDistribuidor: (
                <UploadNFDistribuidor
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_distribuidor_nome}
                />
              ),
              boleto: cotacao.boleto_cliente_nome && (
                <DownloadBoleto
                  idCotacao={cotacao.id}
                  hasBoleto={cotacao.boleto_cliente_nome}
                />
              ),
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
            });

            setListaPainel((state) => [...state, obj]);
          });
        });

        setTotal(response?.dados?.total);
        setFirstPage(response?.dados?.first_page_url);
        setLastPage(response?.dados?.last_page);
        setLastPageUrl(response?.dados?.last_page_url);
        setNextPage(response?.dados?.next_page_url);
        setPrevPage(response?.dados?.prev_page_url);
        setCurrentPage(response?.dados?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.dados.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.dados?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, [handleHistory, user.cadastro_id, history]);

  const handleFilter = useCallback(async () => {
    const dataFilter = {
      numero_pedido_sistema: filterNumeroPedido,
      numero_pedido_cliente: filterNumeroPedidoCliente,
      created_at: filterDataPedido,
      cliente: filterCliente,
      // distribuidor: filterDistribuidorTRR,
      // prazo: filterPrazoPagamento,
      data_entrega_desejada: filterDataEntrega,
      status_filtro_cotacao: filterStatus,
      // operador_id: filterOperador,
      // ultimo_editor_preco: filterResponsavel,
      produto_id: filterProduto,
      // boleto_cliente_nome: filterBoleto,
      nota_distribuidor_nome: filterNotaDitribuidor,
      // status_integracao: filterIntegrado,
      cadastro_distribuidor_id: user.cadastro_id,
      respondido: filterRespondido,
      cotacao_id_original: filterCotacaoOriginal,
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    /** zero a tabela para mostrar somente o retorno */
    setListaPainel([]);

    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });

      response.dados.data.forEach((pedido) => {
        let obj = {};

        pedido.cotacoes.forEach((cotacao) => {
          let produtoFormatted = [];
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              .replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          produtoFormatted = cotacao?.produtos_cotacao.map((prod) => {
            return prod.produto.descricao;
          });

          obj = {};
          Object.assign(obj, {
            original: pedido,
            id: pedido.id,
            id_cotacao: cotacao.id,
            numeroPedido: pedido.numero_pedido_sistema,
            numeroCotacao: cotacao.numero_cotacao_sistema,
            numeroPedidoWizeo: cotacao.numero_pedido_sodexo,
            numeroPedidoCliente: pedido.numero_pedido_cliente,
            dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy"),
            cliente:
              (pedido?.status_pedido === "Aprovado" ||
              pedido?.status_pedido === "Aguardando Faturamento" ||
              pedido?.status_pedido === "Faturado") && (
                cotacao?.status_cotacao === "Aprovado" ||
                cotacao?.status_cotacao === "Faturado"
              )
                ? cotacao?.cliente?.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.cliente?.razao_social
                : "",
            cotacaoOriginal: cotacao?.cotacao_original?.pedido?.numero_pedido_sistema,
            produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
            distribuidorTRR: cotacao.distribuidor
              ? cotacao?.distribuidor.cpf_cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                ) +
                " - " +
                cotacao?.distribuidor.razao_social
              : "",
            // prazoPagamento: pedido?.prazo,
            dataEntrega: format(
              parseISO(pedido.data_entrega_desejada),
              "dd/MM/yyyy"
            ),
            status: cotacao?.status_cotacao,
            respondido:
              cotacao?.status_cotacao !== "Análise Distribuidor/TRR" &&
              cotacao?.status_cotacao !== "Reanálise Distribuidor/TRR"
                ? "Sim"
                : "Não",
            valorTotal: (
              <TextTableFormatted labelFormatted={valorTotalFormatted} />
            ),
            integrado:
              cotacao.status_integracao === 0
                ? "Não"
                : cotacao.status_integracao === 1
                ? "Sim"
                : "Erro",
            cargaWizeo: "1234567 - Fixo",
            operador: pedido?.operador?.name,
            responsavel: cotacao?.ultimo_editor_preco?.name,
            historico: (
              <FaHistory
                size={18}
                onClick={() => handleHistory(pedido.id, cotacao.id)}
              />
            ),
            notaDistribuidor: (
              <UploadNFDistribuidor
                idCotacao={cotacao.id}
                hasNF={cotacao.nota_distribuidor_nome}
              />
            ),
            boleto: cotacao.boleto_cliente_nome && (
              <DownloadBoleto
                idCotacao={cotacao.id}
                hasBoleto={cotacao.boleto_cliente_nome}
              />
            ),
            cotacaoOriginalExcel:
              cotacao?.cotacao_original?.numero_cotacao_sistema,
            produtoExcel: produtoFormatted.toString(),
            valorTotalExcel: valorTotalFormatted.toString(),
            distribuidor_id: cotacao?.distribuidor?.id,
            distribuidor_preferencia_id:
              cotacao?.cliente?.distribuidor_preferencia_id,
          });

          setListaPainel((state) => [...state, obj]);
        });
      });

      setTotal(response?.dados?.total);
      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });
      setLoadingListFilter(false);
      setFilterButton(false);
    } catch (error) {
      console.log(error);

      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [
    filterNumeroPedido,
    filterNumeroPedidoCliente,
    filterDataPedido,
    filterCliente,
    // filterDistribuidorTRR,
    // filterPrazoPagamento,
    filterDataEntrega,
    filterStatus,
    // filterOperador,
    // filterResponsavel,
    filterProduto,
    // filterBoleto,
    filterNotaDitribuidor,
    // filterIntegrado,
    user.cadastro_id,
    filterRespondido,
    handleHistory,
    filterCotacaoOriginal
  ]);

  const handleRemoveFilter = useCallback(async () => {
    const today = new Date();
    const formattedDate = format(today, "dd/MM/yyyy");

    const dataFilter = {
      numero_pedido_sistema: "",
      numero_pedido_cliente: "",
      created_at: formattedDate,
      cliente: "",
      distribuidor: "",
      prazo: "",
      data_entrega_desejada: "",
      status_filtro_cotacao: "",
      operador_id: "",
      ultimo_editor_preco: "",
      produto_id: "",
      boleto_cliente_nome: "",
      nota_distribuidor_nome: "",
      status_integracao: "",
      cadastro_distribuidor_id: user.cadastro_id,
      respondido: "",
      cotacao_id_original: "",
      "order_by[campo]": "id",
      "order_by[order]": "desc",
    };

    localStorage.setItem("dataFilterStorage",JSON.stringify(dataFilter));

    /** zero a tabela para mostrar somente o retorno */
    setListaPainel([]);

    setLoadingListFilter(true);
    setFilterButton(true);
    try {
      const { data: response } = await api.get("/pedido/filter", {
        params: dataFilter,
      });

      response.dados.data.forEach((pedido) => {
        let obj = {};

        pedido.cotacoes.forEach((cotacao) => {
          let produtoFormatted = [];
          let arrayQuantidadeTotal = [];
          let valorTotalFormatted = 0;

          arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
            return parseFloat(prod.quantidade / 1000)
              .toFixed(2)
              .replace(".", ",");
          });

          if (arrayQuantidadeTotal.length > 0) {
            valorTotalFormatted = arrayQuantidadeTotal;
          }

          produtoFormatted = cotacao?.produtos_cotacao.map((prod) => {
            return prod.produto.descricao;
          });

          obj = {};
          Object.assign(obj, {
            original: pedido,
            id: pedido.id,
            id_cotacao: cotacao.id,
            numeroPedido: pedido.numero_pedido_sistema,
            numeroCotacao: cotacao.numero_cotacao_sistema,
            numeroPedidoWizeo: cotacao.numero_pedido_sodexo,
            numeroPedidoCliente: pedido.numero_pedido_cliente,
            dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy"),
            cliente:
              (pedido?.status_pedido === "Aprovado" ||
              pedido?.status_pedido === "Aguardando Faturamento" ||
              pedido?.status_pedido === "Faturado") && (
                cotacao?.status_cotacao === "Aprovado" ||
                cotacao?.status_cotacao === "Faturado"
              )
                ? cotacao?.cliente?.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.cliente?.razao_social
                : "",
            cotacaoOriginal: cotacao?.cotacao_original?.pedido?.numero_pedido_sistema,
            produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
            distribuidorTRR: cotacao.distribuidor
              ? cotacao?.distribuidor.cpf_cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                ) +
                " - " +
                cotacao?.distribuidor.razao_social
              : "",
            // prazoPagamento: pedido?.prazo,
            dataEntrega: format(
              parseISO(pedido.data_entrega_desejada),
              "dd/MM/yyyy"
            ),
            status: cotacao?.status_cotacao,
            respondido:
              cotacao?.status_cotacao !== "Análise Distribuidor/TRR" &&
              cotacao?.status_cotacao !== "Reanálise Distribuidor/TRR"
                ? "Sim"
                : "Não",
            valorTotal: (
              <TextTableFormatted labelFormatted={valorTotalFormatted} />
            ),
            integrado:
              cotacao.status_integracao === 0
                ? "Não"
                : cotacao.status_integracao === 1
                ? "Sim"
                : "Erro",
            cargaWizeo: "1234567 - Fixo",
            operador: pedido?.operador?.name,
            responsavel: cotacao?.ultimo_editor_preco?.name,
            historico: (
              <FaHistory
                size={18}
                onClick={() => handleHistory(pedido.id, cotacao.id)}
              />
            ),
            notaDistribuidor: (
              <UploadNFDistribuidor
                idCotacao={cotacao.id}
                hasNF={cotacao.nota_distribuidor_nome}
              />
            ),
            boleto: cotacao.boleto_cliente_nome && (
              <DownloadBoleto
                idCotacao={cotacao.id}
                hasBoleto={cotacao.boleto_cliente_nome}
              />
            ),
            cotacaoOriginalExcel:
              cotacao?.cotacao_original?.numero_cotacao_sistema,
            produtoExcel: produtoFormatted.toString(),
            valorTotalExcel: valorTotalFormatted.toString(),
            distribuidor_id: cotacao?.distribuidor?.id,
            distribuidor_preferencia_id:
              cotacao?.cliente?.distribuidor_preferencia_id,
          });

          setListaPainel((state) => [...state, obj]);
        });
      });

      setTotal(response?.dados?.total);
      setFirstPage(response?.dados?.first_page_url);
      setLastPage(response?.dados?.last_page);
      setLastPageUrl(response?.dados?.last_page_url);
      setNextPage(response?.dados?.next_page_url);
      setPrevPage(response?.dados?.prev_page_url);
      setCurrentPage(response?.dados?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.dados?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.dados?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });
      setLoadingListFilter(false);
      setFilterButton(false);
    } catch (error) {
      console.log(error);

      setFilterButton(false);
      setLoadingListFilter(false);
    }
  }, [handleHistory, user]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleDisplay = useCallback(
    async (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;

      const newObj = data.original;

      history.push("/orders/painel/show", newObj);
    },
    [history]
  );

  const handleEdit = useCallback(
    (data) => {
      delete data.original?.historico;
      delete data.original?.produto;
      delete data.original?.valorTotal;
      delete data.original?.notaDistribuidor;
      delete data.original?.boleto;

      const newObj = data.original;

      history.push("/orders/painel/edit", newObj);
    },
    [history]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="numPedidoSistema" />,
        accessor: "numeroPedido",
      },
      {
        Header: <FormattedMessage id="numPedidoCliente" />,
        accessor: "numeroPedidoCliente",
      },
      {
        Header: <FormattedMessage id="dataCriacao" />,
        accessor: "dataPedido",
      },
      {
        Header: <FormattedMessage id="cliente" />,
        accessor: "cliente",
      },
      {
        Header: "Responsável Cotação",
        accessor: "responsavel",
      },
      {
        Header: "Originado De",
        accessor: "cotacaoOriginal",
      },
      {
        Header: <FormattedMessage id="produtos" />,
        accessor: "produto",
      },
      {
        Header: <FormattedMessage id="volumeM2" />,
        accessor: "valorTotal",
      },
      {
        Header: <FormattedMessage id="dataEntrega" />,
        accessor: "dataEntrega",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Respondido",
        accessor: "respondido",
      },
      {
        Header: <FormattedMessage id="notaDistribuidor" />,
        accessor: "notaDistribuidor",
      },
    ],
    []
  );

  const tableInstance = useTable(
    { columns, data: listaPainel, initialState: { pageIndex: 1 } },
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(
    async (url) => {
      try {
        setLoadingListFilter(true);
        setListaPainel([]);
        const { data: response } = await api.get(url);

        response.dados.data.forEach((pedido) => {
          let obj = {};

          pedido.cotacoes.forEach((cotacao) => {
            let produtoFormatted = [];
            let arrayQuantidadeTotal = [];
            let valorTotalFormatted = 0;

            arrayQuantidadeTotal = cotacao?.produtos_cotacao.map((prod) => {
              return parseFloat(prod.quantidade / 1000)
                .toFixed(2)
                .replace(".", ",");
            });

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            produtoFormatted = cotacao?.produtos_cotacao.map((prod) => {
              return prod.produto.descricao;
            });

            obj = {};
            Object.assign(obj, {
              original: pedido,
              id: pedido.id,
              id_cotacao: cotacao.id,
              numeroPedido: pedido.numero_pedido_sistema,
              numeroCotacao: cotacao.numero_cotacao_sistema,
              numeroPedidoWizeo: cotacao.numero_pedido_sodexo,
              numeroPedidoCliente: pedido.numero_pedido_cliente,
              dataPedido: format(parseISO(pedido.created_at), "dd/MM/yyyy"),
              cliente:
                (pedido?.status_pedido === "Aprovado" ||
                pedido?.status_pedido === "Aguardando Faturamento" ||
                pedido?.status_pedido === "Faturado") && (
                  cotacao?.status_cotacao === "Aprovado" ||
                  cotacao?.status_cotacao === "Faturado"
                )
                  ? cotacao?.cliente?.cpf_cnpj.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    ) +
                    " - " +
                    cotacao?.cliente?.razao_social
                  : "",
              cotacaoOriginal: cotacao?.cotacao_original?.pedido?.numero_pedido_sistema,
              produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
              distribuidorTRR: cotacao.distribuidor
                ? cotacao?.distribuidor.cpf_cnpj.replace(
                    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                    "$1.$2.$3/$4-$5"
                  ) +
                  " - " +
                  cotacao?.distribuidor.razao_social
                : "",
              // prazoPagamento: pedido?.prazo,
              dataEntrega: format(
                parseISO(pedido.data_entrega_desejada),
                "dd/MM/yyyy"
              ),

              status: cotacao?.status_cotacao,
              valorTotal: (
                <TextTableFormatted labelFormatted={valorTotalFormatted} />
              ),
              integrado:
                cotacao.status_integracao === 0
                  ? "Não"
                  : cotacao.status_integracao === 1
                  ? "Sim"
                  : "Erro",
              cargaWizeo: "1234567 - Fixo",
              operador: pedido?.operador?.name,
              responsavel: cotacao?.ultimo_editor_preco?.name,
              historico: (
                <FaHistory
                  size={18}
                  onClick={() => handleHistory(pedido.id, cotacao.id)}
                />
              ),
              notaDistribuidor: (
                <UploadNFDistribuidor
                  idCotacao={cotacao.id}
                  hasNF={cotacao.nota_distribuidor_nome}
                />
              ),
              boleto: cotacao.boleto_cliente_nome && (
                <DownloadBoleto
                  idCotacao={cotacao.id}
                  hasBoleto={cotacao.boleto_cliente_nome}
                />
              ),
              cotacaoOriginalExcel:
                cotacao?.cotacao_original?.numero_cotacao_sistema,
              produtoExcel: produtoFormatted.toString(),
              valorTotalExcel: valorTotalFormatted.toString(),
              distribuidor_id: cotacao?.distribuidor?.id,
              distribuidor_preferencia_id:
                cotacao?.cliente?.distribuidor_preferencia_id,
            });

            setListaPainel((state) => [...state, obj]);
          });
        });

        setTotal(response?.dados?.total);
        setFirstPage(response?.dados?.first_page_url);
        setLastPage(response?.dados?.last_page);
        setLastPageUrl(response?.dados?.last_page_url);
        setNextPage(response?.dados?.next_page_url);
        setPrevPage(response?.dados?.prev_page_url);
        setCurrentPage(response?.dados?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.dados?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.dados?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });
        // setLoadingList(false);
        setLoadingListFilter(false);
      } catch (error) {
        console.log(error);
        // setLoadingList(false);
      }
    },
    [handleHistory]
  );

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="painelControle" />
            </h2>
          </nav>

          <div>
             <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#221c46"}
                color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => handleFilter()}
              loading={filterButton}
            />
             <ButtonIcon
              Icon={FiTrash2}
              backgroundColor={"#d43131"}
              placeholder={"Limpar Filtro"}
              onClick={() => handleRemoveFilter()}
              loading={filterButton}
            />
            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Painel de Controle"
              >
                <ExcelSheet
                  dataSet={formatExcelData(listaPainel)}
                  name="Painel de Controle"
                />
              </ExcelFile>

              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  <tr>
                    <td>
                      <InputMaskDefault
                        name="numero_pedido"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        mask={"int15"}
                        maxLength={15}
                        onChange={(e) => setFilterNumeroPedido(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="numero_pedido_cliente"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) =>
                          setFilterNumeroPedidoCliente(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="data"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        mask={MaskInput.date}
                        onChange={(e) => setFilterDataPedido(e.target.value)}
                        // onBlur={() => {
                        //   handleFilter();
                        // }}
                      />
                    </td>

                    <td>
                      <InputLabel
                        name="cliente"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterCliente(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td>

                    {/* <td>
                      <AsyncListSelect
                        name="operador_id"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        myOptions={"operadores"}
                        onChange={(e) => {
                          setFilterOperador(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td> */}

                    <td>
                      {/* <AsyncListSelect
                        name="responsavel"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        loadOptions={async () => responsavelOptions}
                        onChange={(e) => {
                          setFilterResponsavel(e?.value);
                        }}
                        isClearable={true}
                      /> */}
                    </td>

                    <td>
                      <ListSelect
                        name="cotacaoOriginal"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaDistribuidor}
                        onChange={(e) => {
                          setFilterCotacaoOriginal(e?.value);
                        }}
                        isClearable={true}
                      />
                    </td>

                    <td>
                      <AsyncListSelect
                        name="produto"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        myOptions={"produtos"}
                        onChange={(e) => {
                          setFilterProduto(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td>

                    <td></td>

                    {/* <td>
                      <InputLabel
                        name="distribuidor"
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) =>
                          setFilterDistribuidorTRR(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td> */}

                    {/* <td>
                      <InputMaskDefault
                        name="prazo_pgt"
                        style={{ borderColor: "#cccccc" }}
                        mask={"int3"}
                        type="text"
                        onChange={(e) =>
                          setFilterPrazoPagamento(e.target.value)
                        }
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td> */}

                    <td>
                      <InputLabel
                        name="data_entrega"
                        mask={MaskInput.date}
                        style={{ borderColor: "#cccccc" }}
                        type="text"
                        onChange={(e) => setFilterDataEntrega(e.target.value)}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                      />
                    </td>

                    <td>
                      <ListSelect
                        name="status"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={statusOptions}
                        onChange={(e) => {
                          setFilterStatus(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td>

                    <td>
                      <ListSelect
                        name="respondido"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={boleto}
                        onChange={(e) => {
                          setFilterRespondido(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td>

                    {/* <td>
                      <ListSelect
                        name="integrado"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={integradoOptions}
                        onChange={(e) => {
                          setFilterIntegrado(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td> */}

                    <td>
                      <ListSelect
                        name="notaDistribuidor"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={notaDistribuidor}
                        onChange={(e) => {
                          setFilterNotaDistribuidor(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td>
                    {/* <td>
                      <ListSelect
                        name="boleto"
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        options={boleto}
                        onChange={(e) => {
                          setFilterBoleto(e?.value);
                        }}
                        // onBlur={() => {
                        //   return handleFilter();
                        // }}
                        isClearable={true}
                      />
                    </td> */}
                    <td></td>
                    <td></td>
                  </tr>
                  {loadingListFilter
                    ? null
                    : rows.map((row) => {
                        // Prepare the row for display

                        prepareRow(row);
                        return (
                          // Apply the row props
                          <tr {...row.getRowProps()}>
                            {
                              // Loop over the rows cells
                              row.cells.map((cell) => {
                                // Apply the cell props

                                var style;
                                var colorCell;

                                if (cell.column.id === "integrado") {
                                  if (cell.row.original.integrado === "Não") {
                                    colorCell = "#edea4c";
                                  } else if (
                                    cell.row.original.integrado === "Sim"
                                  ) {
                                    colorCell = "#97df99";
                                  } else {
                                    colorCell = "#fe93a3";
                                  }

                                  style = {
                                    background: colorCell,
                                    fontWeight: "bold",
                                  };
                                }

                                return (
                                  <td {...cell.getCellProps()} style={style}>
                                    {
                                      // Render the cell contents
                                      cell.render("Cell")
                                    }
                                  </td>
                                );
                              })
                            }
                            <td>
                              <OptionsList>
                                <li>
                                  <OptionsIconPlaceholder
                                    Icon={FiEye}
                                    placeholder={
                                      <FormattedMessage id="exibir" />
                                    }
                                    onClick={() => handleDisplay(row)}
                                    permission="pedido-show[2]"
                                  />
                                </li>

                                {row.original.status === "Aprovado" ||
                                row.original.status === "Faturado" ||
                                row.original.status ===
                                  "Aguardando Faturamento" ||
                                row.original.status === "Reprovado" ||
                                row.original.status === "Cancelado" ||
                                row.original.status ===
                                  "Aguardando Aprovação" ? (
                                  <></>
                                ) : (
                                  <li>
                                    <OptionsIconPlaceholder
                                      Icon={FiEdit}
                                      placeholder={
                                        <FormattedMessage id="editar" />
                                      }
                                      onClick={() => handleEdit(row)}
                                      permission="pedido-update[2]"
                                    />
                                  </li>
                                )}
                              </OptionsList>
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              {loadingListFilter ? (
                <ContentSpinner
                  style={{
                    zIndex: "-1",
                    height: "65vh",
                    width: "90%",
                    position: "fixed",
                  }}
                />
              ) : null}
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
              local={"clienteXdistribuidor"}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default PainelList;

import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TreeComponent from "./../../../components/TreeMenu/TreeComponent";
import { FormattedMessage, injectIntl } from "react-intl";

import api from "./../../../services/api";

//import AuthContext from "./../../../context/auth";

import {
  Container,
  CreateForm,
  CreateFooter,
  CheckForm,
  FormPermission,
  TitlePermission,
} from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ContentSpinner from "./../../../components/Spinner/Content";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const EditAccessProfiles = () => {
  const [empresa, setEmpresa] = useState("");
  const [descricao, setDescricao] = useState("");
  const [listaPermissoes, setListaPermissoes] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [status, setStatus] = useState("");
  const [label, setLabel] = useState("");

  const [checkBoxAll, setCheckBoxAll] = useState([]);

  const [loading, setLoading] = useState(true);

  const history = useHistory();
  //const { user } = useContext(AuthContext);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    if (!history.location.state?.id) {
      history.push("/access/profiles/customers/list");
    }

    const id = history.location.state?.id;

    async function loadInfo() {
      try {
        const { data: response } = await api.get(`role/show/${id}`);

        setDescricao(response?.perfil?.description);
        setStatus(
          response?.perfil?.ativo === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );

        const cadastrosEmpresas = response.rolecadastro.map((item) => {
          return {
            value: item.id,
            label:
              item.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.razao_social,
          };
        });

        setEmpresa(cadastrosEmpresas);

        setLabel(history.location.state?.label);

        response.perfilpermissoes.map((p) => {
          setCheckBoxAll((checkbox) => [...checkbox, p.name + 3]);
          return setPermissions((permissions) => [...permissions, p.name]);
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadInfo();
  }, [history]);

  useEffect(() => {
    async function loadPermissions() {
      try {
        const { data: response } = await api.get(`/permission`);

        const arr = Object.keys(response);

        // Formata Objeto de permissões da API

        const arrFormmated = arr.map((item) => {
          let arrPermission = [];
          const permissions = Object.keys(response[item]);

          permissions.map((p) => {
            return arrPermission.push({
              id: p,
              permission: item + "-" + response[item][p],
              name: item,
            });
          });

          const b = { [item]: arrPermission };

          return b;
        });

        setAllPermissions(arrFormmated);
      } catch (error) {}
    }

    loadPermissions();
  }, []);

  useEffect(() => {
    const arrFormmated = allPermissions;

    let menuData = [];

    // Acessos
    let acessosPermissoes;
    let usuarioPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "roles") {
        acessosPermissoes = e.roles;
      }
    });

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "usuario") {
        usuarioPermissoes = e.usuario;
      }
    });

    const acessos = {
      title: <FormattedMessage id="acessos" />,
      children: [
        {
          title: <FormattedMessage id="perfis" />,
          children: [
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={acessosPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
        {
          title: <FormattedMessage id="usuarios" />,
          children: [
            {
              title: <FormattedMessage id="clientes" />,
              component: (
                <Permissions
                  data={usuarioPermissoes}
                  cadastroTipoId={3}
                  permissions={permissions}
                />
              ),
            },
          ],
        },
      ],
    };

    menuData.push(acessos);

    // Cadastro

    let cadastroPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cadastro") {
        // Remove Permissões do cadastro de distribuidor
        const arr = e.cadastro.filter((item) => {
          return (
            item.permission.indexOf("cadastro-cliente-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-cliente-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-cliente-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-cliente-list") === -1 &&
            item.permission.indexOf("cadastro-cliente-store") === -1 &&
            item.permission.indexOf("cadastro-cliente-show") === -1 &&
            item.permission.indexOf("cadastro-cliente-destroy") === -1 &&
            item.permission.indexOf("cadastro-cliente-update") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-list") === -1 &&
            item.permission.indexOf("cadastro-store") === -1 &&
            item.permission.indexOf("cadastro-show") === -1 &&
            item.permission.indexOf("cadastro-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-store") === -1 &&
            item.permission.indexOf("cadastro-sodexo-destroy") === -1 &&
            item.permission.indexOf("cadastro-sodexo-show") === -1 &&
            item.permission.indexOf("cadastro-sodexo-update") === -1 &&
            item.permission.indexOf("cadastro-sodexo-list") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-store") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-destroy") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-show") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-update") === -1 &&
            item.permission.indexOf("cadastro-distribuidor-list") === -1 &&
            item.permission.indexOf("cadastro-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-consumocapacidade") === -1 &&
            item.permission.indexOf("cadastro-certificadodigitalnfe") === -1 &&
            item.permission.indexOf("cadastro-basesdistribuicao") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscadastrais") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosendereco") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadosentrega") === -1 &&
            item.permission.indexOf("cadastro-sodexo-dadoscobranca") === -1 &&
            item.permission.indexOf("cadastro-sodexo-consumocapacidade") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-certificadodigitalnfe") ===
              -1 &&
            item.permission.indexOf("cadastro-sodexo-basesdistribuicao") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscadastrais") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosendereco") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadosentrega") ===
              -1 &&
            item.permission.indexOf("cadastro-distribuidor-dadoscobranca") ===
              -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-consumocapacidade"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-certificadodigitalnfe"
            ) === -1 &&
            item.permission.indexOf(
              "cadastro-distribuidor-basesdistribuicao"
            ) === -1
          );
        });

        cadastroPermissoes = arr;
      }
    });

    const cadastro = {
      title: <FormattedMessage id="cadastrar" />,
      children: [
        {
          title: <FormattedMessage id="certificadoDigital" />,
          component: (
            <Permissions
              data={cadastroPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(cadastro);

    /////// DASHBOARD ///////////
    let dashboardPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "dashboard") {
        dashboardPermissoes = e.dashboard.filter(
          (item) => item.permission.indexOf("dashboard-cliente") !== -1
        );
      }
    });

    const dashboard = {
      title: "Dashboard",
      children: [
        {
          title: "Dashboard",
          component: (
            <Permissions
              data={dashboardPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(dashboard);

    /////// HOME ///////////
    let homePermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "cards") {
        homePermissoes = e.cards.filter(
          (item) => item.permission.indexOf("cards-home") !== -1
        );
      }
    });

    const home = {
      title: "Home",
      children: [
        {
          title: "Home",
          component: (
            <Permissions
              data={homePermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };
    menuData.push(home);

    /////// PEDIDOS ///////////
    let precosPermissoes;
    let pedidosPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "preco") {
        precosPermissoes = e.preco.filter(
          (item) =>
            item.permission.indexOf("preco-store") === -1 &&
            item.permission.indexOf("preco-show") === -1 &&
            item.permission.indexOf("preco-update") === -1 &&
            item.permission.indexOf("preco-destroy") === -1 &&
            item.permission.indexOf("preco-importar") === -1
        );
      }

      if (Object.keys(e)[0] === "pedido") {
        pedidosPermissoes = e.pedido.filter(
          (item) =>
            item.permission.indexOf("pedido-destroy") === -1 &&
            item.permission.indexOf("pedido-liberar") === -1 &&
            item.permission.indexOf("pedido-cancelar") === -1 &&
            item.permission.indexOf("pedido-integrar") === -1
        );
      }
    });

    const pedido = {
      title: <FormattedMessage id="pedidos" />,
      children: [
        {
          title: <FormattedMessage id="precosCombustiveis" />,
          component: (
            <Permissions
              data={precosPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
        {
          title: <FormattedMessage id="painelControle" />,
          component: (
            <Permissions
              data={pedidosPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(pedido);

    /////// RELATÓRIOS ///////////
    let reportPermissoes;

    arrFormmated.forEach((e) => {
      if (Object.keys(e)[0] === "relatorio") {
        reportPermissoes = e.relatorio.filter(
          (item) =>
            item.permission.indexOf("relatorio-cadastroclientes") === -1 &&
            item.permission.indexOf("relatorio-cadastrodistribuidorestrrs") ===
              -1 &&
            item.permission.indexOf("relatorio-condicoescomerciaisclientes") ===
              -1 &&
            item.permission.indexOf("relatorio-precomedio") === -1 &&
            item.permission.indexOf("relatorio-historiconegociacoes") === -1 &&
            item.permission.indexOf("relatorio-saving") === -1 &&
            item.permission.indexOf("relatorio-reembolso") === -1
        );
      }
    });

    const reports = {
      title: <FormattedMessage id="relatorio" />,
      children: [
        {
          title: <FormattedMessage id="relatorio" />,
          component: (
            <Permissions
              data={reportPermissoes}
              cadastroTipoId={3}
              permissions={permissions}
            />
          ),
        },
      ],
    };

    menuData.push(reports);

    setListaPermissoes(menuData);
  }, [permissions, allPermissions]);

  const Permissions = useCallback(
    ({ data, cadastroTipoId, permissions }) => {
      return (
        <CheckForm>
          <main style={{ backgroundColor: "transparent" }}>
            {data.map((permissionObj, index) => {
              let labelPermission = "";

              const namePermission =
                permissionObj.permission.substr(
                  0,
                  permissionObj.permission.length - 3
                ) + `[${cadastroTipoId}]`;

              const checkNamePermission = permissionObj.permission.substr(
                0,
                permissionObj.permission.length - 3
              );

              if (
                checkNamePermission === "pedido-store" ||
                checkNamePermission === "pedido-update"
              ) {
                // Ajustes de Nome Especificos solicitado pelo cliente.
                switch (
                  permissionObj.permission
                    .replace(`${permissionObj.name}-`, "")
                    .substr(
                      0,
                      permissionObj.permission.replace(
                        `${permissionObj.name}-`,
                        ""
                      ).length - 3
                    )
                ) {
                  case "store":
                    labelPermission = <FormattedMessage id="novoPedido" />;
                    break;
                  case "update":
                    labelPermission = <FormattedMessage id="editarPedido" />;
                    break;
                  default:
                    labelPermission = permissionObj.permission.substr(
                      0,
                      permissionObj.permission.length - 3
                    );
                    break;
                }
              } else {
                // Nome Padrões
                switch (
                  permissionObj.permission
                    .replace(`${permissionObj.name}-`, "")
                    .substr(
                      0,
                      permissionObj.permission.replace(
                        `${permissionObj.name}-`,
                        ""
                      ).length - 3
                    )
                ) {
                  case "list":
                    labelPermission = <FormattedMessage id="listar" />;
                    break;
                  case "store":
                    labelPermission = <FormattedMessage id="cadastrar" />;
                    break;
                  case "show":
                    labelPermission = <FormattedMessage id="exibir" />;
                    break;
                  case "update":
                    labelPermission = <FormattedMessage id="editar" />;
                    break;
                  case "destroy":
                    labelPermission = <FormattedMessage id="excluir" />;
                    break;
                  case "redefinirsenha":
                    labelPermission = <FormattedMessage id="redefinirSenha" />;
                    break;
                  case "excel":
                    labelPermission = <FormattedMessage id="exportarExcel" />;
                    break;
                  case "importar":
                    labelPermission = <FormattedMessage id="importar" />;
                    break;
                  case "filter":
                    labelPermission = <FormattedMessage id="filtrar" />;
                    break;
                  case "aprovar":
                    labelPermission = <FormattedMessage id="aprovarPedido" />;
                    break;
                  case "reprovar":
                    labelPermission = <FormattedMessage id="reprovarPedido" />;
                    break;
                  case "liberar":
                    labelPermission = <FormattedMessage id="liberarPedido" />;
                    break;
                  case "cancelar":
                    labelPermission = <FormattedMessage id="cancelarPedido" />;
                    break;
                  //Cadastro
                  case "dadoscadastrais":
                    labelPermission = <FormattedMessage id="dadosCadastrais" />;
                    break;
                  case "dadosendereco":
                    labelPermission = <FormattedMessage id="dadosEndereco" />;
                    break;
                  case "dadosentrega":
                    labelPermission = <FormattedMessage id="dadosEntrega" />;
                    break;
                  case "dadoscobranca":
                    labelPermission = <FormattedMessage id="dadosCobranca" />;
                    break;
                  case "consumocapacidade":
                    labelPermission = <FormattedMessage id="dadosCapacidade" />;
                    break;
                  case "certificadodigitalnfe":
                    labelPermission = (
                      <FormattedMessage id="editarCertificado" />
                    );
                    break;
                  case "cliente-certificadodigitalnfe":
                    labelPermission = (
                      <FormattedMessage id="editarCertificado" />
                    );
                    break;
                  case "basesdistribuicao":
                    labelPermission = (
                      <FormattedMessage id="basesDistribuicao" />
                    );
                    break;
                  // Dashboard
                  case "cliente":
                    labelPermission = "Exibir Dashboard";
                    break;
                  case "home":
                    labelPermission = "Exibir Cards";
                    break;
                  //Relatórios
                  case "cadastroclientes":
                    labelPermission = <FormattedMessage id="cadastroCliente" />;
                    break;
                  case "cadastrodistribuidorestrrs":
                    labelPermission = (
                      <FormattedMessage id="cadastroDistribuidorTRR" />
                    );
                    break;
                  case "condicoescomerciaisclientes":
                    labelPermission = (
                      <FormattedMessage id="condicaoComercialCliente" />
                    );
                    break;
                  case "logs":
                    labelPermission = "Logs";
                    break;
                  case "precomedio":
                    labelPermission = (
                      <FormattedMessage id="precoMedioCombustiveis" />
                    );
                    break;
                  case "historiconegociacoes":
                    labelPermission = (
                      <FormattedMessage id="historicoNegociacaoDistribuidor" />
                    );
                    break;
                  case "saving":
                    labelPermission = "Saving";
                    break;
                  case "pedidosdetalhados":
                    labelPermission = <FormattedMessage id="pedidoDetalhado" />;
                    break;
                  case "reembolso":
                    labelPermission = (
                      <FormattedMessage id="extratoReembolsoDistribuidor" />
                    );
                    break;
                  default:
                    labelPermission = permissionObj.permission.substr(
                      0,
                      permissionObj.permission.length - 3
                    );
                    break;
                }
              }
              let checkAll = true;
              let checkValue = permissions.filter((p) => p === namePermission);

              // remove duplicado
              var noDuplicateArray = permissions.filter(
                (item, i) => permissions.indexOf(item) === i
              );

              let checkArray = [];

              data.forEach((item) => {
                const t = noDuplicateArray.find((i) => i === item.permission);

                if (t) {
                  checkArray.push(t);
                }
              });

              checkAll = checkBoxAll.find(
                (item) => item === permissionObj.permission + cadastroTipoId
              );

              if (checkAll) {
                checkAll = true;
              } else {
                checkAll = false;
              }

              if (checkValue?.length > 0) {
                checkValue = true;
              } else {
                checkValue = false;
              }

              // Bloqueia Permissões que usuário não tem acesso

              // Valida caso o usuário não seja MASTER

              // var verifyPermission = "";
              // var checkPermission = true;

              // if (user?.tipo_id !== 1) {
              //   verifyPermission = permissionObj.permission.substr(
              //     0,
              //     permissionObj.permission.length - 3
              //   );

              //   checkPermission = user?.permission?.find(
              //     (item) => item.namePermission.indexOf(verifyPermission) !== -1
              //   );
              // }

              // **********************************************

              const CheckAll = () => (
                <div>
                  <input
                    type="checkbox"
                    defaultChecked={checkAll}
                    disabled={true}
                  />
                  <label>
                    <FormattedMessage id="todos" />
                  </label>
                </div>
              );

              const CheckOne = ({ hasCheckAll }) => (
                <>
                  {hasCheckAll === 0 && <CheckAll />}
                  {/* {checkPermission ? ( */}
                  <div>
                    <input
                      type="checkbox"
                      name={namePermission}
                      defaultChecked={checkValue}
                      value={namePermission}
                      disabled={true}
                    />
                    <label>{labelPermission}</label>
                  </div>
                  {/* ) : (
                    <div>
                      <input
                        type="checkbox"
                        name={namePermission}
                        disabled={true}
                      />
                      <label>{labelPermission}</label>
                    </div>
                  )} */}
                </>
              );

              // Retorna as permissoes nos inputs
              return <CheckOne key={index} hasCheckAll={index} />;
            })}
          </main>
        </CheckForm>
      );
    },
    [checkBoxAll]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      {/* <button onClick={handleteste}>Entrar</button> */}
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirPerfilAcessoCliente" />
          </h4>
        </header>
        {loading ? (
          <ContentSpinner />
        ) : (
          <form>
            <AsyncListSelect
              value={empresa}
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="empresa" />}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              isSearchable={false}
              menuIsOpen={false}
              isDisabled={true}
              isMulti
            />

            <InputLabel
              name="name"
              type="text"
              maxLength={100}
              label={<FormattedMessage id="nome" />}
              value={label}
              readOnly
            />

            <InputLabel
              name="descricao"
              type="text"
              value={descricao}
              maxLength={200}
              label={<FormattedMessage id="descricao" />}
              readOnly
            />

            <ListSelect
              labelPlaceholder={"Status"}
              options={statusOptions}
              placeholder={<FormattedMessage id="selecionar" />}
              value={status}
              isDisabled={true}
            />

            <hr />

            <TitlePermission>Menu</TitlePermission>

            <FormPermission>
              <TreeComponent data={listaPermissoes} display={true}/>
            </FormPermission>
          </form>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditAccessProfiles);

import React, { useCallback, useEffect, useState, useContext } from "react";
import { useTable } from "react-table";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";
import { parseISO, format } from "date-fns";
import { Link, useHistory } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ptBr from "date-fns/locale/pt-BR";

// import OptionsList from "./../../../../components/OptionsList";
// import OptionsIconPlaceholder from "./../../../../components/OptionsList/OptionsIconPlaceholder";

import AsyncListSelect from "./../../../../components/ListSelect/AsyncListSelect";
import ContentSpinner from "./../../../../components/Spinner/Content";
import TextTableFormatted from "./../../../../components/TextTableFormatted";
import Input from "./../../../../components/InputLabel";
import ListSelect from "./../../../../components/ListSelect";
import Button from "./../../../../components/ButtonDefault";
//import MaskInput from "./../../../../config/input-mask";

import AuthContext from "./../../../../context/auth";
import { AiOutlineFolderOpen } from "react-icons/ai";

import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  TabStyled,
  Content,
  CompanyContainer,
  ContainerPedidoLink,
  DatePickerComponent,
  DateContainer,
} from "./styles";

export const CrestePainelOrdersProgram = ({ intl }) => {
  const { user } = useContext(AuthContext);

  const [empresa, setEmpresa] = useState("");
  //const [data, setData] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [status, setStatus] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  //const [details, setDetails] = useState();
  const [dataPedidoProgramados, setDataPedidoProgramados] = useState([]);

  const [loadingList, setLoadingList] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [dataProgramacaoDe, setDataProgramacaoDe] = useState(null);
  const [dataProgramacaoAte, setDataProgramacaoAte] = useState(null);
  const [dataCriacaoProgDe, setDataCriacaoProgDe] = useState(null);
  const [dataCriacaoProgAte, setDataCriacaoProgAte] = useState(null);

  registerLocale("pt", ptBr);

  // const [startDate, endDate] = dateRange;
  //const [startDate, endDate] = dateRange;

  const history = useHistory();

  const statusOptions = [
    { value: 1, label: "Em processo" },
    { value: 0, label: "Processado" },
  ];

  const PedidoLink = useCallback(({ item }) => {
    return (
      <ContainerPedidoLink>
        <Link
          to={{
            pathname: "/orders/painel/show",
            state: {
              numeroPedido: item?.pedido?.numero_pedido_sistema,
              pedido: item?.pedido,
              isProgramming: true,
            },
          }}
        >
          {item?.pedido?.numero_pedido_sistema}
          <AiOutlineFolderOpen />
        </Link>
      </ContainerPedidoLink>
    );
  }, []);

  useEffect(() => {
    //Lista Programação
    (async () => {
      try {
        var response;
        let pedido;

        if (history.location.state?.numeroPedido) {
          pedido = history.location.state?.numeroPedido;
        }

        if (user.cadastro_tipo_id === 3 || pedido) {
          const data = {};

          if (pedido) {
            Object.assign(data, {
              numero_pedido_sistema: pedido,
            });
          } else {
            Object.assign(data, {
              cadastro_id: user.cadastro_id,
            });
          }

          response = await api.post("/programacao/filter", data);

          setEmpresa({
            value: response.data.data[0].pedido.cadastro.id,
            label: response.data.data[0].pedido.cadastro.razao_social,
          });

          const formattedList = response.data.data.map((item) => {
            const obj = {};

            let produtoFormatted = [];
            produtoFormatted = item?.pedido?.produtos_pedido?.map((prod) => {
              return prod.descricao;
            });

            let valorTotalFormatted = [];
            let arrayQuantidadeTotal = [];

            arrayQuantidadeTotal = item?.pedido?.produtos_pedido?.map(
              (prod) => {
                return parseInt(prod.quantidade / 1000).toFixed(2);
              }
            );

            if (arrayQuantidadeTotal.length > 0) {
              valorTotalFormatted = arrayQuantidadeTotal;
            }

            Object.assign(obj, {
              id: item?.id,
              numero_pedido: <PedidoLink item={item} />,
              numero_pedido_cliente: item?.pedido?.numero_pedido_cliente,
              cliente: item?.pedido?.cadastro?.razao_social,
              prazo: item?.pedido?.prazo,
              frete: item?.pedido?.frete,
              modo_abastecimento: item?.pedido?.modo_abasteciemento,
              periodo: item?.pedido?.periodo_desejado,
              horario: item?.pedido?.horario_desejado,
              data_entrega:
                item?.data_programada &&
                format(parseISO(item?.data_programada), "dd/MM/yyyy"),
              data_programada_criacao: format(
                parseISO(item?.data_programada_criacao),
                "dd/MM/yyyy"
              ),
              status: item?.ativo,
              statusFormmated: item?.ativo === 1 ? "Em processo" : "Processado",
              produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
              volume: (
                <TextTableFormatted labelFormatted={valorTotalFormatted} />
              ),
              originalData: item,
            });

            return obj;
          });

          setDataPedidoProgramados(formattedList);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [history.location.state, user.cadastro_id, user.cadastro_tipo_id]);

  const columnsPedidosProgramados = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="numPedidoBase" />,
        accessor: "numero_pedido",
      },
      {
        Header: <FormattedMessage id="numPedidoCliente" />,
        accessor: "numero_pedido_cliente",
      },
      {
        Header: "Status",
        accessor: "statusFormmated",
      },
      {
        Header: <FormattedMessage id="dataCriacao" />,
        accessor: "data_programada_criacao",
      },
      {
        Header: <FormattedMessage id="dataEntrega" />,
        accessor: "data_entrega",
      },
      {
        Header: <FormattedMessage id="cliente" />,
        accessor: "cliente",
      },
      {
        Header: <FormattedMessage id="produtos" />,
        accessor: "produto",
      },
      {
        Header: <FormattedMessage id="volumeM2" />,
        accessor: "volume",
      },
      {
        Header: <FormattedMessage id="frete" />,
        accessor: "frete",
      },
      {
        Header: <FormattedMessage id="modoAbastecimento" />,
        accessor: "modo_abastecimento",
      },
      {
        Header: <FormattedMessage id="periodo" />,
        accessor: "periodo",
      },
      {
        Header: <FormattedMessage id="horario" />,
        accessor: "horario",
      },
    ],
    []
  );

  const pedidoProgramadoInstance = useTable({
    columns: columnsPedidosProgramados,
    data: dataPedidoProgramados,
  });

  const {
    getTableProps: pedidoProgramadoGetTableProps,
    getTableBodyProps: pedidoProgramadoGetTableBodyProps,
    headerGroups: pedidoProgramadoHeaderGroups,
    rows: pedidoProgramadoRows,
    prepareRow: pedidoProgramadoPrepareRow,
  } = pedidoProgramadoInstance;

  const handleSearchOrders = useCallback(async () => {
    try {
      //  Carrega lista Pedidos Programados
      setLoadingButton(true);
      var responsePedProgramados;

      const dataCriacaoProgDeFormmated = dataCriacaoProgDe
        ? format(dataCriacaoProgDe, "yyyy-MM-dd")
        : "";

      const dataCriacaoProgAteFormmated = dataCriacaoProgAte
        ? format(dataCriacaoProgAte, "yyyy-MM-dd")
        : "";

      const dataProgramacaoDeFormmated = dataProgramacaoDe
        ? format(dataProgramacaoDe, "yyyy-MM-dd")
        : "";

      const dataProgramacaoAteFormmated = dataProgramacaoAte
        ? format(dataProgramacaoAte, "yyyy-MM-dd")
        : "";

      const paramsDate = {};

      dataProgramacaoDeFormmated &&
        Object.assign(paramsDate, {
          "data_programada[0]": dataProgramacaoDeFormmated,
        });
      dataProgramacaoAteFormmated &&
        Object.assign(paramsDate, {
          "data_programada[1]": dataProgramacaoAteFormmated,
        });

      dataCriacaoProgDeFormmated &&
        Object.assign(paramsDate, {
          "data_programada_criacao[0]": dataCriacaoProgDeFormmated,
        });
      dataCriacaoProgAteFormmated &&
        Object.assign(paramsDate, {
          "data_programada_criacao[1]": dataCriacaoProgAteFormmated,
        });

      //if (user.cadastro_tipo_id === 1) {
      responsePedProgramados = await api.post(
        "/programacao/filter",
        {
          cadastro_id: empresa && empresa?.value,
          ativo: status,
          numero_pedido_sistema: numeroPedido || "",
        },
        {
          params: paramsDate,
        }
      );
      // }

      const formattedListProg = responsePedProgramados.data.data.map((item) => {
        const obj = {};

        let produtoFormatted = [];
        produtoFormatted = item?.pedido?.produtos_pedido?.map((prod) => {
          return prod.descricao;
        });

        let valorTotalFormatted = [];
        let arrayQuantidadeTotal = [];

        arrayQuantidadeTotal = item?.pedido?.produtos_pedido?.map((prod) => {
          return parseInt(prod.quantidade / 1000).toFixed(2);
        });

        if (arrayQuantidadeTotal.length > 0) {
          valorTotalFormatted = arrayQuantidadeTotal;
        }

        Object.assign(obj, {
          id: item?.id,
          numero_pedido: <PedidoLink item={item} />,
          numero_pedido_cliente: item?.pedido?.numero_pedido_cliente,
          cliente: item?.pedido?.cadastro?.razao_social,
          prazo: item?.pedido?.prazo,
          frete: item?.pedido?.frete,
          modo_abastecimento: item?.pedido?.modo_abasteciemento,
          periodo: item?.pedido?.periodo_desejado,
          horario: item?.pedido?.horario_desejado,
          data_entrega: format(parseISO(item?.data_programada), "dd/MM/yyyy"),
          data_programada_criacao: format(
            parseISO(item?.data_programada_criacao),
            "dd/MM/yyyy"
          ),
          status: item?.ativo,
          statusFormmated: item?.ativo === 1 ? "Em processo" : "Processado",
          pedidoRef: item.pedido_id,
          originalData: item,
          produto: <TextTableFormatted labelFormatted={produtoFormatted} />,
          volume: <TextTableFormatted labelFormatted={valorTotalFormatted} />,
        });

        return obj;
      });

      setDataPedidoProgramados(formattedListProg);
      setLoadingList(false);
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);

      setLoadingList(false);
      console.log(error);
    }
  }, [
    dataCriacaoProgAte,
    dataCriacaoProgDe,
    dataProgramacaoAte,
    dataProgramacaoDe,
    empresa,
    numeroPedido,
    status,
  ]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="programacaoPedido" />
          </h4>
        </header>

        <CompanyContainer>
          <AsyncListSelect
            value={empresa}
            onChange={(e) => {
              setEmpresa(e);
              // handleSearchOrders(e);
            }}
            myOptions={"clientes"}
            cacheOptions
            defaultOptions
            labelPlaceholder={<FormattedMessage id="empresa" />}
            placeholder={<FormattedMessage id="selecionar" />}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            isDisabled={user.cadastro_tipo_id === 3 ? true : false}
            isClearable
            zIndex={1001}
          />

          <footer>
            <DateContainer>
              <strong>Data Criação</strong>
              <div>
                <DatePickerComponent
                  selected={dataCriacaoProgDe}
                  onChange={(update) => {
                    setDataCriacaoProgDe(update);
                  }}
                  placeholderText={"De"}
                  isClearable={true}
                  withPortal
                  locale={"pt"}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
              <div>
                <DatePickerComponent
                  selected={dataCriacaoProgAte}
                  onChange={(update) => {
                    setDataCriacaoProgAte(update);
                  }}
                  placeholderText={"Até"}
                  isClearable={true}
                  withPortal
                  locale={"pt"}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </DateContainer>
            <DateContainer>
              <strong>Data Entrega</strong>
              <div>
                <DatePickerComponent
                  selected={dataProgramacaoDe}
                  onChange={(update) => {
                    setDataProgramacaoDe(update);
                  }}
                  placeholderText={"De"}
                  isClearable={true}
                  withPortal
                  locale={"pt"}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
              <div>
                <DatePickerComponent
                  selected={dataProgramacaoAte}
                  onChange={(update) => {
                    setDataProgramacaoAte(update);
                  }}
                  placeholderText={"Até"}
                  isClearable={true}
                  withPortal
                  locale={"pt"}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </DateContainer>
            <Input
              label={"Pedido Base"}
              onChange={(e) => setNumeroPedido(e.target.value)}
            />
            <ListSelect
              labelPlaceholder={"Status"}
              placeholder={<FormattedMessage id="selecionar" />}
              options={statusOptions}
              onChange={(e) => setStatus(e?.value)}
              zIndex={1000}
              isClearable
            />

            <Button onClick={handleSearchOrders} loading={loadingButton} program={37}>
              Filtrar
            </Button>
          </footer>
        </CompanyContainer>
        {!loadingList ? (
          <main>
            <Tabs
              selectedIndex={tabIndex}
              onSelect={(index) => setTabIndex(index)}
            >
              <>
                <TabList>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="pedidosProgramado" />
                  </TabStyled>
                </TabList>

                <Content>
                  {/* Pedidos Programados */}
                  <TabPanel>
                    <table {...pedidoProgramadoGetTableProps()}>
                      <thead>
                        {
                          // Loop over the header rows
                          pedidoProgramadoHeaderGroups.map((headerGroup) => (
                            // Apply the header row props
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {
                                // Loop over the headers in each row
                                headerGroup.headers.map((column) => (
                                  // Apply the header cell props
                                  <th {...column.getHeaderProps()}>
                                    {
                                      // Render the header
                                      column.render("Header")
                                    }
                                  </th>
                                ))
                              }
                              {/* <th>
                                  <FormattedMessage id="acoes" />
                                </th> */}
                            </tr>
                          ))
                        }
                      </thead>
                      {/* Apply the table body props */}
                      <tbody {...pedidoProgramadoGetTableBodyProps()}>
                        {
                          // Loop over the table rows
                          pedidoProgramadoRows.map((row) => {
                            // Prepare the row for display

                            pedidoProgramadoPrepareRow(row);
                            return (
                              // Apply the row props
                              <tr {...row.getRowProps()}>
                                {
                                  // Loop over the rows cells
                                  row.cells.map((cell) => {
                                    // Apply the cell props
                                    var style;
                                    var colorCell;

                                    if (cell.column.id === "statusFormmated") {
                                      if (cell.row.original.status === 0) {
                                        colorCell = "#97df99";
                                      } else if (
                                        cell.row.original.status === 1
                                      ) {
                                        colorCell = "#fe93a3";
                                      }

                                      style = {
                                        background: colorCell,
                                        fontWeight: "bold",
                                      };
                                    }

                                    if (
                                      cell.column.Header ===
                                      <FormattedMessage id="dataEntrega" />
                                    ) {
                                    }

                                    return (
                                      <td
                                        {...cell.getCellProps()}
                                        style={style}
                                      >
                                        {
                                          // Render the cell contents
                                          cell.render("Cell")
                                        }
                                      </td>
                                    );
                                  })
                                }
                                {/* <td>
                                    <OptionsList>
                                      <li>
                                        <OptionsIconPlaceholder
                                          Icon={FiFileText}
                                          placeholder={
                                            <FormattedMessage id="detalhes" />
                                          }
                                          onClick={() => {
                                            setDetails(row);
                                            setShowModal(!showModal);
                                          }}
                                        />
                                      </li>
                                    </OptionsList>
                                  </td> */}
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </TabPanel>
                </Content>
              </>
            </Tabs>
          </main>
        ) : (
          <ContentSpinner />
        )}
      </CreateForm>

      {/* {showModal && <HandleDetails details={details} />} */}
    </Container>
  );
};

export default injectIntl(CrestePainelOrdersProgram);

import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";
import { FormattedMessage, injectIntl } from "react-intl";

import { toast } from "react-toastify";

import api from "./../../../services/api";

import { Container, CreateForm, CreateFooter, ContainerRole } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import LoaderContent from "./../../../components/Spinner/Content";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

import { getErrorsApiValidation } from "../../../utils/getErrorsValidation";

export const EditUser = ({ intl }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nivel, setNivel] = useState("");

  // const [roles, setRoles] = useState("");
  // const [listRoles, setListRoles] = useState("");

  const [status, setStatus] = useState({});
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);

  const history = useHistory();

  //Placeholders internacionalizados

  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });

  const [userTipos, setUserTipos] = useState("");

  const [listaEmpresasSelecionadas] = useState("");
  const [empresas, setEmpresas] = useState([
    { value: 0, label: <FormattedMessage id="selecione" /> },
  ]);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    async function loadUser() {
      if (!history.location.state?.id) {
        return history.push("/access/users/distributor/list");
      }
      /** id do user que vem da tela anterior */
      const userId = history.location.state?.id;

      try {
        const { data: response } = await api.get(`/usuario/show/${userId}`);
        /** aba dados Cadastrais */
        var cadastros = response?.usuario[0]?.cadastros;
        var arrayEmpresasSelecionadas = [];
        cadastros.forEach(function (item, indice, array) {
          arrayEmpresasSelecionadas.push({
            value: item.id,
            label: item.cpf_cnpj + " - " + item.razao_social,
          });
        });

        arrayEmpresasSelecionadas = arrayEmpresasSelecionadas.map((item) => {
          const arr = response?.rolesPerfis.filter(
            (role) => role.cadastro_id === item.value
          );

          arr.map((element) => {
            item.role = element.role_id;
            item.empresa = element.label;

            return element;
          });

          return item;
        });

        // setIdUser(response?.usuario[0].id);
        setEmpresas(arrayEmpresasSelecionadas || []);
        setName(response?.usuario[0].name);
        setEmail(response?.usuario[0].email);
        setCelular(response?.usuario[0].celular);
        setTelefone(response?.usuario[0].telefone);

        setNivel({
          value: response?.usuario[0].user_tipo.id,
          label: response?.usuario[0].user_tipo.nome,
        });
        setStatus({
          value: response?.usuario[0].ativo,
          label: response?.usuario[0].ativo === 1 ? "Ativo" : "Inativo",
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadUser();

    async function loadUserTiposDB() {
      try {
        const { data: response } = await api.get("usuario/list-types");
        const levelOptions = [];
        const resp = response.userTipo;
        resp.forEach((tipouser) => {
          levelOptions.push({ value: tipouser.id, label: tipouser.nome });
        });
        setUserTipos(levelOptions);
      } catch (error) {}
    }
    loadUserTiposDB();

    async function loadRolesDB() {
      try {
        const { data: response } = await api.post(`/role/filter`, {
          cadastro_tipo_id: 2,
        });
        const roles = [];
        const resp = response.data;
        resp.map((role) => {
          return roles.push({ value: role.roleId, label: role.label });
        });
        // setListRoles(roles);
      } catch (error) {}
    }
    loadRolesDB();
  }, [history]);

  const handleEditUser = useCallback(async () => {
    setLoadingSave(true);
    var userId = history.location.state?.id;
    if (password !== "") {
      if (password !== passwordConfirmation) {
        toast.error(<FormattedMessage id="asPasswordsNaoCorrespondem" />);
      }
      return false;
    }

    const empresaid = empresas.map(function (empresa) {
      return empresa.value;
    });

    var nameStatus;
    nameStatus = status.value;

    var nameTipoId;
    if (typeof nivel.value !== "undefined") {
      nameTipoId = nivel.value;
    } else {
      nameTipoId = nivel[0].value;
    }

    //> *******************************************************
    let arrayRoleId = empresas.map((empresa) => {
      return empresa?.role;
    });
    //< *******************************************************

    const data = {
      cadastro:
        empresas[0].value === "todos" ? listaEmpresasSelecionadas : empresaid,
      name: name,
      email: email,
      celular: celular,
      telefone: telefone,
      tipo_id: nameTipoId,
      roles: nivel.value === 1 ? "" : arrayRoleId,
      password: password,
      ativo: nameStatus,
    };

    try {
      await api.post(`/usuario/update/${userId}`, data);

      setEmpresas([]);
      setName("");
      setEmail("");
      setPassword("");
      setPasswordConfirmation("");
      setCelular("");
      setTelefone("");
      setNivel("");
      setStatus("");
      // setRoles("");

      toast.success(<FormattedMessage id="usuarioEditadoComSucesso" />);
      setLoadingSave(false);
      setErrors(null);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      toast.error(<FormattedMessage id="erroDeEditarUsuario" />);
      setLoadingSave(false);
    }
  }, [
    history,
    empresas,
    listaEmpresasSelecionadas,
    nivel,
    name,
    email,
    password,
    passwordConfirmation,
    celular,
    telefone,
    status,
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  /** Para carregar os tipos usuario cadastrados */
  /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
  const loadUserTipos = useCallback(async () => {
    try {
      var response;
      response = await api.get(`/usuario/list-types`);

      var resp;
      if (typeof response.data.data != "undefined") {
        resp = response.data.data;
      } else {
        resp = response.data;
      }

      const userTipos = resp.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };
        return objeto;
      });

      return userTipos;
    } catch (error) {}
  }, []);

  //> ***********************************************************************
  /** Para carregar os perfis cadastrados */
  const loadPerfis = useCallback(async (id) => {
    try {
      /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
      var response;

      response = await api.post(`/role/filter`, {
        cadastro_id: `${id}`,
        ativo: 1,
      });

      var resp;
      resp = response.data.data;

      const perfis = resp.map((perfil) => {
        const objeto = {
          value: perfil.roleId,
          label: perfil.label,
        };
        return objeto;
      });

      return perfis;
    } catch (error) {
      console.log(error);
    }
  }, []);

  //< ***********************************************************************

  const handleChangeEmpresa = useCallback((item) => {
    if (!item || item?.length === 0) {
      setEmpresas([
        { value: "todos", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
      if (item) {
        const empresas = item.filter((empresa) => empresa.value !== "todos");

        setEmpresas(empresas);
      }
    }
  }, []);

  //> ***********************************************************************
  const handleChangeRole = useCallback(
    (e, index) => {
      empresas[index].role = e.value;
      setEmpresas([...empresas]);
    },
    [empresas]
  );
  //< *************************************************************************

  return (
    <Container>
      {loading ? (
        <LoaderContent />
      ) : (
        <CreateForm>
          <header>
            <h4>
              <FormattedMessage id="editarUsuario" />
            </h4>
          </header>

          <form>
            <AsyncListSelect
              labelPlaceholder={empresaPlaceholder}
              isMulti
              cacheOptions
              defaultOptions
              myOptions={"distribuidores"}
              value={empresas}
              geterror={errors?.cadastro}
              onChange={handleChangeEmpresa}
            />
            <InputLabel
              name="name"
              type="text"
              label={<FormattedMessage id="nome" />}
              onChange={(e) => setName(e.target.value)}
              value={name}
              geterror={errors?.name}
              maxLength={100}
            />
            <InputLabel
              name="email"
              type="text"
              label={"E-mail"}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              geterror={errors?.email}
              maxLength={80}
            />
            <InputLabel
              name="celular"
              label={<FormattedMessage id="celular" />}
              type="text"
              mask={MaskInput.cellphone}
              onChange={(e) => {
                return setCelular(e.target.value.replace(/[^\d]+/g, ""));
              }}
              value={celular || ""}
            />
            <InputLabel
              name="telefone"
              label={<FormattedMessage id="telefone" />}
              type="text"
              mask={MaskInput.phone}
              onChange={(e) =>
                setTelefone(e.target.value.replace(/[^\d]+/g, ""))
              }
              value={telefone || ""}
            />
            <ListSelect
              labelPlaceholder={nivelPlaceholder}
              onChange={(e) => {
                setNivel(e);
                // setRoles("");
              }}
              value={nivel}
              options={userTipos}
              loadOptions={loadUserTipos}
              placeholder={<FormattedMessage id="selecione" />}
              geterror={errors?.tipo_id}
            />

            {/* ********************************************************************** */}
            {nivel.value !== 1 && nivel.value !== undefined && (
              <ContainerRole gridColumn={empresas.length}>
                {empresas.length === 0 && (
                  <h5>
                    <FormattedMessage id="selecioneEmpresaParaInserirPerfil" />
                  </h5>
                )}
                {empresas.map((item, index) => {
                  let empresaName = item.label.split(" - ");

                  if (empresaName[1]) {
                    empresaName = empresaName[1];
                  } else {
                    empresaName = "";
                  }

                  return (
                    <AsyncListSelect
                      key={index}
                      labelPlaceholder={`${perfilPlaceholder}: ${empresaName}`}
                      onChange={(e) => {
                        return handleChangeRole(e, index);
                      }}
                      loadOptions={() => loadPerfis(item.value)}
                      isDisabled={nivel.value === 1 ? true : false}
                      cacheOptions
                      placeholder={<FormattedMessage id="selecionar" />}
                      defaultOptions
                      defaultValue={{ value: item?.role, label: item?.empresa }}
                      geterror={errors?.roles}
                    />
                  );
                })}
              </ContainerRole>
            )}
            {/* ********************************************************************** */}

            <ListSelect
              labelPlaceholder={"Status"}
              placeholder={statusPlaceholder}
              onChange={(e) => setStatus(e)}
              options={statusOptions}
              value={status}
            />
          </form>
          <hr />
          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
            <div>
              <Button
                type="submit"
                onClick={handleEditUser}
                loading={loadingSave}
              >
                <FormattedMessage id="salvar" />
              </Button>
            </div>
          </CreateFooter>
        </CreateForm>
      )}
    </Container>
  );
};

export default injectIntl(EditUser);

import React, { useCallback, useState} from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";

import {
  FiPlus,
  FiTrash2
} from "react-icons/fi";

import ButtonIcon from "./../../../components/ButtonIcon";
import api from "../../../services/api";
import { Container, CreateForm, CreateFooter, TitleTax } from "./styles";
import InputLabel from "../../../components/InputLabel";
import InputMaskDefault from "../../../components/InputMaskDefault";
import Button from "../../../components/ButtonDefault";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

export const CreateProduct = ({ intl }) => {
  const [distribuidor, setDistribuidor] = useState();
  const [listaBases, setListaBases] = useState("");
  const [bases, setBases] = useState("");

  const [associations, setAssociations] = useState([{
    produto: "",
    tipo_unitario: "",
    marca: "",
    categoria: "",
    base: "",
    valorFrete: "",
    valorProduto: "",
    vigencia: "",
    prazo: "",
    inativo: false
  }]);

  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);

  const loadDistribuidor = useCallback(async (id) => {

    try {
      const { data: response } = await api.get(`/cadastro/${id}`);

      setDistribuidor({value: response.id, label: response.cpf_cnpj + " - " + response.razao_social })

      let formattedProducts = [];


      if (response?.lista_preco_produtos.length !== 0){
        formattedProducts  =  response?.lista_preco_produtos.map((produto) => ({
          produto: {value: produto.produto?.id, label: produto.produto?.nome},
          tipo_unitario: {value: produto.produto.tipo_unitario.id, label: produto.produto.tipo_unitario.descricao},
          marca: {value: produto.produto?.marca?.id, label: produto.produto?.marca?.nome},
          categoria: {value: produto.produto?.categoria?.id, label: produto.produto?.categoria?.nome},
          base: {value: produto.base_distribuicao?.id, label: produto.base_distribuicao?.nome},
          valorFrete: produto?.valor_frete?.replace(".", ","),
          valorProduto:parseFloat(produto?.valor_produto).toFixed(2).replace(".", ","),
          vigencia: produto?.vigencia_ate_produto,
          prazo: produto?.prazo_entrega_produto,
          inativo: !produto?.ativo
        }))
      } else {
        formattedProducts = [{
          produto: "",
          tipo_unitario: "",
          marca: "",
          categoria: "",
          base: "",
          valorFrete: "",
          valorProduto: "",
          vigencia: "",
          prazo: "",
          inativo: false
         }]
      }

      console.log(formattedProducts)

      setAssociations(formattedProducts);

    } catch(err) {
      console.log(err)
    }
  },[associations])

  const history = useHistory();
  const handleCreate = useCallback(async () => {

    if (!distribuidor?.value) {
      return toast.error("Selecione um Distribuidor/TRR");
    }

    setLoadingButton(true);
    let produtoFormmated = [];
    let basesFormatted = [];

    bases?.map((base) => {
      basesFormatted.push(
        base?.value
      )
    })

    associations.map((product) => {
      produtoFormmated.push({
        base_distribuicao_id : product.base.value,
        produto_id : product.produto.value,
        valor_produto : product.inativo === true ? '0': String(product.valorProduto)?.replaceAll(
          ",",
          "."
        ),
        prazo_entrega_produto: product.inativo === true ? "0" : product.prazo,
        vigencia_ate_produto: product.inativo === true ? "2023-01-01" : product.vigencia,
        vigencia_ate_frete: product.inativo === true ? "2023-01-01" : product.vigencia,
        valor_frete : product.inativo === true ? '0': String(product.valorFrete)?.replaceAll(
          ",",
          "."
        ),
        ativo: product.inativo === false ? 1 : 0
      })
    })

    const data = {
      base_distribuicao: basesFormatted,
      lista_preco_produtos: produtoFormmated.reverse()
    };

    try {
      await api.put(`/cadastro/${distribuidor.value}`, data);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      setLoadingButton(false);
      setErrors("")
    } catch (error) {
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroCriarRegistro" />);
      setErrors(getErrorsApiValidation(error));
    }
  }, [associations, distribuidor]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeInfos = useCallback((e, index, param) => {

    if (param === "produto"){
      associations[index].produto = e;
      associations[index].marca = e.marca;
      associations[index].categoria = e.categoria;
      associations[index].tipo_unitario = e.tipo_unitario;
    }

    if (param === "base"){
      associations[index].base = e;
    }

    if (param === "valorFrete"){
      associations[index].valorFrete = e;
    }

    if (param === "valorProduto"){

      let newValue;

      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat?.length <= 1) {
        newValue = e?.target?.value + ",00";
      } else if (checkValueFormat[1]?.length < 2) {
        newValue = e?.target?.value + "00";
      } else if (
        checkValueFormat[0].length <= 2 &&
        checkValueFormat[1]?.length < 2
      ) {
        newValue = checkValueFormat[0] + ",00";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,00";
      } else {
        newValue = e?.target?.value;
      }


      associations[index].valorProduto = newValue;
    }

    if (param === "vigencia"){
      associations[index].vigencia = e;
    }

    if (param === "prazo"){
      associations[index].prazo = e;
    }

    if (param === "inativo"){
      associations[index].inativo = !associations[index].inativo;
    }

      setAssociations([...associations]);
    },
    [associations]
  );

  const handleAddAssociation = useCallback(() => {

    if (!distribuidor?.value) {
      return toast.error("Selecione um Distribuidor/TRR");
    }

    setAssociations([ {
      produto: "",
      unitario: "",
      marca: "",
      categoria: "",
      base: "",
      valorFrete: "",
      valorProduto: "",
      vigencia: "",
      prazo: "",
      inativo: false
     }, ...associations]);

  }, [associations]);

  const handleRemoveAssociation = useCallback((position) => {
    setAssociations((state) =>
      state.filter((_, index) => {
        return index !== position;
      })
    );
  }, []);

  const handleLoadBases = useCallback(async () => {
    if (!distribuidor.value) {
      return;
    }

    try {
      const { data: response } = await api.get(`/cadastro/${distribuidor.value}`);

      const arr = response?.base_distribuicao.map((base) => {
        const obj = {};

        Object.assign(obj, {
          label: base.nome,
          value: base.id,
        });

        return obj;
      });

      setBases(arr)
      return arr;
    } catch (error) {}
  },
  [distribuidor]
);

const handleLoadProducts = useCallback(async () => {
  try {
    const { data: produtos } = await api.post("/produto/filter", {
      tipo_produto: 'outros',
      ativo: 1
    });

    const arr = produtos?.data.map((produto) => {
      const obj = {};

      Object.assign(obj, {
        label: produto?.nome,
        value: produto?.id,
        categoria: {
          label: produto?.categoria?.nome,
          value: produto?.categoria?.id,
        },
        marca: {
          label: produto?.marca?.nome,
          value: produto?.marca?.id,
        },
        tipo_unitario: {
          label: produto?.tipo_unitario?.descricao,
          value: produto?.tipo_unitario?.id,
        },
      });

      return obj;
    });

    return arr;
  } catch (error) {}
},
[distribuidor]
);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="associacaoProdutos" />
          </h4>
        </header>

        <main>
          <div>
            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={<FormattedMessage id="selecione" />}
              onChange={(e) => {
                setListaBases(e?.value);

                setDistribuidor(e);
                loadDistribuidor(e.value)
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#221c46"}
              onClick={handleAddAssociation}
              placeholder={<FormattedMessage id="incluir" />}
            />
          </div>

          <div className="grid grid-template-columns">
              <strong>
                <TitleTax>
                  <FormattedMessage id="produto" />
                </TitleTax>
              </strong>
              <strong>
                <TitleTax>
                  <FormattedMessage id="tipoUnitario" />
                </TitleTax>
              </strong>
              <strong>
                <TitleTax>
                  <FormattedMessage id="marca" />
                </TitleTax>
              </strong>
              <strong>
                <TitleTax>
                  <FormattedMessage id="categoria" />
                </TitleTax>
              </strong>
              <strong>
                <TitleTax>
                  <FormattedMessage id="baseDistribuicao" />
                </TitleTax>
              </strong>
              <strong>
                <TitleTax>
                  <FormattedMessage id="valorProduto" />
                </TitleTax>
              </strong>
              {/* <strong>
                <TitleTax>
                  <FormattedMessage id="valorFrete" />
                </TitleTax>
              </strong> */}
              <strong>
                <TitleTax>
                  <FormattedMessage id="dataVigencia" />
                </TitleTax>
              </strong>
              {/* <strong>
                <TitleTax>
                  <FormattedMessage id="prazoEntregaDias" />
                </TitleTax>
              </strong> */}
              <strong>
                <TitleTax>
                  <FormattedMessage id="inativo" />
                </TitleTax>
              </strong>
            </div>

            {associations.map((association, index) => (
              <div key={association[index]} className="grid grid-template-columns" style={{marginBottom: 10}}>
                  <AsyncListSelect
                    name="produto"
                    placeholder={<FormattedMessage id="selecionar" />}
                    cacheOptions
                    defaultOptions
                    loadOptions={() => {
                      return handleLoadProducts();
                    }}
                    value={association.inativo ? "" : association?.produto}
                    style={{width: '100%'}}
                    onChange={(e) => {
                      handleChangeInfos(e, index, "produto")
                    }}
                    isDisabled={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.produto_id`]
                    }
                  />

                  <AsyncListSelect
                    name="tipo_unitario"
                    placeholder={""}
                    cacheOptions
                    defaultOptions
                    value={association.inativo ? "" : association?.tipo_unitario}
                    isDisabled
                  />

                  <AsyncListSelect
                    name="marca"
                    placeholder={""}
                    cacheOptions
                    defaultOptions
                    value={association.inativo ? "" : association?.marca}
                    isDisabled
                  />

                  <AsyncListSelect
                    name="categoria"
                    placeholder={""}
                    cacheOptions
                    defaultOptions
                    value={association.inativo ? "" : association?.categoria}
                    isDisabled
                  />

                  <AsyncListSelect
                    key={listaBases}
                    name="base"
                    placeholder={<FormattedMessage id="selecionar" />}
                    cacheOptions
                    defaultOptions
                    loadOptions={() => {
                      return handleLoadBases(listaBases);
                    }}
                    value={association.inativo ? "" : association?.base}
                    onChange={(e) => {
                      handleChangeInfos(e, index, "base")
                    }}
                    isDisabled={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.base_distribuicao_id`]
                    }
                  />
                  <InputMaskDefault
                     onChange={(e) => {
                      handleChangeInfos(e, index, "valorProduto")
                    }}
                    mask="currency2"
                    value={association.inativo ? "" : association?.valorProduto}
                    readOnly={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.valor_produto`]
                    }
                  />
{/*
                  <InputMaskDefault
                    onChange={(e) => {
                      handleChangeInfos(e.target.value, index, "valorFrete")
                    }}
                    mask="currency4"
                    value={association.inativo ? "" : association?.valorFrete}
                    readOnly={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.valor_frete`]
                    }
                  /> */}

                  <InputLabel
                     type="date"
                     style={{ color: "hsl(0,0%,50%)", width: '100%' }}
                      value={association.inativo ? "" : association?.vigencia}
                     onChange={(e) => {
                      handleChangeInfos(e.target.value, index, "vigencia")
                    }}
                    readOnly={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.vigencia_ate_produto`]
                    }
                  />
{/*
                  <InputLabel
                     type="text"
                     value={association.inativo ? "" : association?.prazo}
                     onChange={(e) => {
                      handleChangeInfos(e.target.value, index, "prazo")
                    }}
                    readOnly={association.inativo}
                    geterrorcolorbackground={
                      errors?.[`${index}.prazo_entrega_produto`]
                    }
                  /> */}

                  <input
                    type="checkbox"
                    value={association?.inativo}
                    checked={association?.inativo}
                    onChange={(e) => {
                      handleChangeInfos(e, index, "inativo")
                    }}
                  />

                  <ButtonIcon
                    Icon={FiTrash2}
                    backgroundColor={"#B30000"}
                    onClick={() => handleRemoveAssociation(index)}
                    placeholder={<FormattedMessage id="excluir" />}
                  />

              </div>
            ))}
        </main>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateProduct);

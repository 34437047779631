import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import { FiAlertCircle } from "react-icons/fi";

import ButtonIcon from "./../../../components/ButtonIcon";

import api from "./../../../services/api";
import { searchCep } from "./../../../services/cep";

import MaskInput from "./../../../config/input-mask";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import { Container, CreateForm, CreateFooter, TabStyled } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import InputMaskDefault from "./../../../components/InputMaskDefault";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import MessageDefault from "./../../../components/MessageDefault";

export const CreateDistributors = ({ intl, forwardRef }) => {
  /** o campo abaixo define o tipo de Cadastro */
  const [tipoCadastro] = useState(2);
  /** aba Dados Cadastrais */
  const [cnpj, setCNPJ] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState("");
  const [matriz, setMatriz] = useState("");
  const [empresaMatriz, setEmpresaMatriz] = useState("");
  const [classificacao, setClassificacao] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  const [consultor] = useState("");
  const [merchant, setMerchant] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  const listRoles = [
    { value: "Distribuidor", label: "Distribuidor" },
    { value: "TRR", label: "TRR" },
  ];

  const YesNoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];
  /** aba Dados de Endereço */
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [listaCidades, setListaCidades] = useState("");
  const [cidade, setCidade] = useState({});
  const [cidadeFilter, setCidadeFilter] = useState("");
  /** aba Base Distribuicao */
  const [bases, setBases] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);
  // const [setListaBasesDistribuicao] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);
  // State de erros
  const [errors, setErrors] = useState();

  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const baseDistribuicaoPlaceholder = intl.formatMessage({
    id: "baseDistribuicao",
  });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  // const consultorPlaceholder = intl.formatMessage({ id: "consultor" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  useEffect(() => {
    async function loadBases() {
      try {
        //const { data: response } = await api.get("tabela-base-distribuicao");
        //const resp = response.data;
        // const basesFormatted = resp.map((base) => {
        //   return base.id;
        // });
        // setListaBasesDistribuicao({
        //   value: "selecione",
        //   label: <FormattedMessage id="selecione" />,
        // });
      } catch (error) {}
    }
    loadBases();
  }, []);

  const loadBases = useCallback(async (inputValue) => {
    try {
      const { data: response } = await api.post(
        `/tabela-base-distribuicao-lista`,
        {
          nome: inputValue,
          ativo: 1
        }
      );

      const basesFormatted = response.map((bases) => {
        const objeto = {
          value: bases.id,
          label: bases.nome,
        };

        return objeto;
      });

      return basesFormatted;
  } catch (error) {}
}, []);

  const history = useHistory();

  const statusPlaceholder = intl.formatMessage({ id: "status" });

  /** Para carregar as Classificacoes Cliente */
  const handleLoadClassificaoDistribuidores = useCallback(async () => {
    try {
      const { data: response } = await api.get(
        `/tabela-classificacao-distribuidor`
      );

      const arr = response.data.map((mesa) => {
        const obj = {};

        Object.assign(obj, {
          value: String(mesa.id),
          label: mesa.descricao,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  //Função que cria registro
  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    const baseid = bases.map(function (base) {
      return base.value;
    });

    const objEndereco = {
      cep: cep.replace(/[^0-9]+/g, ""),
      logradouro: logradouro,
      numero: numero.replace(/[^0-9]+/g, ""),
      complemento: complemento,
      bairro: bairro,
      estado_id: estado.value,
      municipio_id: cidade.value,
      pais_id: 1,
    };

    const data = {
      dados: {
        ativo: status.value,
        cpf_cnpj: cnpj.replace(/[^0-9]+/g, ""),
        razao_social: razaoSocial,
        fantasia: nomeFantasia,
        telefone: telefone.replace(/[^0-9]+/g, ""),
        celular: celular.replace(/[^0-9]+/g, ""),
        cel_contato_2: celularDois.replace(/[^0-9]+/g, ""),
        email,
        contato,
        contato_email: email,
        contato_2: contatoDois,
        contato_email_2: emailDois,
        grupo_economico: grupoEconomico,
        perfil: perfil.value,
        matriz: matriz.value,
        empresa_matriz_id: empresaMatriz?.value,
        inscricao_estadual: inscricaoEstadual.replace(/[^0-9]+/g, ""),
        consultor_id: consultor?.value,
        cadastro_tipo_id: tipoCadastro,
        merchant: merchant?.value,
        tabela_classificacao_distribuidor_id: 1 /** valor fixo pois ainda nao existe este campo */,
        tabela_classificacao_clientes_id: classificacao.value,
        tabela_mesa_operacao_id: 1 /** 2020-09-24 - Bruno Nogueira - Sem mesa de operacao */,
      },
      endereco: objEndereco,
      base_distribuicao: bases[0].value === "selecione" ? [] : baseid,
    };

    try {
      await api.post("/cadastro", data);
      setLoadingButton(false);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }

    setErrors(null);
  }, [
    history,
    tipoCadastro,
    cnpj,
    razaoSocial,
    nomeFantasia,
    inscricaoEstadual,
    grupoEconomico,
    perfil,
    matriz,
    empresaMatriz,
    classificacao,
    contato,
    email,
    contatoDois,
    emailDois,
    telefone,
    celular,
    celularDois,
    consultor,
    status,
    cep,
    logradouro,
    numero,
    complemento,
    bairro,
    estado,
    cidade,
    bases,
    merchant
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);
  const [tabIndex, setTabIndex] = useState(0);

  // função responsável por buscar os dados do cep nos dados de endereço
  const handleSearchCEP = useCallback(async (cep) => {
    try {
      const response = await searchCep(cep);

      const { data: estado } = await api.post(`/estado/filter`, {
        sigla: response.uf,
      });

      const { data: cidade } = await api.post(`/municipio/filter`, {
        codigo: response.ibge,
      });

      setLogradouro(response.logradouro);
      setBairro(response.bairro);
      setBairro(response.bairro);
      setEstado(response.localidade);
      setListaCidades(estado[0].sigla);
      setEstado({ value: estado[0].id, label: estado[0].sigla });
      setCidade({ value: cidade[0]?.id, label: cidade[0]?.nome });
    } catch (error) {}
  }, []);

  const handleLoadUF = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }
      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });
        const arr = data.map((municipio) => {
          const obj = {};
          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });
          return obj;
        });
        return arr;
      } catch (error) {}
    },
    [cidadeFilter]
  );

  const handleChangeBase = useCallback((item) => {
    if (!item || item?.length === 0) {
      setBases([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
      if (item) {
        const bases = item.filter((base) => base.value !== "selecione");

        // const bases_array = bases.map((elemento) => elemento.value);

        setBases(bases);
      }
    }
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarDistribuidorTRR" />
          </h4>
          {errors && <ButtonIcon Icon={FiAlertCircle} geterror={errors} />}
        </header>
        <form>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            {
              <>
                <TabList>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="dadosCadastrais" />
                    {(errors?.cpf_cnpj ||
                      errors?.razao_social ||
                      errors?.fantasia ||
                      errors?.inscricao_estadual ||
                      errors?.grupo_economico ||
                      errors?.perfil ||
                      errors?.matriz ||
                      errors?.errors?.classificacao ||
                      errors?.contato ||
                      errors?.email ||
                      errors?.telefone ||
                      errors?.celular ||
                      errors?.cel_contato_2 ||
                      errors?.consultor_id) && (
                      <FiAlertCircle
                        size={15}
                        style={{ marginLeft: "5px", color: "#ff3232" }}
                      />
                    )}
                  </TabStyled>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="dadosEndereco" />
                    {(errors?.cep ||
                      errors?.logradouro ||
                      errors?.numero ||
                      errors?.bairro ||
                      errors?.estado_id ||
                      errors?.municipio_id) && (
                      <FiAlertCircle
                        size={15}
                        style={{ marginLeft: "5px", color: "#ff3232" }}
                      />
                    )}
                  </TabStyled>
                  <TabStyled className={"react-tabs__tab"} selected>
                    <FormattedMessage id="baseDistribuicao" />
                    {errors?.estado_id ||
                      (errors?.["0"] && (
                        <FiAlertCircle
                          size={15}
                          style={{ marginLeft: "5px", color: "#ff3232" }}
                        />
                      ))}
                  </TabStyled>
                </TabList>

                {/* Dados cadastrais */}
                <TabPanel>
                  <InputLabel
                    name="cnpj"
                    type="text"
                    label={"CNPJ *"}
                  required={true}
                    mask={MaskInput.CNPJ}
                    value={cnpj}
                    onChange={(e) => {
                      setCNPJ(e.target.value);
                    }}
                    geterror={errors?.cpf_cnpj}
                  />

                  <InputLabel
                    name="razaoSocial"
                    type="text"
                    label={razaoSocialPlaceholder}
            required={true}
                    maxLength={100}
                    value={razaoSocial}
                    onChange={(e) => setRazaoSocial(e.target.value)}
                    geterror={errors?.razao_social}
                  />

                  <InputLabel
                    name="nomeFantasia"
                    type="text"
                    label={nomeFantasiaPlaceholder}
            required={true}
                    maxLength={80}
                    value={nomeFantasia}
                    onChange={(e) => setNomeFantasia(e.target.value)}
                    geterror={errors?.fantasia}
                  />

                  <InputLabel
                    name="inscricaoEstadual"
                    type="text"
                    label={inscricaoEstadualPlaceholder}
                    maxLength={15}
                    value={inscricaoEstadual}
                    onChange={(e) => setInscricaoEstadual(e.target.value)}
                    geterror={errors?.inscricao_estadual}
                  />

                  <InputLabel
                    name="grupoEconomico"
                    type="text"
                    label={grupoEconomicoPlaceholder}
            required={true}
                    maxLength={50}
                    value={grupoEconomico}
                    onChange={(e) => setGrupoEconomico(e.target.value)}
                    geterror={errors?.grupo_economico}
                  />

                  <ListSelect
                    placeholder={<FormattedMessage id="selecionar" />}
                    labelPlaceholder={perfilPlaceholder}
            required={true}
                    options={listRoles}
                    onChange={(e) => setPerfil(e)}
                    value={perfil}
                    geterror={errors?.perfil}
                  />

                  <ListSelect
                    placeholder={<FormattedMessage id="selecionar" />}
                    labelPlaceholder={matrizPlaceholder}
            required={true}
                    options={YesNoOptions}
                    onChange={(e) => {
                      setMatriz(e);
                      setEmpresaMatriz("");
                    }}
                    value={matriz}
                  />

                  <AsyncListSelect
                    value={empresaMatriz}
                    onChange={(e) => {
                      setEmpresaMatriz(e);
                    }}
                    myOptions={"distribuidores"}
                    cacheOptions
                    defaultOptions
                    placeholder={<FormattedMessage id="selecionar" />}
                    labelPlaceholder={empresaMatrizPlaceholder}
                    loadingMessage={() => <FormattedMessage id="carregando" />}
                    noOptionsMessage={() => (
                      <FormattedMessage id="nenhumRegistroEncontrado" />
                    )}
                    isDisabled={matriz.value === 1}
                    geterror={errors?.matriz}
                  />

                  <AsyncListSelect
                    placeholder={<FormattedMessage id="selecionar" />}
                    labelPlaceholder={classificacaoPlaceholder}
            required={true}
                    onChange={(e) => setClassificacao(e)}
                    value={classificacao}
                    loadOptions={handleLoadClassificaoDistribuidores}
                    cacheOptions
                    defaultOptions
                    geterror={errors?.classificacao}
                  />

                  <InputLabel
                    name="contato"
                    type="text"
                    label={contatoPlaceholder}
            required={true}
                    maxLength={100}
                    value={contato}
                    onChange={(e) => setContato(e.target.value)}
                    geterror={errors?.contato}
                  />

                  <InputLabel
                    name="email"
                    type="text"
                    label={"E-mail *"}
                  required={true}
                    maxLength={80}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    geterror={errors?.email}
                  />

                  <InputLabel
                    name="telefone"
                    type="text"
                    label={telefonePlaceholder}
            required={true}
                    mask={MaskInput.phone}
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    geterror={errors?.telefone}
                  />

                  <InputLabel
                    name="celular"
                    type="text"
                    label={celularPlaceholder}
                    mask={MaskInput.cellphone}
                    value={celular}
                    onChange={(e) => setCelular(e.target.value)}
                    geterror={errors?.celular}
                  />

                  <InputLabel
                    name="contato"
                    type="text"
                    label={"Segundo Contato"}
                    maxLength={80}
                    value={contatoDois}
                    onChange={(e) => setContatoDois(e.target.value)}
                    geterror={errors?.contato_2}
                  />

                  <InputLabel
                    name="email"
                    type="text"
                    label={"Segundo E-mail"}
                    maxLength={100}
                    value={emailDois}
                    onChange={(e) => setEmailDois(e.target.value)}
                    geterror={errors?.contato_email_2}
                  />

                  <InputLabel
                    name="celular"
                    type="text"
                    label={"Segundo Celular"}
                    mask={MaskInput.cellphone}
                    value={celularDois}
                    onChange={(e) => setCelularDois(e.target.value)}
                    geterror={errors?.cel_contato_2}
                  />

                  {/* <AsyncListSelect
                    labelPlaceholder={consultorPlaceholder}
            required={true}
                    placeholder={<FormattedMessage id="selecionar" />}
                    onChange={(e) => setConsultor(e)}
                    value={consultor}
                    myOptions={"classificaoConsultores"}
                    cacheOptions
                    defaultOptions
                    geterror={errors?.consultor_id}
                  /> */}

                  <AsyncListSelect
                    labelPlaceholder={"Consultor *"}
                  required={true}
                    placeholder={<FormattedMessage id="selecionar" />}
                    cacheOptions
                    defaultOptions
                    onChange={(e) => setMerchant(e)}
                    value={merchant}
                    myOptions={"merchant"}
                    geterror={errors?.merchant}
                  />

                  <ListSelect
                    value={status}
                    onChange={(e) => setStatus(e)}
                    labelPlaceholder={statusPlaceholder}
                    cacheOptions
                    defaultOptions
                    options={statusOptions}
                  />
                </TabPanel>

                {/* Dados de endereço */}
                <TabPanel>
                  <InputLabel
                    name="cep"
                    type="text"
                    label={cepPlaceholder}
            required={true}
                    mask={MaskInput.CEP}
                    onChange={(e) => setCep(e.target.value)}
                    value={cep}
                    onBlur={() => handleSearchCEP(cep)}
                    geterror={errors?.cep}
                  />
                  <InputLabel
                    name="logradouro"
                    type="text"
                    label={logradouroPlaceholder}
            required={true}
                    maxLength={100}
                    value={logradouro}
                    onChange={(e) => setLogradouro(e.target.value)}
                    geterror={errors?.logradouro}
                  />
                  <InputMaskDefault
                    name="numero"
                    type="text"
                    label={numeroPlaceholder}
            required={true}
                    mask={"int6"}
                    value={numero}
                    onChange={(e) => setNumero(e.target.value)}
                    geterror={errors?.numero}
                  />
                  <InputLabel
                    name={"complemento"}
                    type="text"
                    label={complementoPlaceholder}
                    onChange={(e) => setComplemento(e.target.value)}
                    maxLength={50}
                    value={complemento}
                  />
                  <InputLabel
                    name={"bairro"}
                    type="text"
                    label={bairroPlaceholder}
            required={true}
                    maxLength={30}
                    value={bairro}
                    onChange={(e) => setBairro(e.target.value)}
                    geterror={errors?.bairro}
                  />
                  <AsyncListSelect
                    value={estado}
                    onChange={(e) => {
                      setCidade("");
                      setEstado(e);
                      setListaCidades(e?.label);
                    }}
                    onInput={(e) => {}}
                    loadOptions={handleLoadUF}
                    cacheOptions
                    defaultOptions
                    labelPlaceholder={estadoPlaceholder}
            required={true}
                    placeholder={<FormattedMessage id="selecionar" />}
                    loadingMessage={() => <FormattedMessage id="carregando" />}
                    isSearchable={false}
                    geterror={errors?.estado_id}
                  />
                  <AsyncListSelect
                    key={listaCidades}
                    value={cidade}
                    cacheOptions
                    defaultOptions
                    labelPlaceholder={cidadePlaceholder}
            required={true}
                    placeholder={<FormattedMessage id="selecionar" />}
                    loadOptions={() => {
                      return handleFilterCitiesByUf(listaCidades);
                    }}
                    onChange={(e) => {
                      setCidade(e);
                    }}
                    onInputChange={(e) => {
                      setCidadeFilter(e);
                    }}
                    noOptionsMessage={() => (
                      <FormattedMessage id="selecioneEstadoCarregar" />
                    )}
                    loadingMessage={() => <FormattedMessage id="carregando" />}
                    // isSearchable={false}
                    geterror={errors?.municipio_id}
                  />
                </TabPanel>

                {/* Base de distribuição */}
                <TabPanel>
                  <AsyncListSelect
                    labelPlaceholder={baseDistribuicaoPlaceholder}
            required={true}
                    placeholder={<FormattedMessage id="selecionar" />}
                    isMulti
                    cacheOptions
                    defaultOptions
                    loadOptions={loadBases}
                    value={bases}
                    onChange={handleChangeBase}
                  />
                </TabPanel>

                <MessageDefault geterror={errors?.[`0`]} />
              </>
            }
          </Tabs>
        </form>
        <hr />
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateDistributors);

import styled from "styled-components";

export const HeaderInfo = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  position: relative;

  div {
    display: block !important;

    input {
      text-align: center;
    }

    p {
      white-space: nowrap;
      padding: 0;
    }
  }

  div + div {
    height: 55px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px !important;
    margin-left: 2% !important;
    white-space: nowrap !important;
    p {
      padding: 20px !important;
      margin-bottom: 10px;
    }

    h2 {
      color: #221C46;
      margin: auto;
    }

    @media (max-width: 700px) {
      h2 {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 700px) {
    div + div {
      white-space: break-spaces !important;
    }
  }

  div + div + div {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-end !important;
    flex-direction: row;
    padding-right: 5px;

    > :first-child {
      // margin-right: 20px;
    }
  }
`;

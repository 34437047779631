import styled from "styled-components";

export const ListWithChildren = styled.ul`
  div {
    display: flex;
    align-items: center;
    /* border-bottom: 2px solid #221c46; */
    color:  #221c46;
    height: 25px;

    svg {
      height: 15px;
      width: 15px;
    }
  }

  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ListWithoutChildren = styled.li`
  height: max-content !important;
  display: block;
  align-items: center;
  /* background-color: #dbdbdb; */
  margin: 8px 0;
  /* border-radius: 5px; */
  /* border: 1px solid #221c46; */
  border: ${(props) => (props.display ? "1px solid #221c46" : "1px solid #dbdbdb")};
  > div {
    height: max-content !important;
    margin-right: 15px !important;
    /* border-right: 2px solid #221c46; */
    span {
      font-weight: bold;
      margin-right: 40px;
      padding-left: 15px;
      width: 155px;
      height: 100%;
      white-space: nowrap;
      /* color: #221c46 */

    }
  }

  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const ContainerItem = styled.div`
  svg {
    color: ${(props) => props.visible && "#e05262"};
  }
  > li {
    > button {
      border: 0;
      background: transparent;
      font-weight: ${(props) => (props.visible ? "700" : "500")};
      color: ${(props) => props.visible && "#e05262"};
      cursor: pointer;
    }
  }

  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

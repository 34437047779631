import Axios from "axios";

async function searchCep(cep) {
  const cepFormatted = cep?.replace(/[^\d]+/g, "");

  try {
    if (cepFormatted.length > 8 || cepFormatted.length < 8) {
      throw new Error("Cep inválido.");
    }

    if (isNaN(cepFormatted)) {
      throw new Error("Cep inválido.");
    }

    const response = await Axios.get(
      `https://viacep.com.br/ws/${cepFormatted}/json`
    );
    return response.data;
  } catch (error) {
    return error.message;
  }
}

export { searchCep };

import React from "react";
import { Route, Link } from "react-router-dom";
import { PageSettings } from "./../../config/page-settings.js";
import { ToolTip, ToolTip1 } from "./sidebar-profile-styles";

class SidebarNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      clicked: -1,
      toolTip: false,
    };
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState((state) => ({
      active: this.state.active === i ? -1 : i,
      clicked: 1,
    }));
  }

  render() {
    var icon = this.props.data.icon && (
      <i aria-hidden="true">{this.props.data.icon}</i>
    );
    var img = this.props.data.img && (
      <div className="icon-img">
        <img src={this.props.data.img} alt="" />
      </div>
    );
    var caret = this.props.data.children && !this.props.data.badge && (
      <b className="caret"></b>
    );
    var label = this.props.data.label && (
      <span className="label label-theme m-l-5">{this.props.data.label}</span>
    );
    var badge = this.props.data.badge && (
      <span className="badge pull-right">{this.props.data.badge}</span>
    );
    var title = this.props.data.title && (
      <span>
        {this.props.data.title} {label}
      </span>
    );
    var highlight = this.props.data.highlight && (
      <i className="fa fa-paper-plane text-theme"></i>
    );

    var toolTip = <ToolTip>{this.props.data.title}</ToolTip>;

    return (
      <PageSettings.Consumer>
        {({
          handleSidebarOnMouseOver,
          handleSidebarOnMouseOut,
          pageSidebarMinify,
          pageFloatSubMenuActive,
          pageFloatSubMenu,
        }) => (
          <Route
            path={this.props.data.path}
            exact={this.props.data.exact}
            children={({ match }) => {
              return (
                <li
                  className={
                    (match ? "active " : "") +
                    (this.props.active ||
                    (this.props.clicked === -1 && match) ||
                    this.props.data.search
                      ? "expand "
                      : "closed ") +
                    (this.props.data.children ? "has-sub " : "")
                  }
                >
                  {this.props.data.children ? (
                    <Link
                      to={this.props.data.path}
                      onMouseOver={(e) => {
                        handleSidebarOnMouseOver(e, this.props.data);
                      }}
                      onMouseOut={(e) =>
                        handleSidebarOnMouseOut(e, this.props.data)
                      }
                      onClick={this.props.expand}
                    >
                      {caret} {badge} {img} {icon} {title} {highlight}
                      {pageFloatSubMenuActive &&
                        pageFloatSubMenu.path === this.props.data.path &&
                        toolTip}
                    </Link>
                  ) : (
                    <Link
                      to={this.props.data.path}
                      onMouseOver={(e) => {
                        this.setState((state) => ({
                          toolTip: true,
                        }));
                        handleSidebarOnMouseOver(e, this.props.data);
                      }}
                      onMouseOut={(e) => {
                        this.setState((state) => ({
                          toolTip: false,
                        }));
                        handleSidebarOnMouseOut(e, this.props.data);
                      }}
                    >
                      {caret} {img} {icon} {badge} {title} {highlight}{" "}
                      {this.state.toolTip && pageSidebarMinify && (
                        <ToolTip1>{this.props.data.title}</ToolTip1>
                      )}
                    </Link>
                  )}
                  {this.props.data.children && (
                    <ul
                      className={
                        "sub-menu " +
                        ((this.props.active ||
                          (this.props.clicked === -1 && match) ||
                          this.props.data.search) &&
                        !pageSidebarMinify
                          ? "d-block "
                          : "d-none")
                      }
                    >
                      {this.props.data.children &&
                        this.props.data.children.map((submenu, i) => {
                          if (submenu.permission && submenu.userTipo === 2) {
                            if (
                              submenu.isPrivate &&
                              submenu?.permission.length > 0
                            ) {
                              return (
                                <SidebarNavList
                                  data={submenu}
                                  key={i}
                                  expand={(e) => this.handleExpand(e, i, match)}
                                  active={i === this.state.active}
                                  clicked={this.state.clicked}
                                />
                              );
                            } else {
                              return null;
                            }
                          } else {
                            if (submenu.isPrivate) {
                              return (
                                <SidebarNavList
                                  data={submenu}
                                  key={i}
                                  expand={(e) => this.handleExpand(e, i, match)}
                                  active={i === this.state.active}
                                  clicked={this.state.clicked}
                                />
                              );
                            } else {
                              return null;
                            }
                          }
                        })}
                    </ul>
                  )}
                </li>
              );
            }}
          />
        )}
      </PageSettings.Consumer>
    );
  }
}

export default SidebarNavList;

import React from "react";
import Routes from "./routes";
import AppProvider from "./context";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

const App = () => {
  return (
    <AppProvider>
      <DndProvider backend={HTML5Backend}>
        <Routes />
        <ToastContainer autoClose={3500} pauseOnHover={false} />
      </DndProvider>
    </AppProvider>
  );
};

export default App;
  
import styled, { keyframes } from "styled-components";
import DatePicker from "react-datepicker";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;
  position: relative;

  img {
    width: -webkit-fill-available;
  }

  .sideBySide {
    width: 50%;
    float: left;
    padding: 5px;
  }

  .sideBySideTwo {
    display: inline-block;
    width: 50%;
    padding: 5px;
  }

  .header-two {
    width: 100%;
    height: 34px;
    background-color: #ff7375;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 13px;
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #221c46!important;
      font-weight: 800;
      position: absolute;
      left: 0;
      top: 27%;
    }
  }

  .prazo {
    float: right;
    margin-bottom: -5px;
  }

  .grid {
    display: grid;
    gap: 3%;
  }

  .grid-template-columns-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  main {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 40px;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
      background: #221c46;
    }

  }

  @media screen and (max-width: 865px) {
    .sideBySide {
      width: 100%;
    }
    .sideBySideTwo {
      width: 100%;
    }
  }

  .grid {
    display: grid;
    gap: 0% 3px;
  }

  .grid-template-columns-1 {
    grid-template-columns: 100%;
    align-items: center;
    justify-items: center;
  }

  .grid-template-columns-2 {
    grid-template-columns: 50% 49%;
    align-items: center;
    justify-items: center;
  }

  .grid-template-columns-3 {
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-items: center;
  }

  .grid-template-columns-4 {
    grid-template-columns: 25% 25% 24.5% 24.5% ;
    align-items: center;
    justify-items: center;
  }



`;

export const CreateForm = styled.div`
  width: 100%;
  margin-top: 10px;
  /* background-color: #f8f8f8; */

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    padding: 0 2%;
  }
`;

export const InfoOrder = styled.div`
  display: flex;
  border: solid 1px #221c46;
  > div {
    p {
      font-weight: 700;
      white-space: nowrap;
      padding: 5px 10px 5px 10px;
      background: #221c46;
      color: #fff;
      margin-bottom: 5px;
    }
  }

  > :first-child {
    border-right: 1px solid #221c46;
    /* text-align: center; */
    /* padding: 0 10px; */
    strong {
      font-size: 18px;
      margin-left: 10px;
      color: #221c46;
    }

    @media screen and (max-width: 665px) {
      border: none;
    }
  }

  > :first-child + div {
    border-right: 1px solid #221c46;
    /* text-align: center; */
    /* padding: 0 10px; */
    strong {
      font-size: 18px;
      margin-left: 10px;
      color: #221c46;
    }

    @media screen and (max-width: 665px) {
      border: none;
    }
  }

  > div + div + div {
    width: 100% !important;
    margin: 0 10px;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 3px;
  }

  @media screen and (max-width: 665px) {
    flex-direction: column;
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  > div {
    width: 120px;
  }
`;

export const HeaderTax = styled.header`
  display: flex;

  align-items: center;

  > strong {
    margin-right: 20px;
  }
`;

export const FormTax = styled.div`
  width: 100%;
  margin-bottom: 10px;
  > ul {
    > div {
      display: flex;
      margin: 0;
      margin-left: -5px;

      li {
        height: 100%;
        width: 100%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
`;

export const FormProducts = styled.div`
  width: 100%;
  padding: 0 2%;

  img {
    width: -webkit-fill-available;
  }

  section {
    display: grid;
    grid-template-columns: 24% 15% 12% 12% 8% 8% 15%;
    grid-gap: 1%;
  }

  nav {
    display: grid;
    grid-template-columns: 5% 15% 8% 9% 9% 10% 10% 7% 7% 10%;
    grid-gap: 1%;
  }

  li {
    width: 100%;
    text-align: center;
    align-self: center;

    >div {
      margin-bottom: 0!important;

      div {
      margin-bottom: 0!important
    }
    }
  }

  .produto {
    button {
      font-size: 12px!important;
      min-height: 30px!important;
      height: 33px!important;
    }
  }

`;

export const TitleTax = styled.strong``;

export const LoadingPrazos = styled.span`
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  margin-left: 10px;
  color: #221c46;
  font-weight: bold;
`;

export const HeaderOrder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .infoPedido {
    float: right;
    margin-right: 6px;
  }

  @media (max-width: 700px) {
    .infoPedido {
      width: 100%;
      p {
        margin-bottom: 1px;
      }
      div {
        margin-bottom: 10px;
      }
    }
  }
`;

export const ProgramOrderContainer = styled.div`
  max-height: 280px;
  height: 100%;
  max-width: 430px;
  width: 100%;
  position: absolute;
  top: 180px;
  /* background-color: #e8e8e8; */
  border: 1px solid #d0d0d0;
  z-index: 999;

  @media (max-width: 700px) {
    top: 60% !important;
  }

  > header {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid #d0d0d0;

    > h3 {
      margin-top: 7px;
    }

    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #222;
      margin-left: 10px;
      :hover {
        color: #e05262;
      }
    }
  }
  > main {
    padding: 0;
    margin: 0;
    padding-top: 20px;
    margin: 0 20px;

    > header {
      display: flex;
      align-items: center;

      > :first-child {
        margin-top: 7px;
        margin-right: 20px;
      }
    }

    > div {
      margin: 0px;
      margin-top: 10px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;

      > div {
        display: flex;
        align-items: center;

        > :first-child {
          margin-right: 2px;
        }

        > :nth-child(2) {
          margin-right: 5px;
        }
      }
    }
  }
`;

export const DatePickerComponent = styled(DatePicker).attrs()`
  height: 35px;
  border: 1px solid #d0d0d0;
  text-align: center;
  position: relative;

  color: black;

  .react-datepicker__day {
    color: black !important;
  }

  @media (max-width: 700px) {
    width: 115px;
  }
`;

export const FooterDatePicker = styled.footer`
  margin: 5px;
  div {
    display: flex;
    align-items: center;
    margin: 2px 0;

    div {
      height: 16px;
      width: 16px;
      margin-right: 10px;

      strong {
        color: #000;
        font-size: 12pxx;
      }
    }
  }
`;

export const InfoMotoristaContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 3%;
  top: 22%;

  border: 1px solid #2d353c;

  width: 450px !important;
  height: auto;

  background-color: #fff;
  z-index: 101;

  @media (max-width: 700px) {
    width: 90% !important;
    margin-top: 170px;
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  header {
    width: 100%;
    height: 45px;
    background-color: #2d353c;

    /* display: flex; */
    /* justify-content: space-between; */
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 15px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;
      margin-left: auto;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 865px) {
    top: 36%;
  }
`;

export const ContainerButton = styled.div`
  padding: 3px 10px 3px 10px;

  button {
    border-width: 0;
    color: #fff;
    /* background-color: #355ca5; */
    font-size: 12px;
    text-align: center;
    padding: 4px 15px 4px 15px;
    font-weight: bold;
    margin-right: 10px;
  }
`;

import styled from "styled-components";

export const AlignGraph = styled.div`
  .apexcharts-legend.position-bottom .apexcharts-legend-series,
  .apexcharts-legend.position-top .apexcharts-legend-series {
    width: 160px !important;
  }

  .apexcharts-legend.position-bottom.apexcharts-align-center,
  .apexcharts-legend.position-top.apexcharts-align-center {
    justify-content: left !important;
    inset: auto 7% 1px !important;
  }
`;

export const Search = styled.div`
  width: 100%;
  margin-left:auto;
  padding: 0 10px;
`;


import React from "react";
// import ReactDOM from "react-dom";
import App from "./app.jsx";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { IntlProvider } from "react-intl";
import { flattenMessages } from "./utils/flattenMessages";
import messages from "./lang";
import Language, { LanguageProvider } from "./context/language";

import GlobalStyles from "./styles/global";

// css
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
//import "@fortawesome/fontawesome-free/css/all.min.css";
//import "react-quill/dist/quill.snow.css";
//import "codemirror/lib/codemirror.css";
//import "codemirror/theme/material.css";
//import 'simple-line-icons/css/simple-line-icons.css';
import "flag-icon-css/css/flag-icon.min.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.css";
import "./scss/react.scss";
//import "bootstrap-social/bootstrap-social.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-tabs/style/react-tabs.css";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "./styles/stylesDatePicker.css";

// ========================================

//addLocaleData([...en, ...pt])

//let params = new URLSearchParams(document.location.search.substring(1));

var locale;

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <GlobalStyles />
    <LanguageProvider>
      <Language.Consumer>
        {({ language }) => {
          if (language) {
            locale = language.replace("_", "-");
          }
          return (
            <IntlProvider
              locale={locale}
              messages={flattenMessages(messages[locale])}
            >
              <App />
            </IntlProvider>
          );
        }}
      </Language.Consumer>
    </LanguageProvider>
  </BrowserRouter>,
  // document.getElementById("root")
);

import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

export const CustomerConditions = ({ intl }) => {
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const [cliente, setCliente] = useState("");
  // const [periodo, setPeriodo] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    try {

      const checkedCliente = !cliente?.value ? "" : cliente?.value;
      // const checkedPeriodo = !periodo?.value ? "" : periodo?.value;

      const data = {
        saida: "excel",
        // periodo: checkedPeriodo,
        dataDe: dataInicial || "",
        dataAte: dataFinal || "",
        filtroCliente: checkedCliente,
        grupoEconomico: grupoEconomico,
      };

        await api
          .get(`/relatorio/condicoes-comerciais-clientes`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Relatorio-Condicoes-Comerciais-Clientes.xlsx"
            );
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          })
          .catch((err) => console.log(err));

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    cliente,
    dataFinal,
    dataInicial,
    grupoEconomico,
    // periodo,
  ]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioCondicoesComerciaisClientes" />
          </h4>
        </header>

        <form>
          <div className="sideBySide">
          <InputLabel
              name="date"
              label={"Data Inicio Faturamento"}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
{/*
            <ListSelect
              name="frete"
              labelPlaceholder={<FormattedMessage id="periodo" />}
              placeholder={<FormattedMessage id="selecionar" />}
              cacheOptions
              defaultOptions
              options={periodoOptions}
              value={periodo}
              onChange={(e) => setPeriodo(e)}
              isClearable
            /> */}

            <InputLabel
              name="grupo economico"
              type="text"
              value={grupoEconomico}
              label={<FormattedMessage id="grupoEconomico" />}
              onChange={(e) => {
                setGrupoEconomico(e.target.value);
              }}
              maxLength={50}
            />
          </div>

          <div className="sideBySideTwo">
          <InputLabel
              name="date"
              label={"Data Fim Faturamento"}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />

            <AsyncListSelect
              value={cliente}
              labelPlaceholder={<FormattedMessage id="cliente" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setCliente(e);
              }}
              myOptions={"clientes"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </div>
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="gerar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CustomerConditions);

import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { FormPagination, Total } from "./styles";

const Paginate = ({
  handleUpdateListPaginate,
  currentPg,
  firstPage,
  nextPage,
  prevPage,
  lastPage,
  lastPageUrl,
  countPage,
  totalCotacao,
  totalPedido,
  total,
  local
}) => {
  const [currentPage, setCurrentPage] = useState(currentPg);
  const [countPageState, setCountPageState] = useState([]);

  useEffect(() => {
    setCountPageState(countPage);
  }, [countPage]);

  const handlePaginationByNumber = useCallback(
    (pageNumber) => {
      let url;

      url = firstPage.split("?page=");

      let newUrl = url[0] + `?page=${pageNumber}`;

      if (url?.length === 1) {
        url = firstPage.split("&page=");
        newUrl = url[0] + `&page=${pageNumber}`;
      }

      handleUpdateListPaginate(newUrl);

      setCurrentPage(pageNumber);
    },
    [firstPage, handleUpdateListPaginate]
  );

  const handlePrevOrNextPage = useCallback(
    (action) => {
      if (action === "next") {
        handleUpdateListPaginate(nextPage);
        setCurrentPage((state) => state + 1);
      } else if (action === "prev") {
        handleUpdateListPaginate(prevPage);
        setCurrentPage((state) => state - 1);
      }
    },
    [handleUpdateListPaginate, nextPage, prevPage]
  );

  const handleFirstOrLastPage = useCallback(
    (action) => {
      if (action === "first") {
        handleUpdateListPaginate(firstPage);

        setCurrentPage(1);
      } else if (action === "last") {
        const url = lastPageUrl.split("?page=");

        handleUpdateListPaginate(lastPageUrl);

        setCurrentPage(parseInt(url[1]));
      }
    },
    [firstPage, handleUpdateListPaginate, lastPageUrl]
  );

  return (
    <>
    {local === "painel" ? <Total>Total de pedidos: {totalPedido} / Total de cotações: {totalCotacao}</Total> :
      local === "clienteXdistribuidor" ?  <Total>Total de pedidos: {total}</Total> :
      <Total>Total de registros: {total}</Total>}

      <FormPagination>
        <button
          onClick={() => handleFirstOrLastPage("first")}
          disabled={currentPage <= 1 ? true : false}
        >
          {"<"}
        </button>{" "}
        <button
          onClick={() => handlePrevOrNextPage("prev")}
          disabled={currentPage === 1 ? true : false}
        >
          <FormattedMessage id="anterior" />
        </button>{" "}
        {countPageState?.map((item) => {
          return (
            <button
              key={item}
              disabled={item === currentPage ? true : false}
              onClick={() => handlePaginationByNumber(item)}
            >
              {item}
            </button>
          );
        })}
        <button
          onClick={() => handlePrevOrNextPage("next")}
          disabled={currentPage === lastPage ? true : false}
        >
          <FormattedMessage id="proximo" />
        </button>
        <button
          onClick={() => handleFirstOrLastPage("last")}
          disabled={currentPage >= lastPage ? true : false}
        >
          {">"}
        </button>{" "}
      </FormPagination>
    </>
  );
};

export default Paginate;

import React, { useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import api from "./../../../services/api";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import { Container, CreateForm, CreateFooter } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const CreateDistributionBase = ({ intl, forwardRef }) => {
  const [nome, setNome] = useState("");
  const [regiao, setRegiao] = useState({});
  const [estadoId, setEstadoId] = useState("");
  const [cidade, setCidade] = useState({});
  const [status, setStatus] = useState({ value: 1, label: <FormattedMessage id="ativo" /> });
  const [listaEstados, setListEstados] = useState("uf");
  const [listaCidades, setListaCidades] = useState("cidade");
  const [cidadeFilter, setCidadeFilter] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const [errors, setErrors] = useState();

  const history = useHistory();

  const refRegion = useRef();

  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const nomePlaceholder = intl.formatMessage({ id: "nome" });
  const regiaoPlaceholder = intl.formatMessage({ id: "regiao" });
  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  // Lista campos de Região
  const handleLoadRegion = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado/regiao`);

      const arr = data.map((regiao) => {
        const obj = {};

        Object.assign(obj, {
          value: regiao.regiao,
          label: regiao.regiao,
        });

        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  // Lista campos de UF
  const handleFilterUfByRegion = useCallback(async (regiao) => {
    if (!regiao) {
      return;
    }

    try {
      const { data } = await api.post(`/estado/filter`, {
        regiao,
      });

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  // Lista campos de Cidade
  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }

      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });

        const arr = data.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });
        return arr;
      } catch (error) {}
    },
    [cidadeFilter]
  );

  //Função que cria registro
  const handleCreate = useCallback(async () => {
    setLoadingButton(true);
    const data = {
      nome,
      regiao,
      estado_id: estadoId,
      municipio_id: cidade?.value,
      ativo: status?.value,
    };

    try {
      await api.post("/tabela-base-distribuicao", data);
      setLoadingButton(false);
      toast.success(<FormattedMessage id="baseDistribuiçãoCriadoSucesso" />);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      return toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
    setNome("");
    setRegiao("");
    setEstadoId(null);
    setCidade("");
    setListEstados("uf");
    setListaCidades("cidade");
    setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
    setErrors(null);

    refRegion.current.select.onChange(() => {
      refRegion.current.select.state.value = "";
    });
  }, [nome, regiao, estadoId, cidade, status]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarBaseDistribuicao" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="name"
            type="text"
            label={nomePlaceholder}
            required={true}
            maxLength={80}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            geterror={errors?.nome}
          />

          <AsyncListSelect
            ref={refRegion}
            onChange={(e) => {
              setListaCidades("");
              setEstadoId("");
              setCidade("");
              setRegiao(e.label);
              setListEstados(e.label);
            }}
            cacheOptions
            defaultOptions
            loadOptions={handleLoadRegion}
            labelPlaceholder={regiaoPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="erroCarregarRegioes" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            isSearchable={false}
          />

          <AsyncListSelect
            key={listaEstados}
            onChange={(e) => {
              setCidade("");
              setListaCidades(e.label);
              setEstadoId(e.value);
            }}
            loadOptions={() => handleFilterUfByRegion(listaEstados)}
            cacheOptions
            defaultOptions
            labelPlaceholder={estadoPlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="selecioneRegiaoCarregar" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            geterror={errors?.estado_id}
          />

          <AsyncListSelect
            key={listaCidades}
            cacheOptions
            defaultOptions
            loadOptions={() => {
              return handleFilterCitiesByUf(listaCidades);
            }}
            onChange={(e) => {
              setCidade(e);
            }}
            onInputChange={(e) => {
              setCidadeFilter(e);
            }}
            labelPlaceholder={cidadePlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="selecioneEstadoCarregar" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            geterror={errors?.municipio_id}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder={statusPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateDistributionBase);

import React, { useCallback, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";
import { Container, CreateForm, CreateFooter } from "./styles";

import api from "./../../../services/api";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const EditDistributionBase = ({ intl }) => {
  const [id, setId] = useState();
  const [nome, setNome] = useState("");

  const [uf, setUf] = useState({});

  const [cidade, setCidade] = useState({});
  const [status, setStatus] = useState();
  const [listaEstados, setListEstados] = useState("uf");
  const [listaCidades, setListaCidades] = useState("cidade");

  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState();

  const history = useHistory();

  const refRegion = useRef();

  useEffect(() => {
    if (!history.location.state?.id) {
      return history.push("/register/sodexo/distribution-base/list");
    }
    setId(history.location.state?.id);
    setNome(history.location.state?.nome);

    setUf({
      value: history.location.state?.estado_id,
      label: history.location.state?.uf,
    });
    setCidade({
      value: history.location.state?.cidade_id,
      label: history.location.state?.cidade,
    });
    setStatus(
      history.location.state?.status === 1
        ? { value: 1, label: <FormattedMessage id="ativo" /> }
        : { value: 0, label: <FormattedMessage id="inativo" /> }
    );

    refRegion.current.select.state.value = {
      value: history.location.state?.regiao,
      label: history.location.state?.regiao,
    };

    setListEstados(history.location.state?.regiao);
    setListaCidades(history.location.state?.uf);
  }, [history]);

  const handleLoadRegion = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado/regiao`);

      const arr = data.map((regiao) => {
        const obj = {};

        Object.assign(obj, {
          value: String(regiao.regiao),
          label: regiao.regiao,
        });

        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  const handleFilterUfByRegion = useCallback(async (regiao) => {
    if (!regiao) {
      return;
    }

    try {
      const { data } = await api.post(`/estado/filter`, {
        regiao,
      });

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const handleFilterCitiesByUf = useCallback(async (id) => {
    if (!id) {
      return;
    }

    try {
      const { data } = await api.post(`/municipio/filter`, {
        estado: id,
      });

      const arr = data.map((municipio) => {
        const obj = {};

        Object.assign(obj, {
          value: String(municipio.id),
          label: municipio.nome,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const nomePlaceholder = intl.formatMessage({ id: "nome" });
  const regiaoPlaceholder = intl.formatMessage({ id: "regiao" });
  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleEdit = useCallback(async () => {
    setLoadingButton(true);

    var estado = uf?.value;

    if (uf?.label === "0") {
      estado = null;
    }
    const data = {
      nome,
      estado_id: estado,
      municipio_id: parseInt(cidade?.value),
      ativo: status?.value,
    };

    try {
      await api.put(`/tabela-base-distribuicao/${id}`, data);
      toast.success(<FormattedMessage id="registroEditadoComSucesso" />);
      setLoadingButton(false);
      history.goBack();
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);
      toast.error(<FormattedMessage id="erroEditarRegistro" />);
    }
  }, [nome, uf, cidade, status, history, id]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            {" "}
            <FormattedMessage id="editarBaseDistribuicao" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="name"
            type="text"
            label={nomePlaceholder}
            required={true}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            geterror={errors?.nome}
          />

          <AsyncListSelect
            ref={refRegion}
            onChange={(e) => {
              setListEstados(e.label);
              setListaCidades("");
              setUf("");
              setCidade("");
            }}
            cacheOptions
            defaultOptions
            loadOptions={handleLoadRegion}
            labelPlaceholder={regiaoPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="erroCarregarRegioes" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            isSearchable={false}
          />

          <AsyncListSelect
            key={listaEstados}
            value={uf}
            onChange={(e) => {
              setListaCidades(e.label);
              setUf(e);
              setCidade("");
            }}
            loadOptions={() => handleFilterUfByRegion(listaEstados)}
            cacheOptions
            defaultOptions
            labelPlaceholder={estadoPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="selecioneRegiaoCarregar" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            isSearchable={false}
            geterror={errors?.estado_id}
          />

          <AsyncListSelect
            key={listaCidades}
            value={cidade}
            onChange={(e) => {
              setCidade(e);
            }}
            loadOptions={() => handleFilterCitiesByUf(listaCidades)}
            cacheOptions
            defaultOptions
            labelPlaceholder={cidadePlaceholder}
            required={true}
            placeholder={<FormattedMessage id="selecionar" />}
            noOptionsMessage={() => (
              <FormattedMessage id="selecioneEstadoCarregar" />
            )}
            loadingMessage={() => <FormattedMessage id="carregando" />}
            isSearchable={false}
            geterror={errors?.municipio_id}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder={statusPlaceholder}
            placeholder={<FormattedMessage id="selecionar" />}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button type="button" onClick={handleEdit} loading={loadingButton}>
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(EditDistributionBase);

import React from "react";

import LogoWizeo from "./../../assets/images/wizeo-transparent-medium.png";
import NotFoundImg from "./../../assets/images/page-not-found.png";

import { FormattedMessage } from "react-intl";

import { Container } from "./styles";
const PageNotFound = () => {
  return (
    <Container>
      <img src={LogoWizeo} alt="logo-wizeo" />
      <div>
        <img src={NotFoundImg} alt="not-found" />
        <h2>
          <FormattedMessage id="paginaNaoEncontrada" />
        </h2>
      </div>
    </Container>
  );
};

export default PageNotFound;

import React, { useRef, useContext } from "react";

import { Container, TextArea, MessageError } from "./styles";
import Language from "./../../context/language";

const TextAreaLabel = ({
  placeholder,
  readOnly,
  value,
  defaultValue,
  onChange,
  geterror,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { language } = useContext(Language);

  return (
    <>
      <Container readOnly={readOnly}>
        <p>{placeholder}</p>

        <TextArea
          ref={inputRef}
          {...rest}
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        {geterror && (
          <MessageError>
            <div>
              {geterror.map((err) => {
                return <p key={err}>{err[language]}</p>;
              })}
            </div>
          </MessageError>
        )}
        {/* {geterror && isErrored ? (
          <Error>
            <p onClick={handleFocus}>{geterror}</p>
          </Error>
        ) : null} */}
      </Container>
    </>
  );
};

export default TextAreaLabel;

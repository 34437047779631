import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;

  display: flex;
`;

export const SignContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    width: 75%;

    img {
      width: 200px;
      height: 60px;
      margin-bottom: 7px;
    }

    header {
      margin-top: 5px;
      margin-bottom: 24px;
    }

    h5 {
      margin-top: 5px;
      margin-bottom: 24px;
    }

    footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        margin-top: 10px;
        color: #707478;
      }
    }

    span {
      font-size: 16px;
    }

    span:last-child {
      color: #707478;
      margin-top: 15px;
      text-align: center;
      font-size: 12px;
    }
  }
`;

export const Background = styled.div`
  flex: 1;
  width: auto;
  height: 100%;
  z-index: -1;
  background-color: #fcfeff;

  img {
    width: 100%;
    height: 100%;

    @media (max-width: 850px) {
      object-fit: cover;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #ededed;
`;

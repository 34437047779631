import React, { useContext, useEffect } from "react";
import { Route, withRouter, Switch, useHistory } from "react-router-dom";

import PageNotFound from "./../../pages/PageNotFound";
import { PageSettings } from "./../../config/page-settings.js";
import AuthContext from "./../../context/auth";
import RoutesContext from "./../../context/routes";

function setTitle(path, routeArray) {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = "Trade";
    }
  }
  document.title = pageTitle ? pageTitle : "Trade";
}

const Content = () => {
  const { user } = useContext(AuthContext);
  const { routes } = useContext(RoutesContext);
  const history = useHistory();

  useEffect(() => {
    setTitle(history.location.pathname, routes);
  }, [history.location.pathname, routes]);

  return (
    <PageSettings.Consumer>
      {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
        <div
          className={
            "content " +
            (pageContentFullWidth ? "content-full-width " : "") +
            (pageContentInverseMode ? "content-inverse-mode " : "") +
            pageContentClass
          }
          style={{ padding: "7px" }}
        >
          <Switch>
            {routes.map((route, index) => {
              if (route.permission && user.tipo_id === 2) {
                if (route.permission.length > 0) {
                  // Faz a verificação do cadastro_tipo_id para poder criar a rota.
                  if (
                    (route?.cadastro_tipo_id &&
                      route?.cadastro_tipo_id.filter(
                        (cad) => cad === user?.cadastro_tipo_id
                      ).length >= 1) ||
                    route?.cadastro_tipo_id.length === 0
                  ) {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              } else {
                // Faz a verificação do cadastro_tipo_id para poder criar a rota.
                if (
                  (route?.cadastro_tipo_id &&
                    route?.cadastro_tipo_id.filter(
                      (cad) => cad === user?.cadastro_tipo_id
                    ).length >= 1) ||
                  route?.cadastro_tipo_id.length === 0
                ) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                } else {
                  return null;
                }
              }
            })}
            <Route path="*" component={() => <PageNotFound />} />
          </Switch>
        </div>
      )}
    </PageSettings.Consumer>
  );
};

export default withRouter(Content);

import React from "react";

import { Route } from "react-router-dom";

import SignIn from "./../pages/SignIn";
import Recovery from "./../pages/Recovery";
import SetEmailRecovery from "./../pages/SetEmailRecovery";

const AuthRoutes = () => {
  return (
    <>
      <Route path="/signin" exact component={SignIn} />
      <Route path="/recovery-account" exact component={Recovery} />
      <Route path="/set-email-recovery" exact component={SetEmailRecovery} />
      {/* <Redirect to="/signin" /> */}
    </>
  );
};

export default AuthRoutes;

import React, { useEffect, useState, useContext, useCallback } from "react";
import ImageBanner from "../../../components/ImageBanner";
import Card from "../../../components/Card";
import { Modal } from "react-bootstrap";
import { useTable, usePagination } from "react-table";
import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/es";
import "moment/locale/en-au";

import { FiFileText } from "react-icons/fi";

import { FormattedMessage, injectIntl } from "react-intl";
import Alert from "./../../../assets/images/alert-branco.png";

import { Container, ContainerInfo, ContainerPage } from "./styles";

import api from "../../../services/api";
import { useHistory } from "react-router-dom";

import Language from "../../../context/language";

import ContentSpinner from "../../../components/Spinner/Content";

import GraphVolumeVendido from "../../DashboardGraphs/DistributorVolumeVendido";

const ListHome = ({ intl }) => {
  const [modal, setModal] = useState(false);
  const [bannerLeftTop, setBannerLeftTop] = useState(false);
  const [bannerLeftBottom, setBannerLeftBottom] = useState(false);
  const [bannerBottom, setBannerBottom] = useState(false);

  const [srcModal, setSrcModal] = useState("");
  const [srcLeftTop, setSrcLeftTop] = useState("");
  const [srcLeftBottom, setSrcLeftBottom] = useState("");
  const [srcBottom, setSrcBottom] = useState("");

  const [linkModal, setLinkModal] = useState(null);
  const [linkLeftTop, setLinkLeftTop] = useState(null);
  const [linkLeftBottom, setLinkLeftBottom] = useState(null);
  const [linkBottom, setLinkBottom] = useState(null);

  const [loadingList, setLoadingList] = useState(true);

  const [cotacoesResponderAnalise, setCotacoesResponderAnalise] = useState("");
  const [cotacoesResponderReanalise, setCotacoesResponderReanalise] = useState("");
  const [cotacoesRespondidas, setCotacoesRespondidas] = useState("");
  const [pedidosVendidos, setPedidosVendidos] = useState("");
  const [M3LitrosVendidos, setM3LitrosVendidos] = useState("");
  const [reaisVendidos, setReaisVendidos] = useState("");
  const [ultimaData, setUltimaData] = useState("");
  const [litrosPendentes, setLitrosPendentes] = useState("");

  const [precos, setPrecos] = useState([]);
  const [data, setData] = useState([]);

  const [dados, setDados] = useState(true);

  const history = useHistory();

  const { language } = useContext(Language);

  const distribuidorPlaceholder = intl.formatMessage({
    id: "distribuidor",
  });

  const vendidosPlaceholder = intl.formatMessage({
    id: "vendidos",
  });

  const totalLitrosPendentes = intl.formatMessage({
    id: "totalLitrosPendentes",
  });

  const melhoresPrecosHojePlaceholder = intl.formatMessage({
    id: "melhoresPrecosHoje",
  });

  useEffect(() => {
    (async () => {
      try {
        const dateFilter = moment().format("YYYY-MM-DD");
        const { data: response } = await api.post("/comunicacao/filter", {
          exibir_distribuidor: 1,
          data_inicio_fim: dateFilter,
        });

        response.data.forEach((item) => {
          if (item.posicao === "Rodapé" && item.local === "Home") {
            setBannerBottom(true);
            setSrcBottom(item.link_arquivo);
            if (item.link !== null) {
              setLinkBottom(item.link);
            }
          }

          if (item.posicao === "Direita Alta" && item.local === "Home") {
            setBannerLeftTop(true);
            setSrcLeftTop(item.link_arquivo && item.local === "Home");
            if (item.link !== null) {
              setLinkLeftTop(item.link);
            }
          }

          if (item.posicao === "Direita Baixa" && item.local === "Home") {
            setBannerLeftBottom(true);
            setSrcLeftBottom(item.link_arquivo);
            if (item.link !== null) {
              setLinkLeftBottom(item.link);
            }
          }

          if (item.posicao === "Centro" && item.local === "Home") {
            setModal(true);
            setSrcModal(item.link_arquivo);
            if (item.link !== null) {
              setLinkModal(item.link);
            }
          }
        });

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }

      try {
        const { data: response } = await api.get("/home");

        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [day, month, year].join('/');
      }

        setCotacoesResponderAnalise(response?.CotacoesResponderAnalise);
        setCotacoesResponderReanalise(response?.CotacoesResponderReanalise);
        setCotacoesRespondidas(response?.CotacoesRespondidas);
        setPedidosVendidos(response?.PedidosVendidos);
        setM3LitrosVendidos(response.M3LitrosVendidos/1000 + " / " + response.M3LitrosVendidos);
        setReaisVendidos(response?.ReaisVendidos);
        setLitrosPendentes(response?.totalLitrosPendentes/1000 + " / " + response?.totalLitrosPendentes);

        let column = [];
        let bases = [];
        let seuPreco = [];
        let precosItems = [];
        var obj = {};

        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        if (response.MelhoresPrecoHoje === 0) {
          setDados(false);
        } else {
          setUltimaData(formatDate(response.dataUltimoRegistro));

          response.MelhoresPrecoHoje.map((item) => {
            column.push(item.descricaoProduto);
            bases.push(item.nomeBaseDistribuicao);
            seuPreco.push(item.menorPreco);

            precosItems.push({
              base: item.nomeBaseDistribuicao,
              seuPreco: item.menorPreco,
              produto: item.descricaoProduto,
              preco:
                "< " +
                item.menorPreco +
                " | " +
                item.maiorPreco +
                " > || " +
                item.ultimoPrecoDistribuidor,
            });

            return { column, bases, seuPreco };
          });

          column = column.filter(onlyUnique);

          //Estrutura sem a parte de valores, Data, Base e Distribuidores
          bases = bases.filter(onlyUnique);

          const basesEDistribuidores = bases.map((base) => {
            let stringSplit = base.split("@Artium@");

            obj = { Base: stringSplit[0] };
            const produtoId = [];
            column.map((prod) => {
              const preco = precosItems.filter((element) => {
                return element.base === base && element.produto === prod;
              });

              produtoId.push(preco[0]?.id);

              return (obj[prod] = preco[0]?.preco.replace(".", ",") || 0);
            });

            return Object.assign(obj, { produtoIds: produtoId });
          });

          setPrecos(basesEDistribuidores);
          setData(basesEDistribuidores);
        }

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
      }
    })();
  }, []);


  const columns = React.useMemo(() => {
    var cols;
    var headers = [];

    if (precos.length > 0) {
      cols = Object.keys(precos[0]);
      cols = cols.filter((c) => c !== "base_id" && c !== "produtoIds");
      headers = cols.map((c) => {
        return {
          Header: c,
          accessor: c,
        };
      });
    }
    return headers;
  }, [precos]);

  function Table({ columns, data, updateMyData }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
    } = useTable(
      {
        columns,
        data,
        updateMyData,
      },
      usePagination
    );

    return (
      <>
        <h2>{melhoresPrecosHojePlaceholder + " " + ultimaData}</h2>
        <table id="table-to-xls" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <>
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      if(cell.value !== 0){
                        var price = cell.value.split("||");
                        return <td {...cell.getCellProps()}>{price[0]}</td>;
                      } else {
                        return <td {...cell.getCellProps()}>0</td>;
                      }


                    })}
                  </tr>
                  <tr style={{color: '#aa1f2f'}}>
                    {row.cells.map((cell) => {
                      if(cell.value !== 0){
                        var price = cell.value.split("||");
                        if (cell.column.Header === "Base") {
                          return <td {...cell.getCellProps()}>Seu preço</td>;
                        } else {
                          if (price[1] === " null" || price[1] === " undefined"){
                            return <td {...cell.getCellProps()}>0,0000</td>;
                          } else {
                            return <td {...cell.getCellProps()}>{price[1]}</td>;
                          }
                        }
                      } else {
                        return <td {...cell.getCellProps()}>0</td>;
                      }

                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  if (language === "en") {
    moment.locale("en-au");
  } else if (language === "es") {
    moment.locale("es");
  } else if (language === "pt_BR") {
    moment.locale("pt-br");
  }

  const date = moment().format("MMMM/YYYY");
  const mesAno = date[0].toUpperCase() + date.substr(1);

  const handlePainelFilter = useCallback(
    (statusCard) => {
      const status = {
        statusCard,
      };

      history.push("/orders/painel", status);
    },
    [history]
  );

  return (
    <>
    <Modal
        centered={true}
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body >
          <ImageBanner
            src={srcModal}
            height={500}
            width={"100%"}
            display={modal ?  0 : 1}
            link={linkModal !== null ? linkModal : null}
            style={{margin: "-10px 0", padding: '5px 0px'}}
          />
        </Modal.Body>
      </Modal>

      <Container>
        <header>
          <nav>
            <h2>
              Home {distribuidorPlaceholder} - {mesAno}
            </h2>
          </nav>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <ContainerPage columns={srcLeftTop || srcLeftBottom ? true : false}>
              <ContainerInfo>
                <div className="grid grid-template-columns-cards">

                  <div style={{ display: "grid", gridTemplateColumns: "49% 49%", gap: "2%"}}>
                    <div onClick={() => handlePainelFilter("Análise Distribuidor/TRR")} style={{cursor: "pointer"}}>
                      <Card
                        text={"Pedidos Pendentes (Análise)"}
                        number={cotacoesResponderAnalise}
                        progress={"100%"}
                        icon={"fa fa-globe fa-fw"}
                        href={"/orders/painel"}
                        permission="cards-home[2]"
                      />
                    </div>

                    <div onClick={() => handlePainelFilter("Reanálise Distribuidor/TRR")} style={{cursor: "pointer"}}>
                     <Card
                      text={"Pedidos Pendentes (Reanálise)"}
                      number={cotacoesResponderReanalise}
                      progress={"100%"}
                      icon={"fa fa-globe fa-fw"}
                      href={"/orders/painel"}
                      permission="cards-home[2]"
                    />
                    </div>
                   </div>

                  <Card
                    text={totalLitrosPendentes}
                    number={litrosPendentes}
                    progress={"100%"}
                    icon={"fa fa-dollar-sign fa-fw"}
                    permission="cards-home[2]"
                  />

                  <Card
                    text={<FormattedMessage id="cotacoesRespondidas" />}
                    number={cotacoesRespondidas}
                    progress={"100%"}
                    icon={"fa fa-globe fa-fw"}
                    href={"/orders/painel"}
                    permission="cards-home[2]"
                  />

                  <Card
                    text={<FormattedMessage id="pedidosVendidos" />}
                    number={pedidosVendidos}
                    progress={"100%"}
                    icon={"fa fa-chart-line fa-fw"}
                    permission="cards-home[2]"
                  />

                  <Card
                    text={<FormattedMessage id="litrosVendidos" />}
                    number={M3LitrosVendidos}
                    progress={"100%"}
                    icon={"fa fa-dollar-sign fa-fw"}
                    permission="cards-home[2]"
                  />

                  <Card
                    text={"R$ " + vendidosPlaceholder}
                    number={reaisVendidos}
                    progress={"100%"}
                    icon={"fa fa-dollar-sign fa-fw"}
                    permission="cards-home[2]"
                  />
                </div>

                <div className="grid grid-template-columns-important">
                  <img  src={Alert} alt="" />

                  <h3 className="text">
                    <FormattedMessage id="messageHomeDistribuidor" />
                  </h3>
                </div>

                <div className="graph-div">
                  <GraphVolumeVendido />
                </div>

                <div className="grid grid-template-columns-2">
                  <ImageBanner
                    src={srcLeftTop}
                    height={300}
                    width={"100%"}
                    display={bannerLeftTop ? 0 : 1}
                  />
                  <ImageBanner
                    src={srcLeftBottom}
                    height={300}
                    width={"100%"}
                    display={bannerLeftBottom ? 0 : 1}
                  />
                </div>

                <div className="grid grid-template-columns-table">
                  {dados ? (
                    <main>
                      <Table data={data} columns={columns} />
                    </main>
                    ) : (
                      <main style={{overflowX: 'hidden', marginBottom: '10px'}}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FiFileText size={"5%"} color="#221c46" />
                          <h3
                            style={{
                              color: "#221c46",
                              marginLeft: '10px'
                            }}
                          >
                            <FormattedMessage id="naoExisteMelhorPreco" />
                          </h3>
                        </div>
                      </main>
                    )}
                </div>
              </ContainerInfo>

              <div className="hidden">
                <ImageBanner
                  src={srcLeftTop}
                  height={300}
                  width={"100%"}
                  display={bannerLeftTop ? 0 : 1}
                  link={linkLeftTop !== null ? linkLeftTop : null}
                />
                <ImageBanner
                  src={srcLeftBottom}
                  height={300}
                  width={"100%"}
                  display={bannerLeftBottom ? 0 : 1}
                  link={linkLeftBottom ? linkLeftBottom : null}
                />
              </div>
            </ContainerPage>
          </>
        )}
        <ImageBanner
          src={srcBottom}
          height={"100%"}
          width={"100%"}
          display={bannerBottom ? 0 : 1}
          link={linkBottom ? linkBottom : null}
        />
      </Container>
    </>
  );
};

export default injectIntl(ListHome);

import React, { createRef, useCallback, useContext } from "react";

import { Container, MessageError } from "./styles";
import Language from "./../../context/language";

const CheckboxDefault = ({ labelName, type, name, geterror, ...rest }) => {
  const ref = createRef();

  const handleFocus = useCallback(() => {
    ref.current.click();
  }, [ref]);

  const { language } = useContext(Language);

  return (
    <Container>
      <input type={type} name={name} ref={ref} {...rest} />
      <label onClick={handleFocus}>{labelName}</label>
      {geterror && (
          <MessageError>
            <div>
              {Array.isArray(geterror) ? (
                geterror.map((err) => {
                  return <p key={err}>{err[language]}</p>;
                })
              ) : (
                <p>{geterror}</p>
              )}
            </div>
          </MessageError>
        )}
    </Container>
  );
};

export default CheckboxDefault;

import React, { useState, useMemo, useContext, useEffect } from "react";
import InputMaskDefault from "./../../../../../components/InputMaskDefault";
import ButtonIcon from "./../../../../../components/ButtonIcon";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

import { FormattedMessage, injectIntl } from "react-intl";

import { HeaderInfo } from "./styles";
import AuthContext from "./../../../../../context/auth";

const CardProducts = ({
  children,
  data,
  onChange,
  sizeArray,
  statusPedido,
  statusCotacao,
  prazoPedido,
  index,
  selectCotacao,
  intl,
}) => {
  const [exibir, setExibir] = useState(() => (index === 0 ? true : false));

  const { user } = useContext(AuthContext);

  const [taxa, setTaxa] = useState(() => {});

  useEffect(() => {
    const formatted = String(data.produtos[0].taxa_financeira)?.replace(
      ".",
      ","
    );

    setTaxa(formatted);
  }, [data.produtos]);

  const random = useMemo(() => {
    return Math.random() * (300 - 0) + 0;
  }, []);

  const taxaPlaceholder = intl.formatMessage({ id: "taxaFinanceira" });

  return (
    <>
      <header
        style={{
          backgroundColor:
            (prazoPedido === data.prazo &&
              statusPedido === "Aprovado" &&
              statusCotacao === "Aprovado") ||
            (prazoPedido === data.prazo &&
              statusPedido === "Faturado" &&
              statusCotacao === "Faturado") ||
            (prazoPedido === data.prazo &&
              statusPedido === "Aguardando Faturamento" &&
              statusCotacao === "Aprovado")
              ? "#01AD43"
              : "#FF7375",
              color:
            (prazoPedido === data.prazo &&
              statusPedido === "Aprovado" &&
              statusCotacao === "Aprovado") ||
            (prazoPedido === data.prazo &&
              statusPedido === "Faturado" &&
              statusCotacao === "Faturado") ||
            (prazoPedido === data.prazo &&
              statusPedido === "Aguardando Faturamento" &&
              statusCotacao === "Aprovado")
              ? "#fff"
              : "#221c46",
        }}
      >
        <div>
          <div>
            {user.cadastro_tipo_id === 3 &&
              statusPedido === "Aguardando Aprovação" && (
                <>
                  <input
                    type="radio"
                    id={`order${random}`}
                    name={`order`}
                    onClick={selectCotacao}
                    defaultChecked={sizeArray > 1 ? false : true}
                  />
                  <label htmlFor={`order${random}`}>
                    <FormattedMessage id="selecionarCotacao" />
                  </label>
                </>
              )}
          </div>
          {user.cadastro_tipo_id !== 2 && (
            <h1 style={{ whiteSpace: "nowrap" }}>
              <FormattedMessage id="prazo" /> {`${data.produtos[0].prazo || 0}`}{" "}
              <FormattedMessage id="dias" />
            </h1>
          )}
        </div>
        <HeaderInfo>
          {user.cadastro_tipo_id !== 2 ? (
            <>
              {user.cadastro_tipo_id === 1 && (
                <div>
                <p style={{marginBottom: 5}}>{taxaPlaceholder+ " %"}</p>
                <InputMaskDefault
                  mask={"percent"}
                  type="text"
                  name="preco_final"
                  //onChange={onChange}
                  defaultValue={taxa}
                  readOnly={true}
                />
                </div>
              )}
              {user.cadastro_tipo_id === 3 && <div></div>}
              {statusPedido === "Cotação" && user.cadastro_tipo_id === 3 ? (
                <div></div>
              ) : (
                <div>
                  <span>
                    {user.cadastro_tipo_id === 3 ? (
                      "Valor Total Pedido"
                    ) : (
                      <FormattedMessage id="valorTotalPedidoTaxas" />
                    )}
                  </span>
                  <h2>
                    {data.produtos[0].total_cotacao_com_taxa
                      ? data.produtos[0].total_cotacao_com_taxa.toLocaleString(
                          "pt-br",
                          {
                            style: "currency",
                            currency: "BRL",
                          }
                        )
                      : "R$ 0,00"}
                  </h2>
                </div>
              )}
            </>
          ) : (
            <>
              <div></div>
              <div>
                <span>Valor Total Pedido</span>
                <h2>
                  {data.produtos[0].total_cotacao_sem_taxa
                    ? data.produtos[0].total_cotacao_sem_taxa.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )
                    : "R$ 0,00"}
                </h2>
              </div>
            </>
          )}
          <div style={{ marginLeft: "auto" }}>
            <ButtonIcon
              Icon={exibir ? FiChevronUp : FiChevronDown}
              // placeholder={"Expandir"}
              backgroundColor={"transparent"}
              color={((prazoPedido === data.prazo &&
                statusPedido === "Aprovado" &&
                statusCotacao === "Aprovado") ||
                (prazoPedido === data.prazo &&
                  statusPedido === "Faturado" &&
                  statusCotacao === "Faturado") ||
                (prazoPedido === data.prazo &&
                  statusPedido === "Aguardando Faturamento" &&
                  statusCotacao === "Aprovado")) ? "#fff" : "#221c46"}
              borderColor={"transparent"}
              onClick={() => setExibir(!exibir)}
              style={{ height: "40px" }}
            />
          </div>
        </HeaderInfo>
        {((prazoPedido === data.prazo &&
          statusPedido === "Aprovado" &&
          statusCotacao === "Aprovado") ||
          (prazoPedido === data.prazo &&
            statusPedido === "Faturado" &&
            statusCotacao === "Faturado") ||
          (prazoPedido === data.prazo &&
            statusPedido === "Aguardando Faturamento" &&
            statusCotacao === "Aprovado")) && <h3>Prazo Aprovado</h3>}
      </header>

      {exibir && children}
    </>
  );
};

export default injectIntl(CardProducts);

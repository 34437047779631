import React, { useCallback, useState, useEffect, createRef } from "react";
import { useTable } from "react-table";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  FiEdit,
  FiEye,
  FiTrash2,
  FiPlus,
  FiXCircle,
} from "react-icons/fi";
import { FormattedMessage, injectIntl } from "react-intl";
import iconFiltro from "./../../../../assets/images/icone-filtro.png";
import ContentSpinner from "./../../../../components/Spinner/Content";
import ButtonIcon from "./../../../../components/ButtonIcon";
import iconVoltar from "./../../../../assets/images/icone-voltar.svg";

import api from "./../../../../services/api";
import swal from "sweetalert";
import Alert from "./../../../../assets/images/alert.png";

import InputLabel from "./../../../../components/InputLabel";
import Button from "./../../../../components/ButtonDefault";
import MaskInput from "./../../../../config/input-mask";

import ListSelect from "./../../../../components/ListSelect";

import { Container, FilterContainer } from "./styles";
import OptionsList from "./../../../../components/OptionsList";
import OptionsIconPlaceholder from "./../../../../components/OptionsList/OptionsIconPlaceholder";

import ButtonImage from "./../../../../components/ButtonImage";
import ExcelLogo from "./../../../../assets/images/logo-excel.png";

import Paginate from "./../../../../components/Paginate";

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const CreateCustomerClassification = ({ intl }) => {
  const [customerClassification, setCustomerClassification] = useState([]);
  const [codigoFilter, setCodigoFilter] = useState("");
  const [descricaoFilter, setDescricaoFilter] = useState("");
  const [codigoExternoFilter, setCodigoExternoFilter] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  // Paginação
  const location = useLocation();
  const [total, setTotal] = useState("");
  const [firstPage, setFirstPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [lastPageUrl, setLastPageUrl] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [currentPage, setCurrentPage] = useState(() => {
    return new URLSearchParams(location.search).get("page") || 1;
  });
  const [countPage, setCountPage] = useState([]);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const refExcel = createRef(null);

  const history = useHistory();

  const desejaExcluirRegistro = intl.formatMessage({
    id: "desejaExcluirRegistro",
  });
  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  function formatExcelData(data) {
    const dataFormat = data.map((item) => {
      return [
        {
          value: item?.codigo || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.descricao || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.codigo_externo || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
        {
          value: item?.status_formatted || "",
          style: {
            alignment: {
              wrapText: false,
              horizontal: "center",
            },
          },
        },
      ];
    });

    return [
      {
        columns: [
          {
            title: "Código",
            width: { wpx: 90 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Descrição",
            width: { wpx: 220 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Código Externo",
            width: { wpx: 100 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
          {
            title: "Status",
            width: { wpx: 70 },
            style: {
              alignment: {
                wrapText: false,
                horizontal: "center",
              },
              font: { bold: true },
            },
          },
        ],
        data: dataFormat,
      },
    ];
  }

  useEffect(() => {
    (async () => {
      try {
        const { data: response } = await api.get(
          "/tabela-classificacao-cliente"
        );

        const formattedList = response.data.map((item) => {
          const obj = {};

          Object.assign(obj, {
            id: item.id,
            codigo: item.id,
            descricao: item.descricao,
            codigo_externo: item.codigo_externo,
            status: item.ativo,
            status_formatted: item.ativo === 1 ? "Ativo" : "Inativo",
          });

          return obj;
        });

        setTotal(response?.total);
        setFirstPage(response?.first_page_url);
        setLastPage(response?.last_page);
        setLastPageUrl(response?.last_page_url);
        setNextPage(response?.next_page_url);
        setPrevPage(response?.prev_page_url);
        setCurrentPage(response?.current_page);
        setCountPage(() => {
          const arr = [];

          const current_Page = response?.current_page;
          let initialPageNumber = 0;
          let limitPageNumber = 0;

          let lastPage = response?.last_page;

          if (current_Page > 2) {
            initialPageNumber = current_Page - 3;
          } else if (current_Page <= 2) {
            initialPageNumber = current_Page - current_Page;
          }

          // define qual é a pagina final pelos numeros
          if (current_Page > 2) {
            limitPageNumber = current_Page + 2;
          } else if (current_Page <= 2) {
            limitPageNumber = current_Page - current_Page + 5;
          }

          // define qual é a pagina final pelos numeros
          if (limitPageNumber > lastPage) {
            initialPageNumber = lastPage - 5;
            limitPageNumber = lastPage;
          }

          // Cria o Array com a paginação numérica
          for (
            let index = initialPageNumber;
            index < limitPageNumber;
            index++
          ) {
            arr.push(index + 1);
          }

          return arr.filter((page) => page > 0);
        });

        setCustomerClassification(formattedList);
        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
        return () => <h3>Erro ao carregar a lista</h3>;
      }
    })();
  }, []);

  const handleCreate = useCallback(() => {
    history.push("/register/sodexo/tables/customer-classification/create");
  }, [history]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.click();
  }, [refExcel]);

  const handleDisplay = useCallback(
    async (data) => {
      history.push(
        "/register/sodexo/tables/customer-classification/show",
        data.original
      );
    },
    [history]
  );

  const handleEdit = useCallback(
    (data) => {
      history.push(
        "/register/sodexo/tables/customer-classification/edit",
        data.original
      );
    },
    [history]
  );

  const handleDelete = useCallback(
    async (id) => {
      const willDelete = await swal({
        title: voceTemCerteza,
        text: desejaExcluirRegistro,
        icon: "warning",
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {
          await api.delete(`/tabela-classificacao-cliente/${id}`);
          setCustomerClassification(
            customerClassification.filter((element) => element.id !== id)
          );
          toast.success(<FormattedMessage id="registroExcluidoComSucesso" />);
        } catch (err) {
          toast.error(<FormattedMessage id="erroExcluirRegistro" />);
        }
      }
    },
    [
      customerClassification,
      voceTemCerteza,
      desejaExcluirRegistro,
      opcaoSim,
      cancelar,
    ]
  );

  const handleFilter = useCallback(async () => {
    setLoadingButton(true);
    const checkedStatus = isNaN(status?.value) ? "" : String(status?.value);

    try {
      const { data: response } = await api.post(
        "/tabela-classificacao-cliente/filter",
        {
          id: codigoFilter,
          descricao: descricaoFilter,
          codigo_externo: codigoExternoFilter,
          ativo: checkedStatus,
        }
      );

      const formattedList = response.data.map((item) => {
        const obj = {};

        Object.assign(obj, {
          id: item.id,
          codigo: item.id,
          descricao: item.descricao,
          codigo_externo: item.codigo_externo,
          status: item.ativo,
          status_formatted: item.ativo === 1 ? "Ativo" : "Inativo",
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setCustomerClassification(formattedList);

      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    } catch (error) {
      setCustomerClassification(error.response?.data?.data);
      setLoadingButton(false);
      setShowFilter(false);
    }
  }, [status, codigoFilter, descricaoFilter, codigoExternoFilter]);

  const columns = React.useMemo(
    () => [
      {
        Header: <FormattedMessage id="codigo" />,
        accessor: "codigo", // accessor is the "key" in the data
      },
      {
        Header: <FormattedMessage id="descricao" />,
        accessor: "descricao",
      },
      {
        Header: <FormattedMessage id="codigoExterno" />,
        accessor: "codigo_externo",
      },
      {
        Header: "Status",
        accessor: "status_formatted",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data: customerClassification });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  // Paginação
  const handleUpdateList = useCallback(async (url) => {
    try {
      const { data: response } = await api.get(url);

      const formattedList = response.data.map((item) => {
        const obj = {};

        Object.assign(obj, {
          id: item.id,
          codigo: item.id,
          descricao: item.descricao,
          codigo_externo: item.codigo_externo,
          status: item.ativo,
          status_formatted: item.ativo === 1 ? "Ativo" : "Inativo",
        });

        return obj;
      });

      setTotal(response?.total);
      setFirstPage(response?.first_page_url);
      setLastPage(response?.last_page);
      setLastPageUrl(response?.last_page_url);
      setNextPage(response?.next_page_url);
      setPrevPage(response?.prev_page_url);
      setCurrentPage(response?.current_page);
      setCountPage(() => {
        const arr = [];

        const current_Page = response?.current_page;
        let initialPageNumber = 0;
        let limitPageNumber = 0;

        let lastPage = response?.last_page;

        if (current_Page > 2) {
          initialPageNumber = current_Page - 3;
        } else if (current_Page <= 2) {
          initialPageNumber = current_Page - current_Page;
        }

        // define qual é a pagina final pelos numeros
        if (current_Page > 2) {
          limitPageNumber = current_Page + 2;
        } else if (current_Page <= 2) {
          limitPageNumber = current_Page - current_Page + 5;
        }

        // define qual é a pagina final pelos numeros
        if (limitPageNumber > lastPage) {
          initialPageNumber = lastPage - 5;
          limitPageNumber = lastPage;
        }

        // Cria o Array com a paginação numérica
        for (let index = initialPageNumber; index < limitPageNumber; index++) {
          arr.push(index + 1);
        }

        return arr.filter((page) => page > 0);
      });

      setCustomerClassification(formattedList);
      setLoadingList(false);
    } catch (error) {
      setLoadingList(false);
      console.log(error);
    }
  }, []);

  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>{<FormattedMessage id="classificacaoClientes" />}</h2>
          </nav>

          <div>
            <ButtonIcon
              Icon={FiPlus}
              backgroundColor={"#221c46"}
              onClick={handleCreate}
              placeholder={<FormattedMessage id="incluir" />}
              permission="tabelaclassificacaocliente-store[1]"
            />

                          <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#221c46"}
                color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
            />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>
        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              <ExcelFile
                element={
                  <button style={{ display: "none" }} ref={refExcel}></button>
                }
                filename="Classificação de Clientes"
              >
                <ExcelSheet
                  dataSet={formatExcelData(customerClassification)}
                  name="Classificação de Clientes"
                />
              </ExcelFile>
              <table {...getTableProps()}>
                <thead>
                  {
                    // Loop over the header rows
                    headerGroups.map((headerGroup) => (
                      // Apply the header row props
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {
                          // Loop over the headers in each row
                          headerGroup.headers.map((column) => (
                            // Apply the header cell props
                            <th {...column.getHeaderProps()}>
                              {
                                // Render the header
                                column.render("Header")
                              }
                            </th>
                          ))
                        }
                        <th>
                          <FormattedMessage id="acoes" />
                        </th>
                      </tr>
                    ))
                  }
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                  {
                    // Loop over the table rows
                    rows.map((row) => {
                      // Prepare the row for display

                      prepareRow(row);
                      return (
                        // Apply the row props
                        <tr {...row.getRowProps()}>
                          {
                            // Loop over the rows cells
                            row.cells.map((cell) => {
                              // Apply the cell props
                              return (
                                <td {...cell.getCellProps()}>
                                  {
                                    // Render the cell contents
                                    cell.render("Cell")
                                  }
                                </td>
                              );
                            })
                          }
                          <td>
                            <OptionsList>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEye}
                                  placeholder={<FormattedMessage id="exibir" />}
                                  onClick={() => handleDisplay(row)}
                                  permission="tabelaclassificacaocliente-show[1]"
                                />
                              </li>

                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiEdit}
                                  placeholder={<FormattedMessage id="editar" />}
                                  onClick={() => handleEdit(row)}
                                  permission="tabelaclassificacaocliente-update[1]"
                                />
                              </li>
                              <li>
                                <OptionsIconPlaceholder
                                  Icon={FiTrash2}
                                  placeholder={
                                    <FormattedMessage id="excluir" />
                                  }
                                  onClick={() => handleDelete(row.original.id)}
                                  permission="tabelaclassificacaocliente-destroy[1]"
                                />
                              </li>
                            </OptionsList>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </main>
            <Paginate
              handleUpdateListPaginate={handleUpdateList}
              countPage={countPage}
              currentPg={currentPage}
              firstPage={firstPage}
              lastPage={lastPage}
              lastPageUrl={lastPageUrl}
              nextPage={nextPage}
              prevPage={prevPage}
              total={total}
            />
          </>
        )}
      </Container>
      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            <InputLabel
              name="codigo_filter"
              type="text"
              label={<FormattedMessage id="codigo" />}
              value={codigoFilter}
              mask={MaskInput.FieldNumber5}
              onChange={(e) => {
                setCodigoFilter(e.target.value);
              }}
            />
            <InputLabel
              name="descricao"
              type="text"
              label={<FormattedMessage id="descricao" />}
              maxLength={40}
              value={descricaoFilter}
              onChange={(e) => setDescricaoFilter(e.target.value)}
            />

            <InputLabel
              name="codigo_externo"
              type="text"
              label={<FormattedMessage id="codigoExterno" />}
              maxLength={10}
              value={codigoExternoFilter}
              onChange={(e) => setCodigoExternoFilter(e.target.value)}
            />

            <ListSelect
              value={status}
              onChange={(e) => setStatus(e)}
              labelPlaceholder={"Status"}
              cacheOptions
              defaultOptions
              options={statusOptions}
              isClearable
            />
          </main>

          <footer>
            <Button
              type="button"
              themeType="default"
              onClick={handleFilter}
              loading={loadingButton}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(CreateCustomerClassification);

import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { FormattedMessage, injectIntl } from "react-intl";

import api from "./../../../services/api";

import MaskInput from "./../../../config/input-mask";

import { BsQuestion } from "react-icons/bs";

import {
  Container,
  CreateForm,
  CreateFooter,
  TabStyled,
  FormTax,
  TitleTax,
  HeaderTax,
  FormConsumer,
} from "./styles";

import InputMaskDefault from "./../../../components/InputMaskDefault";
import InputLabel from "./../../../components/InputLabel";
import TextAreaLabel from "./../../../components/TextAreaLabel";
import Button from "./../../../components/ButtonDefault";
import CheckboxDefault from "./../../../components/CheckboxDefault";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

import ButtonIcon from "./../../../components/ButtonIcon";
import LoadingContent from "./../../../components/Spinner/Content";
import { MessageError } from "../../../components/InputLabel/styles";

export const DisplayCustomer = ({ intl }) => {
  // <List defaultInputValue value defaultValue defaultOptions/>;
  const [showContent, setShowContent] = useState(true);
  // Dados Cadastrais
  const [cnpj, setCnpj] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataCadastro, setDataCadastro] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [registroANP, setRegistroANP] = useState("");
  const [grupoEconomico, setGrupoEconomico] = useState("");
  const [perfil, setPerfil] = useState(null);
  const [matriz, setMatriz] = useState(0);
  const [empresaMatriz, setEmpresaMatriz] = useState(0);
  const [classificacao, setClassificacao] = useState("");
  const [contato, setContato] = useState("");
  const [email, setEmail] = useState("");
  const [contatoDois, setContatoDois] = useState("");
  const [emailDois, setEmailDois] = useState("");
  const [telefone, setTelefone] = useState("");
  const [celular, setCelular] = useState("");
  const [celularDois, setCelularDois] = useState("");
  //const [listaMesaOperacao, setListaMesaOperacao] = useState("");
  const [mesaOperacao, setMesaOperacao] = useState("");
  const [farmer, setFarmer] = useState("");
  const [hunter, setHunter] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  // Dados de endereços
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");

  // Dados de Entrega
  const [modalidadeTransporte, setModalidadeTransporte] = useState("");
  const [tanque, setTanque] = useState("");
  const [modoAbastecimento, setModoAbastecimento] = useState("");
  const [tipoRecebimento, setTipoRecibimento] = useState("");
  const [observacoes, setObservacoes] = useState("");

  //Dados Cobrança
  const [limiteCredito, setLimiteCredito] = useState("");
  const [toleranciaMaxima, setToleranciaMaxima] = useState("");
  const [saldoCredito, setSaldoCredito] = useState("");
  const [saldoCreditoEmissor, setSaldoCreditoEmissor] = useState("");
  const [prazoPagamento, setPrazoPagamento] = useState("");
  const [taxaAdm, setTaxaAdm] = useState("");
  const [taxaFinanceira, setTaxaFinanceira] = useState([]);

  //Consumo e Capacidade
  const [produtos, setProdutos] = useState([]);

  //Certificado digital
  const [nomeCertificado, setNomeCertificado] = useState("Nome Certificado");
  const [validade, setValidade] = useState("2020/02/02");

  // Bases de distribuição
  // const [basesDist, setBasesDist] = useState([]);
  // const [optionsBases, setOptionsBases] = useState([]);
  const [preferenciaDist, setPreferenciaDist] = useState("");
  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "todos", label: <FormattedMessage id="todos" /> },
  ]);


  const estadoPlaceholder = intl.formatMessage({ id: "estado" });
  const cidadePlaceholder = intl.formatMessage({ id: "cidade" });

  //Dados Cadastrais
  const razaoSocialPlaceholder = intl.formatMessage({ id: "razaoSocial" });
  const nomeFantasiaPlaceholder = intl.formatMessage({ id: "nomeFantasia" });
  const inscricaoEstadualPlaceholder = intl.formatMessage({
    id: "inscricaoEstadual",
  });
  const registroANPPlaceholder = intl.formatMessage({ id: "registroANP" });
  const grupoEconomicoPlaceholder = intl.formatMessage({
    id: "grupoEconomico",
  });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const matrizPlaceholder = intl.formatMessage({ id: "matriz" });
  const empresaMatrizPlaceholder = intl.formatMessage({ id: "empresaMatriz" });
  const classificacaoPlaceholder = intl.formatMessage({ id: "classificacao" });
  const contatoPlaceholder = intl.formatMessage({ id: "contato" });
  const telefonePlaceholder = intl.formatMessage({ id: "telefone" });
  const celularPlaceholder = intl.formatMessage({ id: "celular" });
  const mesaOperacaoPlaceholder = intl.formatMessage({ id: "mesaOperacao" });

  // Dados endereço
  const cepPlaceholder = intl.formatMessage({ id: "cep" });
  const logradouroPlaceholder = intl.formatMessage({ id: "logradouro" });
  const numeroPlaceholder = intl.formatMessage({ id: "numero" });
  const complementoPlaceholder = intl.formatMessage({ id: "complemento" });
  const bairroPlaceholder = intl.formatMessage({ id: "bairro" });

  //Dados Entrega
  const modalidadeTransportePlaceholder = intl.formatMessage({
    id: "modalidadeTransporte",
  });
  const tanquesPropriosTransportePlaceholder = intl.formatMessage({
    id: "tanquesProprios",
  });
  const modoAbastecimentoPlaceholder = intl.formatMessage({
    id: "modoAbastecimento",
  });
  const tipoRecebimentoPlaceholder = intl.formatMessage({
    id: "tipoRecebimento",
  });
  const distribuidorPlaceholder = intl.formatMessage({
    id: "distribuidor",
  });
  const observacoesPlaceholder = intl.formatMessage({
    id: "observacoes",
  });

  // Dados Cobraça

  const limiteCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "limiteCreditoDisponivel",
  });
  const toleranciaMaximaPlaceholder = intl.formatMessage({
    id: "toleranciaMaxima",
  });
  const saldoCreditoDisponivelPlaceholder = intl.formatMessage({
    id: "saldoCreditoDisponivel",
  });
  const prazoPagamentoPlaceholder = intl.formatMessage({
    id: "prazoPagamento",
  });
  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });
  const taxaFinanceiraPlaceholder = intl.formatMessage({
    id: "taxaFinanceira",
  });
  const dePlaceholder = intl.formatMessage({ id: "de" });
  const atePlaceholder = intl.formatMessage({ id: "ate" });

  const history = useHistory();

  useEffect(() => {
    async function loadCustomer() {
      try {
        if (!history.location?.state) {
          return history.push("/register/sodexo/customers/list");
        }

        const id = history.location?.state;


        const { data: response } = await api.get(`/cadastro/${id}`);

        setPreferenciaDist(
          response.distribuidor_preferencia.length !== 0
            ? {
                value: response.distribuidor_preferencia[0]?.id,
                label:
                  response.distribuidor_preferencia[0]?.cpf_cnpj +
                  " - " +
                  response.distribuidor_preferencia[0]?.razao_social,
              }
            : ""
        );

        if (response?.base_distribuicao) {
          const formattedBases = response?.base_distribuicao.map((base) => ({
            label: base.nome,
            value: base.id,
          }));

          setBaseDistribuicao(formattedBases);
        }

        setNomeCertificado(response?.certificado_digital?.nome_certificado);
      } catch (error) {}
    }
    loadCustomer();
  }, [history]);

  useEffect(() => {
    const id = history.location?.state;
    let todosProdutos = [];
    async function loadProducts() {
      try {
        const { data: response } = await api.get("/produto");

        const formattedList = response.data.map((item) => {
          const obj = {};

          Object.assign(obj, {
            produto_id: item.id,
            codigo: item.codigo,
            descricao: item.descricao,
            capacidade_tanque: "",
            produto: item,
            volume_compras_mensal: "",
          });

          return obj;
        });

        todosProdutos = formattedList;
      } catch (error) {}
    }

    loadProducts();

    async function loadInfos() {

      try {
        const { data: response } = await api.get(`/cadastro/${id}`);

        function formatDate(date) {
          var d = new Date(date),
              month = '' + (d.getMonth() + 1),
              day = '' + d.getDate(),
              year = d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [day, month, year].join('/');
      }

        setDataCadastro(formatDate(response.data_cadastro))

        // Dados Cadastrais
        setCnpj(response.cpf_cnpj);
        setRazaoSocial(response.razao_social);
        setNomeFantasia(response.fantasia);
        setInscricaoEstadual(response.inscricao_estadual);
        setRegistroANP(response.registro_amp);
        setGrupoEconomico(response.grupo_economico);
        setPerfil(
          response.perfil === "Privado"
            ? [{ value: "Privado", label: <FormattedMessage id="privado" /> }]
            : [{ value: "Público", label: <FormattedMessage id="publico" /> }]
        );
        setMatriz(
          response.matriz === 1
            ? [{ value: 1, label: <FormattedMessage id="opcaoSim" /> }]
            : [{ value: 0, label: <FormattedMessage id="opcaoNao" /> }]
        );

        setEmpresaMatriz(() => {
          if (response.empresa_matriz) {
            return {
              value: response.empresa_matriz?.id,
              label: response.cpf_cnpj + " - " + response.razao_social,
            };
          }
        });
        setClassificacao([
          {
            value: response.classificacao_cliente?.descricao,
            label: response.classificacao_cliente?.descricao,
          },
        ]);
        setTelefone(response.telefone);
        setCelular(response.celular);
        setCelularDois(response.cel_contato_2);
        setMesaOperacao([
          {
            value: response.mesa_operacao?.descricao,
            label: response.mesa_operacao?.descricao,
          },
        ]);

        setContato(response.contato);
        setEmail(response.contato_email);

        setContatoDois(response.contato_2);
        setEmailDois(response.contato_email_2);

        setFarmer({
          value: response?.farmer?.id,
          label: response?.farmer?.descricao,
        });

        setHunter({
          value: response?.hunter?.id,
          label:  response?.hunter?.descricao,
        });

        setStatus(
          response.ativo === 1
            ? {
                value: 1,
                label: <FormattedMessage id="ativo" />,
              }
            : {
                value: 0,
                label: <FormattedMessage id="inativo" />,
              }
        );

        // Dados de Endereço
        setCep(response.endereco?.cep || "");
        setLogradouro(response.endereco?.logradouro || "");
        setNumero(response.endereco?.numero || "");
        setComplemento(response.endereco?.complemento || "");
        setBairro(response.endereco?.bairro || "");

        setEstado([
          {
            value: response.endereco?.estado?.estado,
            label: response.endereco?.estado?.estado,
          },
        ]);
        setCidade([
          {
            value: response.endereco?.municipio?.nome,
            label: response.endereco?.municipio?.nome,
          },
        ]);

        // Dados de Entrega

        setModalidadeTransporte(
          response.dados_entrega?.modalidade_transporte || ""
        );
        setModalidadeTransporte(
          response.dados_entrega?.modalidade_transporte || ""
        );
        setTanque(
          response.dados_entrega?.tanques_proprios === 1 ? "Sim" : "Não"
        );
        // setModoAbastecimento(response.dados_entrega?.modo_abastecimento || "");

        if (response.dados_entrega?.modo_abastecimento === "Bomba") {
          setModoAbastecimento("Com Bomba" || "");
        } else {
          setModoAbastecimento("Sem Bomba" || "");
        }


        setTipoRecibimento(response.dados_entrega?.tipo_recebimento || "");
        setObservacoes(response.dados_entrega?.observacao || "");

        // Dados Cobrança
        setLimiteCredito(
          response.dados_cobranca?.limite_credito.replace(".", ",") || ""
        );

        setToleranciaMaxima(
          response.dados_cobranca?.tolerancia_maxima !== null
            ? response.dados_cobranca?.tolerancia_maxima.replace(".", ",") || ""
            : ""
        );

        setSaldoCredito(
          response.dados_cobranca?.saldo_credito_total !== null
            ? response.dados_cobranca?.saldo_credito_total.replace(".", ",") ||
                ""
            : ""
        );

        setSaldoCreditoEmissor(
          response.dados_cobranca?.saldo_credito !== null
            ? response.dados_cobranca?.saldo_credito.replace(".", ",") || ""
            : ""
        );

        setPrazoPagamento(response.dados_cobranca?.prazo_pagamento || "");

        setTaxaAdm(
          response.dados_cobranca?.taxa_administrativa !== null
            ? response.dados_cobranca?.taxa_administrativa.replace(".", ",") ||
                ""
            : ""
        );

        setTaxaFinanceira(
          response.taxa_financeira?.map((taxa) => {
            return {
              id: taxa.id,
              taxa_financeira_ate: taxa.taxa_financeira_ate,
              taxa_financeira_de: taxa.taxa_financeira_de,
              taxa_financeira_percentual:
                taxa.taxa_financeira_percentual.replace(".", ","),
            };
          }) || []
        );

        //Consumo e Capacidade
        setProdutos(
          response.consumo_capacidade.length !== 0
            ? response.consumo_capacidade.map((prod) => {
                return {
                  produto_id: prod.id,
                  capacidade_tanque: prod.capacidade_tanque.replace(".", ","),
                  volume_compras_mensal: prod.volume_compras_mensal.replace(
                    ".",
                    ","
                  ),
                  produto: prod.produto,
                };
              })
            : todosProdutos
        );

        // Certificado Digital
        setNomeCertificado(
          response.certificado_digital?.nome_certificado || ""
        );

        setValidade(response.certificado_digital?.validade_certificado || "");

        setShowContent(false);
      } catch (error) {
        setShowContent(false);
      }
    }
    loadInfos();
  }, [history, tanque]);

  const [tabIndex, setTabIndex] = useState(0);

  const profileOptions = [
    { value: "Privado", label: <FormattedMessage id="privado" /> },
    { value: "Público", label: <FormattedMessage id="publico" /> },
  ];

  const YesNoOptions = [
    { value: 1, label: <FormattedMessage id="opcaoSim" /> },
    { value: 0, label: <FormattedMessage id="opcaoNao" /> },
  ];

  //Função que cria registro

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  if (showContent) {
    return <LoadingContent />;
  }

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="exibirCliente" />: {cnpj.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  "$1.$2.$3/$4-$5"
                )} - {razaoSocial}
          </h4>
        </header>

        <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
          <TabList>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosCadastrais" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosEndereco" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosEntrega" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="dadosCobranca" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="consumoCapacidade" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="certificadoDigital" />
            </TabStyled>
            <TabStyled className={"react-tabs__tab"}>
              <FormattedMessage id="baseDistribuicao" />
            </TabStyled>
          </TabList>

          {/* Dados Cadastrais */}
          <TabPanel>
            <form>
              <InputLabel
                name="cnpj"
                type="text"
                label={"CNPJ"}
                mask={MaskInput.CNPJ}
                value={cnpj || ""}
                readOnly
              />

              <InputLabel
                name="razaoSocial"
                type="text"
                label={razaoSocialPlaceholder}
            required={true}
                maxLength={100}
                value={razaoSocial || ""}
                readOnly
              />

              <InputLabel
                name="nomeFantasia"
                type="text"
                label={nomeFantasiaPlaceholder}
            required={true}
                maxLength={80}
                value={nomeFantasia || ""}
                readOnly
              />

              <InputLabel
                name="dataCadastro"
                type="text"
                label={"Data Cadastro"}
                value={dataCadastro}
                readOnly
              />

              <InputLabel
                name="inscricaoEstadual"
                type="text"
                label={inscricaoEstadualPlaceholder}
                maxLength={15}
                value={inscricaoEstadual}
                onChange={(e) => setInscricaoEstadual(e.target.value)}
                readOnly
              />

              <InputLabel
                name="registroANP"
                type="text"
                label={registroANPPlaceholder}
                maxLength={15}
                value={registroANP || ""}
                readOnly
              />

              <InputLabel
                name="grupoEconomico"
                type="text"
                label={grupoEconomicoPlaceholder}
            required={true}
                maxLength={50}
                value={grupoEconomico || ""}
                readOnly
              />

              <ListSelect
                labelPlaceholder={perfilPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={perfil}
                isDisabled
              />

              <ListSelect
                labelPlaceholder={matrizPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                options={YesNoOptions}
                value={matriz}
                isDisabled
              />

              <AsyncListSelect
                labelPlaceholder={empresaMatrizPlaceholder}
                placeholder={<FormattedMessage id="selecionar" />}
                options={profileOptions}
                value={empresaMatriz}
                isDisabled
              />

              <AsyncListSelect
                labelPlaceholder={classificacaoPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={classificacao}
                cacheOptions
                defaultOptions
                isDisabled
              />

              <InputLabel
                name="contato"
                type="text"
                label={contatoPlaceholder}
            required={true}
                maxLength={100}
                value={contato || ""}
                readOnly
              />

              <InputLabel
                name="email"
                type="text"
                label={"E-mail"}
                required={true}
                maxLength={80}
                value={email || ""}
                readOnly
              />

              <InputLabel
                name="telefone"
                type="text"
                label={telefonePlaceholder}
            required={true}
                mask={MaskInput.phone}
                value={telefone || ""}
                readOnly
              />

              <InputLabel
                name="celular"
                type="text"
                label={celularPlaceholder}
                mask={MaskInput.cellphone}
                value={celular || ""}
                readOnly
              />

              <InputLabel
                  name="contato"
                  type="text"
                  label={"Segundo Contato"}
                  maxLength={100}
                  value={contatoDois || ""}
                  readOnly
                />

                <InputLabel
                  name="email"
                  type="text"
                  label={"Segundo E-mail"}
                  maxLength={80}
                  value={emailDois || ""}
                  readOnly
                />

                <InputLabel
                  name="celular"
                  type="text"
                  label={celularPlaceholder}
                  mask={MaskInput.cellphone}
                  value={celularDois || ""}
                  readOnly
                />

              <AsyncListSelect
                labelPlaceholder={mesaOperacaoPlaceholder}
            required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                cacheOptions
                defaultOptions
                value={mesaOperacao}
                isDisabled
              />

              <ListSelect
                labelPlaceholder={"Farmer"}
                required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={farmer}
                cacheOptions
                defaultOptions
                isDisabled
              />

              <ListSelect
                labelPlaceholder={"Hunter"}
                required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={hunter}
                cacheOptions
                defaultOptions
                isDisabled
              />

              <ListSelect
                labelPlaceholder={"Status"}
                required={true}
                placeholder={<FormattedMessage id="selecionar" />}
                value={status}
                cacheOptions
                defaultOptions
                isDisabled
              />
            </form>
          </TabPanel>
          {/* Dados de Endereço */}
          <TabPanel>
            <form>
              <InputLabel
                name="cep"
                type="text"
                label={cepPlaceholder}
            required={true}
                mask={MaskInput.CEP}
                value={cep}
                readOnly
              />

              <InputLabel
                name="logradouro"
                type="text"
                label={logradouroPlaceholder}
            required={true}
                maxLength={100}
                value={logradouro}
                readOnly
              />

              <InputLabel
                name="numero"
                type="text"
                label={numeroPlaceholder}
            required={true}
                value={numero}
                readOnly
              />
              <InputLabel
                name="complemento"
                type="text"
                label={complementoPlaceholder}
                maxLength={50}
                value={complemento}
                readOnly
              />

              <InputLabel
                name="bairro"
                type="text"
                label={bairroPlaceholder}
            required={true}
                maxLength={30}
                value={bairro}
                readOnly
              />

              <AsyncListSelect
                value={estado}
                labelPlaceholder={estadoPlaceholder}
            required={true}
                cacheOptions
                defaultOptions
                isDisabled
              />

              <AsyncListSelect
                cacheOptions
                defaultOptions
                value={cidade}
                labelPlaceholder={cidadePlaceholder}
            required={true}
                isDisabled
              />
            </form>
          </TabPanel>
          {/* Dados de Entrega */}
          <TabPanel>
            <form>
              <InputLabel
                name="modalidadeAbastecimento"
                type="text"
                label={modalidadeTransportePlaceholder}
            required={true}
                value={modalidadeTransporte}
                readOnly
              />
              <InputLabel
                name="tanquesProprios"
                type="text"
                label={tanquesPropriosTransportePlaceholder}
            required={true}
                value={tanque}
                readOnly
              />
              <InputLabel
                name="modoAbastecimento"
                type="text"
                label={modoAbastecimentoPlaceholder}
            required={true}
                value={modoAbastecimento}
                readOnly
              />

              <section>
                <strong style={{ marginLeft: "12px" }}>
                  {tipoRecebimentoPlaceholder}
                </strong>
                <CheckboxDefault
                  type="radio"
                  name="recebimento"
                  labelName="TRR"
                  onChange={() => setTipoRecibimento("TRR")}
                  checked={tipoRecebimento === "TRR" ? true : false}
                  disabled
                />
                <CheckboxDefault
                  type="radio"
                  name="recebimento"
                  labelName={distribuidorPlaceholder}
                  onChange={() => setTipoRecibimento("Distribuição")}
                  checked={tipoRecebimento === "Distribuição" ? true : false}
                  disabled
                />
              </section>

              <TextAreaLabel
                name="obs"
                placeholder={observacoesPlaceholder + " -  Máx. 200 caracteres"}
                maxLength={200}
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
                readOnly
              />
            </form>
          </TabPanel>
          {/* Dados de cobrança */}
          <TabPanel>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputMaskDefault
                type="text"
                name="limiteCredito"
                label={limiteCreditoDisponivelPlaceholder}
            required={true}
                mask={"currency2"}
                value={limiteCredito}
                readOnly
              />

              <InputMaskDefault
                type="text"
                name="toleranciaMaxima"
                label={toleranciaMaximaPlaceholder + " %"}
                value={toleranciaMaxima && toleranciaMaxima.replace(".", ",")}
                mask={"currency2WithNegative"}
                readOnly
              />

              <InputMaskDefault
                type="text"
                name="saldoCredito"
                mask={"currency2WithNegative"}
                label={saldoCreditoDisponivelPlaceholder + " - Trade"}
                required={true}
                toolTip={
                  <ButtonIcon
                    Icon={BsQuestion}
                    size={16}
                    backgroundColor={"#FF7375"}
                    placeholder={
                      "Limite de Crédito Disponível Trade + Tolerância Máxima de Limite - Pedidos Aprovados"
                    }
                  />
                }
                value={saldoCredito && saldoCredito.replace(".", ",")}
                readOnly
              />

              <InputMaskDefault
                type="text"
                name="saldoCredito"
                label={saldoCreditoDisponivelPlaceholder + " - Emissor"}
                required={true}
                value={
                  saldoCreditoEmissor && saldoCreditoEmissor.replace(".", ",")
                }
                mask={"currency2WithNegative"}
                readOnly
                toolTip={
                  <ButtonIcon
                    Icon={BsQuestion}
                    size={16}
                    backgroundColor={"#FF7375"}
                    placeholder={
                      "Limite de Crédito Disponível Emissor + Tolerância Máxima de Limite - Pedidos/Faturas em aberto"
                    }
                  />
                }
              />

              <InputMaskDefault
                name="prazoPagamento"
                label={prazoPagamentoPlaceholder}
            required={true}
                mask={"int3"}
                value={prazoPagamento}
                readOnly
              />

              <InputMaskDefault
                name="taxaAdm"
                type="text"
                label={taxaAdministrativaPlaceholder}
            required={true}
                mask={"currency4"}
                value={taxaAdm}
                readOnly
              />
              <HeaderTax>
                <strong>{taxaFinanceiraPlaceholder}</strong>
              </HeaderTax>
              <FormTax>
                <header>
                  <strong>
                    <TitleTax>{dePlaceholder}</TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>{atePlaceholder}</TitleTax>
                  </strong>
                  <strong>
                    <TitleTax>%</TitleTax>
                  </strong>
                </header>

                <ul>
                  {taxaFinanceira.map((taxa, index) => {
                    return (
                      <div key={index}>
                        <li>
                          <InputMaskDefault
                            mask={"int2"}
                            value={taxa.taxa_financeira_de}
                            readOnly
                          />
                        </li>
                        <li>
                          <InputMaskDefault
                            mask={"int2"}
                            value={taxa.taxa_financeira_ate}
                            readOnly
                          />
                        </li>
                        <li>
                          <InputMaskDefault
                            mask={"currency4"}
                            value={taxa.taxa_financeira_percentual}
                            readOnly
                          />
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </FormTax>
            </form>
          </TabPanel>
          {/* Capacidade e Consumo */}
          <TabPanel>
            <FormConsumer>
              <header>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="produto" />
                  </TitleTax>
                </strong>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="capacidadeTanque" />
                  </TitleTax>
                </strong>
                <strong>
                  <TitleTax>
                    <FormattedMessage id="volumeComprasMensal" />{" "}
                  </TitleTax>
                </strong>
              </header>

              <ul>
                {produtos.map((produto, index) => {
                  return (
                    <div key={index}>
                      <li>
                        <InputLabel
                          value={produto?.produto?.descricao}
                          readOnly
                        />
                      </li>
                      <li>
                        <InputMaskDefault
                          value={produto?.capacidade_tanque}
                          readOnly
                          mask="currency6"
                        />
                      </li>
                      <li>
                        <InputMaskDefault
                          value={produto?.volume_compras_mensal}
                          readOnly
                          mask="currency6"
                        />
                      </li>
                    </div>
                  );
                })}
              </ul>
            </FormConsumer>
          </TabPanel>
          {/* Certificado digital */}
          <TabPanel>
            <form>
              <InputLabel
                name="nomeCertificado"
                type="text"
                label={<FormattedMessage id="nomeCertificado" />}
                readOnly
                value={nomeCertificado}
              />
              <InputLabel
                name="validadeCertificado"
                type="text"
                label={<FormattedMessage id="validadeCertificado" />}
                value={validade}
                readOnly

                // value={nome}
                // onChange={(e) => setNome(e.target.value)}
              />
            </form>
          </TabPanel>
          {/* Bases de Distribuição */}

          <TabPanel>
            <form>
              <MessageError>
                <p style={{color:'#221c46'}}>
                  Cotações com Distribuidor de Preferência serão destacadas no
                  Painel de Controle pela cor azul{" "}
                </p>
              </MessageError>
              <br />
              <AsyncListSelect
                value={preferenciaDist}
                cacheOptions
                defaultOptions
                onChange={(e) => {}}
                labelPlaceholder={
                  <FormattedMessage id="distribuidorPreferencia" />
                }
                placeholder={"Não há Distribuidor de Preferência"}
                isDisabled={true}
                style={{ backgroundColor: "#e1e1ec5c" }}
              />

              <br />

              <AsyncListSelect
                    isMulti
                    labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                    cacheOptions
                    defaultOptions
                    value={basesDistribuicao}
                    isDisabled
                  />
            </form>
          </TabPanel>
        </Tabs>
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(DisplayCustomer);

import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;
`;

export const CreateForm = styled.div`
  width: 100%;
  margin-top: 0px;
  /* background-color: #f8f8f8; */

  border-radius: 10px;

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const CheckForm = styled.div`
  width: 70% !important;
  height: max-content !important;
  display: flex;
  justify-content: center;
  /* background-color: #f5f5f5; */
  padding: 5px;
  align-items: center;

  main {
    display: flex;
    justify-content: space-around;
    align-items: center;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-auto-columns: auto;
    gap: 7px;
    justify-content: center;
    width: 100%;

    > div {
      width: max-content;
      display: flex;
      align-items: center !important;
      margin: 0 5px;
      padding: 5px;
      /* border-radius: 5px; */
      border: solid 1px #e0e3e6;
      /* background: #f1f1f1; */
      background-color: ${(props) => props.isDisabled && "#F00"};

      input {
        margin-top: 2px;
        margin-right: 5px;
      }

      label {
        margin-top: 10px;
        margin-right: 15px;
        color: #221c46
      }
    }
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;

export const TitlePermission = styled.h3`
  padding-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  color: #221c46;
`;

export const PermissionItem = styled.li`
  height: 34px;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  margin: 5px 0;
`;

export const FormPermission = styled.div`
  padding: 0 5px;
  background: transparent;
  border-radius: 10px;
  button {
    color: #221c46;
  }
`;

import styled, { keyframes } from "styled-components";
import { Tab } from "react-tabs";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  h3 {
    font-size: 15px;
    color: #221c46;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const CreateForm = styled.div`
  width: 100%;

  border-radius: 10px;

  > header {
    width: 100%;
    height: 34px;

    background-color: #221c46;

    margin-bottom: 20px;
    /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    h4 {
      width: max-content;
      height: max-content;

      color: #f8f8f8;

      margin-top: 8px;
    }

    button {
      background-color: #e05262;
      svg {
      }
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;

    > section {
      display: flex;
      margin-bottom: 10px;
      margin-top: 10px;
      strong {
        margin-right: 15px;
      }
    }
  }
`;

export const CheckForm = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;

  div {
    width: max-content;
    height: 18px;
    display: flex;

    input {
      margin-top: 2px;
      margin-right: 5px;
    }
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;

export const TabStyled = styled(Tab)`
  background: ${(props) => props.selected && "#FF7375"};
  color: ${(props) => (props.selected ? "#221c46" : "#221c46")};
  font-weight: ${(props) => (props.selected ? 'bold' : "")};
  border: 1px solid #221c46;
  border-radius: 0;
`;

export const FormTax = styled.div`
  height: max-content;
  width: 100%;

  display: flex;
  flex-direction: column;

  margin-bottom: 20px;

  > header {
    strong {
      margin-right: 6.5%;
    }

    strong {
      :first-child {
        margin-left: 4.5%;
      }
    }
  }

  > ul {
    div {
      display: flex;
      align-items: center;
      margin: 0;

      li {
        height: 100%;
        width: 20%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
`;
export const TitleTax = styled.strong``;

export const HeaderTax = styled.header`
  display: flex;

  align-items: center;

  > strong {
    margin-right: 20px;
  }
`;

export const FormConsumer = styled.div`
  width: 100%;

  height: max-content;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  /* margin-top: 20px;
  margin-bottom: 20px; */

  > header {
    width: 100%;

    display: flex;
    justify-content: center;

    strong {
      width: 30%;
      text-align: center;
    }
  }

  > ul {
    width: 100%;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;

      li {
        height: 100%;
        width: 30%;
        padding: 0 5px;
        text-align: center;
        margin-top: 5px;
      }
    }
  }
`;


import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);

  }

  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div`
  height: 90vh;
  min-height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  svg, img {
    height: 100px;
    width: 100px;
    animation: ${rotate} 2s linear infinite;
    color: #221c46;
  }
`;

import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import moment from "moment";

import { FormattedMessage } from "react-intl";

const GraphReceita = ({ dataFilter }) => {
  const [loadingList, setLoadingList] = useState(true);

  const [graph, setGraph] = useState([]);
  const [labels, setLabels] = useState([]);

  let loadGraph = {
    series: [
      {
        name: "",
        data: graph,
      },
    ],
    options: {
      chart: {
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "98%",
          distributed: true,
        }
      },
      legend: {
        show: false,
      },
      colors: ["#530000", "#830707", "#960909", "#B40404","#DF0101", "#FF0000",
      "#FE2E2E" , "#f75050", "#f77171",  "#f58c8c" ],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString('pt-BR')
        },
        textAnchor: 'left',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined,

        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          // opacity: 0.9,

        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            // opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          maxWidth: 500,
        },
      },
      xaxis: {
        categories: labels,
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString('pt-BR')
          }
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (parseFloat(val)).toLocaleString('pt-BR') + " Litros"
          }
        }
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const data = [];
        const labels = [];

        const { data: response } = await api.get(
          `/dashboard/ranking-distribuidores?`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        response.map((item) => {
          const obj = {};

          Object.assign(obj, {
            data: data.push(item.total_qtd_litros),
            labels: labels.push(item.grupo_economico === null ? "CADASTRO SEM GRUPO" : item.grupo_economico),
          });

          return obj;
        });

        setGraph(data);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoRankingDistribuidores" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="bar"
                width="100%"
                height="400px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default GraphReceita;

import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }


`;

export const Container = styled.div`
  width: 100%;
  height: max-content;

  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  table {
      table-layout: auto;
      border-collapse: collapse;
      width: 50%;
      border: 1px solid #dddddd;
      position: relative;
      margin-left: 30px;

      thead {
        tr {
          th {
            height: 40px;
            text-align: left;
            padding: 8px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #B30000;
            color: #fff;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #dddddd;
        }

        tr {
          height: 45px;
          background-color: #fff;

          td {
            padding-left: 10px;
          }
        }
      }
    }
`;

export const CreateForm = styled.div`
   width: 100%;
  margin-top: 10px;
  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;
  }
`;

export const CheckForm = styled.div`
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;

  div {
    width: max-content;
    height: 18px;
    display: flex;

    input {
      margin-right: 5px;
      margin-left: 10px
    }

    p {
      font-size: 13px;
      color: #931a28;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }

  main {
    display: flex;
    justify-content: left;
    margin-top: 10px;
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;


import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 400px;

  position: relative;


  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    nav {
      display: flex;

      h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align: center;
      }
    }

    div {
      display: flex;
      width: 100px;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        margin: 5px;

        border-radius: 50%;
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }

      button:last-child {
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.4s;
        background-color: transparent;
        border-radius: 0;

        img {
          height: 30px;
          width: 30px;
        }
      }
    }
  }

  main {
    height: 450px;
    overflow: hidden;
    overflow-y: auto;
    table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      position: relative;

      thead {
        tr {
          th {
            height: 40px;
            text-align: left;
            padding: 8px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #2d353c;
            color: #fff;
          }
          th:last-child {
            text-align: center;
            transition: width 1.5s ease, max-width 1.5s ease;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #dddddd;
          width: max-content;
        }

        tr {
          height: 45px;
          background-color: #f4f4f4;

          td {
            padding-left: 10px;
          }
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #f8f8f8;
        }

        td:last-child {
          position: relative;
          padding: 0;
        }
      }

      th {
        top: 0;
        z-index: 5;
        background: #fff;
        border: 1px solid #dddddd;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #2d353c;

  width: 450px !important;
  height: auto;

  border-radius: 10px;
  background-color: #fff;
  z-index: 101;

  .react-datepicker__input-container {
    margin-bottom: 10px;
      input {
        background: #fff;
        color: #000;
        font-size: 14px;
        padding: 10px;
        color: #000 #363636;
        font-weight: 500;
        width: 100% !important;
        height: 43px !important;
        border: solid 1px rgb(204 204 204);
        border-radius: 5px;
      }
    }

    .react-datepicker-wrapper {

      input {
        background: #fff;
        color: #000;
        font-size: 14px;
        padding: 10px;
        color: #363636;
        font-weight: 500;
        width: 100% !important;
        height: 20px !important;
        border: solid 1px #d9e0e7;
        border-radius: 5px;
        font-size: 11px !important;
      }
    }

    .react-datepicker__day--outside-month {
      color: #cfc1c1bd !important;
    }

    .react-datepicker__day--outside-month:hover {
      color: #544a4abd !important;
      /* pointer-events: none; */
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:focus, .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--today {
      background: #f46666;
      color: #f2f4f5;
      outline: none;
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day:hover {
      background: #cfc1c1bd;
    }

    .react-datepicker__day--in-range{
      background-color: #cfc1c1bd
    }

    .react-datepicker .react-datepicker__month-container .react-datepicker__month .react-datepicker__week .react-datepicker__day.react-datepicker__day--selected {
      background-color: #cfc1c1bd
     }

     .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
        background-color: transparent;
     }

  header {
    width: 100%;
    height: 45px;
    background-color: #2d353c;
    border-radius: 10px 10px 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

export const FormPagination = styled.div`
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  button {
    width: auto;
    height: 100%;
    outline: none;
    border: none;
    font-weight: bold;
    font-family: 16px;
    margin-right: 6px;
    border-radius: 5px;
    background-color: #e05262;
    transition: all 0.4s;
    padding: 0 10px;

    :hover {
      background-color: #931a28;
      color: #f8f8f8;
    }

    :disabled {
      background-color: #e05262;
      opacity: 0.6;
      color: #000;
      cursor: default;
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
  }

  select {
    border: none;
    border-radius: 5px;
  }
`;

import React, { useCallback, useEffect, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../../components/ListSelect";
import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";
// import { toast } from "react-toastify";


import api from "./../../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  ActionContainer,
  FormTax,
  TitleTax,
  StyleButton,
  InfoOrder,
  HeaderOrder,
  InfoMotoristaContainer,
} from "./styles";

import MaskInput from "./../../../../config/input-mask";
import Button from "./../../../../components/ButtonDefault";
import ButtonIcon from "./../../../../components/ButtonIcon";
import TextArea from "./../../../../components/TextAreaLabel";
import ContentSpinner from "./../../../../components/Spinner/Content";
import CardProduct from "./CardProducts";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

import AuthContext from "./../../../../context/auth";

import { FiXCircle } from "react-icons/fi";

import { FaAngleDoubleRight } from "react-icons/fa";

//import Language from "./../../../context/language";

export const EditPainelOrders = ({ intl }) => {
  const [cotacao, setCotacao] = useState("");
  const [saldo, setSaldo] = useState("");
  const [limiteCredito, setLimiteCredito] = useState("");
  const [tolerancia, setTolerancia] = useState("");
  const [prazoPedido, setPrazoPedido] = useState("");

  const [statusPedido, setStatusPedido] = useState("");

  const [cliente, setCliente] = useState("");
  const [baseDist, setBaseDist] = useState("");
  const [numeroCotacao, setNumeroCotacao] = useState("");
  const [date, setDate] = useState(new Date());
  const [frete, setFrete] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [abastecimento, setAbastecimento] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [horario, setHorario] = useState("");
  const [numeroPedido, setNumeroPedido] = useState("");
  const [numeroPedidoCliente, setNumeroPedidoCliente] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [UF, setUF] = useState("");
  const [cidade, setCidade] = useState("");
  const [cep, setCep] = useState("");
  const [distancia, setDistancia] = useState("");
  const [distribuidorPreferencia, setDistribuidorPreferencia] = useState("");

  const [taxaAdministrativa, setTaxaAdministrativa] = useState(0);

  const [listaProdutos, setListaProdutos] = useState([]);
  const [listaMenorPreco, setListaMenorPreco] = useState([]);

  const [showScreen, setShowScreen] = useState(false);

  const [showEndereco, setShowEndereco] = useState(false);
  const [showInfoMotorista, setShowInfoMotorista] = useState(false);

  const [nomeMotorista, setMotorista] = useState("");
  const [cpfMotorista, setCpfMotorista] = useState("");
  const [RgMotorista, setRgMotorista] = useState("");
  const [placaVeiculo, setPlacaVeiculo] = useState("");
  const [celular, setCelular] = useState("");

  const [hasProgramacao, setHasProgramacao] = useState([]);

  const [motivo, setMotivo] = useState("");
  const [motivoLiberacao, setMotivoLiberacao] = useState("");

  const [justificativa, setJustificativa] = useState("");
  const [motivoCotacao, setMotivoCotacao] = useState("");
  const [justificativaCotacao, setJustificativaCotacao] = useState("");
  const [precoJustificado, setPrecoJustificado] = useState("");

  // const [loadingEmail, setLoadingEmail] = useState(false);

  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleBackButton = useCallback(() => {
    const painelControle = {
      painelControle: "painelControle"
    };

    history.push("/orders/painel", painelControle);
  },
  [history]
);

  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });

  const quantidadePlaceholder = intl.formatMessage({
    id: "quantidade",
  });

  useEffect(() => {
    (async () => {
      try {
        if (!history.location?.state) {
          return history.push("/orders/painel");
        }
        var data;

        if (history.location?.state?.isProgramming) {
          if (history.location?.state?.isHistory) {
            data = await api.get(
              `/cadastro/${history.location?.state?.cliente}`
            );
          } else {
            data = await api.get(
              `/cadastro/${history.location?.state?.pedido?.cadastro?.id}`
            );
          }

          var { data: response } = await api.post(`/pedido/filter`, {
            id: history.location?.state?.pedido?.id,
          });
        } else {
          // eslint-disable-next-line no-redeclare

          if (history.location.state.original.cadastro?.id) {
            data = await api.get(
              `/cadastro/${history.location.state.original.cadastro?.id}`
            );
          } else {
            data = await api.get(
              `/cadastro/${history.location.state.original.cliente?.id}`
            );
          }

          // eslint-disable-next-line no-redeclare
          var { data: response } = await api.post(`/pedido/filter`, {
            id: history.location.state?.id,
          });
        }

        var { data: responseCadastro } = data;

        const taxFinanceira = responseCadastro.taxa_financeira;

        var cotacao = [];

        setHasProgramacao(response.dados.data[0]?.programacao);

        setListaMenorPreco(history.location?.state?.cotacaoIdProduto);

        // Seleciona Cotação para exibir
        if (parseInt(user.cadastro_tipo_id) !== 3) {
          cotacao = response.dados.data[0].cotacoes.filter((cot) => {
            let idCotacao;
            if (
              history.location?.state?.isProgramming ||
              history.location?.state?.isHistory
            ) {
              return (cotacao = response.dados.data[0].cotacoes.slice(0, 1));
            } else {
              idCotacao = history.location.state?.id_cotacao;
            }
            return cot.id === idCotacao;
          });
        } else {
          cotacao = response.dados.data[0].cotacoes.filter((cot) => {
            return (
              cot.status_cotacao === "Análise Cliente" ||
              cot.status_cotacao === "Aprovado" ||
              cot.status_cotacao === "Entregue" ||
              cot.status_cotacao === "Faturado"
            );
          });

          if (cotacao.length === 0) {
            cotacao = response.dados.data[0].cotacoes.slice(0, 1);
          }
        }

        setMotorista(response.dados?.data[0]?.motorista_nome);
        setRgMotorista(response.dados?.data[0]?.motorista_rg);
        setCpfMotorista(response.dados?.data[0]?.motorista_cpf);
        setPlacaVeiculo(response.dados?.data[0]?.motorista_placa);
        setCelular(response.dados?.data[0]?.motorista_tel);

        cotacao = cotacao[0];

        setMotivoLiberacao(cotacao?.motivo_liberacao)

        setDistancia(cotacao?.distance);

        setBaseDist({
          value: cotacao.base_distribuicao?.id,
          label: cotacao.base_distribuicao?.nome,
        });

        setDistribuidorPreferencia({
          label: cotacao?.distribuidor?.razao_social || "",
          value: cotacao?.distribuidor?.id || "",
        });

        if (cotacao.motivo_cancelamento !== null) {
          setMotivoCotacao(cotacao?.motivo_cancelamento.motivo);
          setJustificativaCotacao(cotacao?.motivo_cancelamento.justificacao);

          if (parseFloat(cotacao?.motivo_cancelamento.preco) > 0) {
            setPrecoJustificado(
              parseFloat(cotacao?.motivo_cancelamento.preco).toLocaleString(
                "pt-br",
                {
                  minimumFractionDigits: 2,
                }
              )
            );
          }
        }

        if (
          cotacao?.status_cotacao === "Análise Distribuidor/TRR" ||
          cotacao?.status_cotacao === "Reanálise Distribuidor/TRR" ||
          cotacao?.status_cotacao === "Análise Mesa de Operação"
        ) {
          // Atualiza os valores das taxas, usa as taxas que foram salvas devido o status do pedido.
          const taxAdm = responseCadastro.dados_cobranca.taxa_administrativa;

          setTaxaAdministrativa(
            responseCadastro.dados_cobranca.taxa_administrativa.replaceAll(
              ".",
              ","
            )
          );

          const disponibilidadeProdutos = cotacao.produtos_cotacao.map(
            (item) => {
              if (cotacao?.status_cotacao === "Análise Mesa de Operação" && user?.cadastro_tipo_id === 1){
                if (listaMenorPreco.find( id => id === item?.id )) {
                  return {
                    disponibilidade_distribuidor: "s",
                    produto_id: item.produto_id,
                  };
                } else {
                  return {
                    disponibilidade_distribuidor: "n",
                    produto_id: item.produto_id,
                  };
                }
              } else {
                return {
                  disponibilidade_distribuidor: item.disponibilidade_distribuidor,
                  produto_id: item.produto_id,
                };
              }
            }
          );

          const formattedList = cotacao.prazos.map((prazo) => {
            let precoLitroFinal = 0;
            let totalLinhaSemTaxas = 0;
            let totalLinhaProduto = 0;
            let totalProdutoSemTaxa = 0;
            let totalProdutoComTaxa = 0;
            let idPrazo = prazo.id;
            let prazoCotacao = prazo.prazo;
            let totalLinhaFrete = 0;
            let totalLinhaTaxaFinanceira = 0;
            let totalLitros = 0;
            let taxaFinan = "";
            let totalLinhaProdutoSemTaxa = 0;
            let precoLitroSemTaxas = 0;

            const arr = prazo.produtos.map((prod) => {
              const obj = {};
              let filterTaxa = [];

              //Total Litros/Quantidade da Cotação
              totalLitros = prazo.produtos.reduce((total, b) => {
                const totalM3 = parseFloat(b.quantidade);
                return total + totalM3;
              }, 0);

              const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                (cot_prod) => {
                  return cot_prod.produto_id === prod.produto_id;
                }
              );

              if (!prod.taxa_financeira_percentual) {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prazo.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });

                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
              } else {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prod.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prod.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });
                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
              }

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(
                    parseFloat(prod?.valor_unitario) +
                      parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ) *
                    (parseFloat(taxaFinan) / 100 + 1) +
                  parseFloat(taxAdm)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              } else {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(prod?.valor_unitario) +
                  parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              }

              // Valor Total sem taxas produto linha
              totalLinhaSemTaxas = (
                parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              // Preço Final sem Taxas (Preço + Frete)
              precoLitroSemTaxas = (
                parseFloat(prod?.valor_unitario || 0) +
                parseFloat(prod?.valor_frete || 0)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 4,
              });

              // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
              totalLinhaProdutoSemTaxa = (
                (parseFloat(prod?.valor_unitario || 0) +
                  parseFloat(prod?.valor_frete || 0)) *
                parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Total produto linha
                totalLinhaProduto = (
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100 + 1)
                    ).toFixed(4)
                  ) *
                    parseFloat(prod?.quantidade) +
                  parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                );

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              } else {
                // Valor Total produto linha
                totalLinhaProduto = (
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0)) *
                  parseFloat(prod?.quantidade)
                ).toFixed(2);

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              }

              //Soma total Sem taxas Com Frete
              totalProdutoSemTaxa =
                totalProdutoSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0));

              //Soma total Com taxas + Frete
              // Calcula o total do pedido inteiro com todas as taxas.
              // Valor total da cotação com todas as taxas
              totalProdutoComTaxa =
                parseFloat(totalProdutoComTaxa) +
                parseFloat(
                  totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                );

              totalLinhaFrete =
                parseFloat(prod?.valor_frete || 0) *
                parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira =
                parseFloat(
                  (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete)) *
                    (parseFloat(taxaFinan) / 100)
                  ).toFixed(4)
                ) * parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira = parseFloat(
                totalLinhaTaxaFinanceira.toFixed(2)
              );

              if (parseInt(user.cadastro_tipo_id) !== 3){
                Object.assign(obj, {
                  id: prod?.id,
                  id_produto: prod?.produto_id,
                  id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                  disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                    (i) => i.produto_id === prod?.produto_id
                  )[0].disponibilidade_distribuidor,
                  descricao: prod.produto.descricao,
                  prazo: prazo.prazo,
                  quantidade: prod.quantidade || 0,
                  qtdInput:
                    (parseInt(prod.quantidade) / 1000)
                      .toFixed(2)
                      ?.replaceAll(".", ",") || 0,
                  valor_referencia: prod?.valor_referencia
                    ? parseFloat(prod?.valor_referencia)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_unitario: prod?.valor_unitario
                    ? parseFloat(prod?.valor_unitario)
                        .toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  valor_frete: prod?.valor_frete
                    ? parseFloat(prod?.valor_frete)
                        ?.toFixed(4)
                        ?.replaceAll(".", ",")
                    : (0).toFixed(4).replaceAll(".", ","),
                  total_frete: totalLinhaFrete,
                  total_taxa_financeira: totalLinhaTaxaFinanceira,
                  preco_litro_final: precoLitroFinal,
                  total: totalLinhaProduto || 0,
                  total_sem_taxas: totalLinhaSemTaxas,
                  taxa_financeira: taxaFinan || 0,
                  total_servico:
                    (
                      parseFloat(
                        totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                      ) -
                      parseFloat(
                        totalLinhaProdutoSemTaxa
                          .replaceAll(".", "")
                          .replaceAll(",", ".")
                      )
                    ).toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    }) || 0,

                  preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
                  valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
                });
              } else {
                if (cotacao?.status_cotacao === "Análise Distribuidor/TRR" ||
                cotacao?.status_cotacao === "Reanálise Distribuidor/TRR" ||
                cotacao?.status_cotacao === "Análise Mesa de Operação"){
                  Object.assign(obj, {
                    id: prod?.id,
                    id_produto: prod?.produto_id,
                    id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                    disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                      (i) => i.produto_id === prod?.produto_id
                    )[0].disponibilidade_distribuidor,
                    descricao: prod.produto.descricao,
                    prazo: prazo.prazo,
                    quantidade: prod.quantidade || 0,
                    qtdInput:
                      (parseInt(prod.quantidade) / 1000)
                        .toFixed(2)
                        ?.replaceAll(".", ",") || 0,
                    valor_referencia: (0).toFixed(4).replaceAll(".", ","),
                    valor_unitario: (0).toFixed(4).replaceAll(".", ","),
                    valor_frete: (0).toFixed(4).replaceAll(".", ","),
                    total_frete: 0,
                    total_taxa_financeira: 0,
                    preco_litro_final: 0,
                    total:  0,
                    total_sem_taxas: 0,
                    taxa_financeira: 0,
                    total_servico: 0,

                    preco_total_produto_sem_taxa: 0,
                    valor_total_produto_sem_taxa: 0,
                  });
                } else {
                  Object.assign(obj, {
                    id: prod?.id,
                    id_produto: prod?.produto_id,
                    id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                    disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                      (i) => i.produto_id === prod?.produto_id
                    )[0].disponibilidade_distribuidor,
                    descricao: prod.produto.descricao,
                    prazo: prazo.prazo,
                    quantidade: prod.quantidade || 0,
                    qtdInput:
                      (parseInt(prod.quantidade) / 1000)
                        .toFixed(2)
                        ?.replaceAll(".", ",") || 0,
                    valor_referencia: prod?.valor_referencia
                      ? parseFloat(prod?.valor_referencia)
                          .toFixed(4)
                          ?.replaceAll(".", ",")
                      : (0).toFixed(4).replaceAll(".", ","),
                    valor_unitario: prod?.valor_unitario
                      ? parseFloat(prod?.valor_unitario)
                          .toFixed(4)
                          ?.replaceAll(".", ",")
                      : (0).toFixed(4).replaceAll(".", ","),
                    valor_frete: prod?.valor_frete
                      ? parseFloat(prod?.valor_frete)
                          ?.toFixed(4)
                          ?.replaceAll(".", ",")
                      : (0).toFixed(4).replaceAll(".", ","),
                    total_frete: totalLinhaFrete,
                    total_taxa_financeira: totalLinhaTaxaFinanceira,
                    preco_litro_final: precoLitroFinal,
                    total: totalLinhaProduto || 0,
                    total_sem_taxas: totalLinhaSemTaxas,
                    taxa_financeira: taxaFinan || 0,
                    total_servico:
                      (
                        parseFloat(
                          totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                        ) -
                        parseFloat(
                          totalLinhaProdutoSemTaxa
                            .replaceAll(".", "")
                            .replaceAll(",", ".")
                        )
                      ).toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      }) || 0,

                    preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
                    valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
                  });
                }
              }


              return obj;
            });

            // soma total do pedido com taxa e sem taxa
            arr.map((prod) => {
              prod.total_cotacao_sem_taxa =
                parseFloat(totalProdutoSemTaxa.toFixed(3)) || 0;
              prod.total_cotacao_com_taxa =
                parseFloat(totalProdutoComTaxa.toFixed(3)) || 0;
              return prod;
            });

            return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
          });

          setListaProdutos(formattedList);

          //     const cotacao = listaProdutos.filter((cot) => cot.id === item.id);
        } else {
          // Atualiza os valores das taxas

          const taxAdm = cotacao?.taxa_administrativa_unitaria;

          setTaxaAdministrativa(
            cotacao?.taxa_administrativa_unitaria?.replaceAll(".", ",")
          );

          const disponibilidadeProdutos = cotacao.produtos_cotacao.map(
            (item) => {

              if (cotacao?.status_cotacao === "Análise Mesa de Operação" && user?.cadastro_tipo_id === 1){
                if (listaMenorPreco.find( id => id === item?.id )) {
                  return {
                    disponibilidade_distribuidor: "s",
                    produto_id: item.produto_id,
                  };
                } else {
                  return {
                    disponibilidade_distribuidor: "n",
                    produto_id: item.produto_id,
                  };
                }
              } else {
                return {
                  disponibilidade_distribuidor: item.disponibilidade_distribuidor,
                  produto_id: item.produto_id,
                };
              }
            }
          );

          const formattedList = cotacao.prazos.map((prazo) => {
            let precoLitroFinal = 0;
            let totalLinhaSemTaxas = 0;
            let totalLinhaProduto = 0;
            let totalProdutoSemTaxa = 0;
            let totalProdutoComTaxa = 0;
            let idPrazo = prazo.id;
            let prazoCotacao = prazo.prazo;
            let totalLinhaFrete = 0;
            let totalLinhaTaxaFinanceira = 0;
            let totalLitros = 0;
            let taxaFinan = "";
            let totalLinhaProdutoSemTaxa = 0;
            let precoLitroSemTaxas = 0;

            const arr = prazo.produtos.map((prod) => {
              const obj = {};
              let filterTaxa = [];

              //Total Litros/Quantidade da Cotação
              totalLitros = prazo.produtos.reduce((total, b) => {
                const totalM3 = parseFloat(b.quantidade);
                return total + totalM3;
              }, 0);

              const ProdutoCotacao = cotacao.produtos_cotacao.filter(
                (cot_prod) => {
                  return cot_prod.produto_id === prod.produto_id;
                }
              );

              if (!prod.taxa_financeira_percentual) {
                filterTaxa = taxFinanceira.filter((tax) => {
                  if (
                    parseInt(prazo.prazo) >= parseInt(tax.taxa_financeira_de) &&
                    parseInt(prazo.prazo) <= parseInt(tax.taxa_financeira_ate)
                  ) {
                    return true;
                  }

                  return false;
                });

                taxaFinan = filterTaxa[0]?.taxa_financeira_percentual || 0;
              } else {
                taxaFinan = prod.taxa_financeira_percentual;
              }

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(
                    parseFloat(prod?.valor_unitario) +
                      parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                  ) *
                    (parseFloat(taxaFinan) / 100 + 1) +
                  parseFloat(taxAdm)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              } else {
                // Valor Preço Litro Final
                precoLitroFinal = (
                  parseFloat(prod?.valor_unitario) +
                  parseFloat(prod.valor_frete?.replaceAll(",", ".") || 0)
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 4,
                });
              }

              // Valor Total sem taxas produto linha
              totalLinhaSemTaxas = (
                parseFloat(prod?.valor_unitario) * parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              // Preço Final sem Taxas (Preço + Frete)
              precoLitroSemTaxas = (
                parseFloat(prod?.valor_unitario || 0) +
                parseFloat(prod?.valor_frete || 0)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 4,
              });

              // Valor Total produto linha sem Taxas (Preço + Frete * Quantidade)
              totalLinhaProdutoSemTaxa = (
                (parseFloat(prod?.valor_unitario || 0) +
                  parseFloat(prod?.valor_frete || 0)) *
                parseFloat(prod?.quantidade)
              ).toLocaleString("pt-br", {
                minimumFractionDigits: 2,
              });

              if (parseInt(user.cadastro_tipo_id) !== 2) {
                // Valor Total produto linha
                totalLinhaProduto = (
                  parseFloat(
                    (
                      (parseFloat(prod?.valor_unitario) +
                        parseFloat(prod?.valor_frete || 0)) *
                      (parseFloat(taxaFinan) / 100 + 1)
                    ).toFixed(4)
                  ) *
                    parseFloat(prod?.quantidade) +
                  parseFloat(prod?.quantidade) * parseFloat(taxAdm)
                ).toFixed(2); //arredondamento

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              } else {
                // Valor Total produto linha
                totalLinhaProduto = (
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0)) *
                  parseFloat(prod?.quantidade)
                ).toFixed(2);

                totalLinhaProduto = parseFloat(
                  totalLinhaProduto
                ).toLocaleString("pt-br", {
                  minimumFractionDigits: 2,
                });
              }

              //Soma total Sem taxas Com Frete
              totalProdutoSemTaxa =
                totalProdutoSemTaxa +
                parseFloat(prod?.quantidade) *
                  (parseFloat(prod?.valor_unitario) +
                    parseFloat(prod?.valor_frete || 0));

              //Soma total Com taxas + Frete
              // Calcula o total do pedido inteiro com todas as taxas.
              // Valor total da cotação com todas as taxas
              totalProdutoComTaxa =
                parseFloat(totalProdutoComTaxa) +
                parseFloat(
                  totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                );

              totalLinhaFrete =
                parseFloat(prod?.valor_frete || 0) *
                parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira =
                parseFloat(
                  (
                    (parseFloat(prod?.valor_unitario) +
                      parseFloat(prod?.valor_frete)) *
                    (parseFloat(taxaFinan) / 100)
                  ).toFixed(4)
                ) * parseFloat(prod?.quantidade);

              totalLinhaTaxaFinanceira = parseFloat(
                totalLinhaTaxaFinanceira.toFixed(2)
              );

              Object.assign(obj, {
                id: prod?.id,
                id_produto: prod?.produto_id,
                id_cotacao_produto: ProdutoCotacao[0]?.id || "",
                descricao: prod.produto.descricao,
                disponibilidade_distribuidor: disponibilidadeProdutos.filter(
                  (i) => i.produto_id === prod?.produto_id
                )[0].disponibilidade_distribuidor,
                prazo: prazo.prazo,
                quantidade: prod.quantidade || 0,
                qtdInput:
                  (parseInt(prod.quantidade) / 1000)
                    .toFixed(2)
                    ?.replaceAll(".", ",") || 0,
                valor_referencia: prod?.valor_referencia
                  ? parseFloat(prod?.valor_referencia)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_unitario: prod?.valor_unitario
                  ? parseFloat(prod?.valor_unitario)
                      .toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                valor_frete: prod?.valor_frete
                  ? parseFloat(prod?.valor_frete)
                      ?.toFixed(4)
                      ?.replaceAll(".", ",")
                  : (0).toFixed(4).replaceAll(".", ","),
                total_frete: totalLinhaFrete,
                total_taxa_financeira: totalLinhaTaxaFinanceira,
                preco_litro_final: precoLitroFinal,
                total: totalLinhaProduto || 0, // AQUI
                total_sem_taxas: totalLinhaSemTaxas,
                taxa_financeira: taxaFinan || 0,
                total_servico:
                  (
                    parseFloat(
                      totalLinhaProduto.replaceAll(".", "").replaceAll(",", ".")
                    ) -
                    parseFloat(
                      totalLinhaProdutoSemTaxa
                        .replaceAll(".", "")
                        .replaceAll(",", ".")
                    )
                  ).toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  }) || 0,

                preco_total_produto_sem_taxa: precoLitroSemTaxas || 0,
                valor_total_produto_sem_taxa: totalLinhaProdutoSemTaxa || 0,
              });

              return obj;
            });

            // soma total do pedido com taxa e sem taxa
            arr.map((prod) => {
              prod.total_cotacao_sem_taxa =
                parseFloat(totalProdutoSemTaxa.toFixed(3)) || 0;
              prod.total_cotacao_com_taxa =
                parseFloat(totalProdutoComTaxa.toFixed(3)) || 0;
              return prod;
            });

            return { id: idPrazo, prazo: prazoCotacao, produtos: arr };
          });

          setListaProdutos(formattedList);

          //     const cotacao = listaProdutos.filter((cot) => cot.id === item.id);
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const { data: response } = await api.post(`/pedido/filter`, {
          id: history.location.state?.pedido?.id || history.location.state?.id,
        });

        const pedido = response.dados.data[0];

        const cliente =
          pedido.cadastro.razao_social +
          " - " +
          pedido.cadastro.cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          );

        const date = pedido.data_entrega_desejada;
        const numeroPedido = pedido.numero_pedido_sistema;
        const numeroCotacao = history.location.state?.numeroCotacao
          ? history.location.state?.numeroCotacao
          : cotacao.numero_cotacao_sistema;
        const frete = pedido.frete;
        // const abastecimento = pedido.modo_abasteciemento;
        const logradouro = pedido.cadastro.endereco.logradouro;
        const numero = pedido.cadastro.endereco.numero;
        const complemento = pedido.cadastro.endereco.complemento;
        const bairro = pedido.cadastro.endereco.bairro;
        const UF = pedido.cadastro.endereco.municipio.estado;
        const cidade = pedido.cadastro.endereco.municipio.nome;
        const cep = pedido.cadastro.endereco.cep;
        const periodo = pedido.periodo_desejado;
        const horario = pedido.horario_desejado;
        const saldo = pedido.cadastro.dados_cobranca.saldo_credito_total;
        const limite_credito = pedido.cadastro.dados_cobranca.limite_credito;
        const numeroPedidoCliente = pedido.numero_pedido_cliente;
        const obs = pedido.observacao;

        // setBaseDist({
        //   value: response.dados.data[0].cotacoes[0].base_distribuicao?.id,
        //   label: response.dados.data[0].cotacoes[0].base_distribuicao?.nome,
        // });

        setStatusPedido(
          response.dados.data[0].status_pedido ||
            history.location.state.original?.status_pedido
        );
        setCotacao(cotacao?.status_cotacao);
        setCliente({ value: `${cliente}`, label: `${cliente}` });
        setDate(date);
        setNumeroPedido(numeroPedido);
        setNumeroCotacao(numeroCotacao);
        setFrete({ value: `${frete}`, label: `${frete}` });
        setObservacoes(obs);
        // setAbastecimento({
        //   value: `${abastecimento}`,
        //   label: `${abastecimento}`,
        // });
        if (pedido.modo_abasteciemento === "Bomba") {
          setAbastecimento({
            value: pedido.modo_abasteciemento,
            label: "Com Bomba",
          });
        } else {
          setAbastecimento({
            value: pedido.modo_abasteciemento,
            label: "Sem Bomba",
          });
        }

        setPeriodo({ value: `${periodo}`, label: `${periodo}` });
        setHorario(horario);
        setLogradouro(logradouro);
        setNumero(numero);
        setNumeroPedidoCliente(numeroPedidoCliente);
        setComplemento(complemento);
        setBairro(bairro);
        setUF(UF);
        setCidade(cidade);
        setCep(cep);
        // setDistribuidorPreferencia({
        //   label: pedido.cotacoes[0]?.distribuidor?.razao_social || "",
        //   value: pedido.cotacoes[0]?.distribuidor?.id || "",
        // });

        // Info Motorista
        setMotorista(cotacao.motorista_nome);
        setRgMotorista(cotacao.motorista_rg);
        setCpfMotorista(cotacao.motorista_cpf);
        setPlacaVeiculo(cotacao.motorista_placa);
        setCelular(cotacao.motorista_tel);

        setMotivo(pedido?.motivo_cancelamento?.motivo);
        setJustificativa(pedido?.motivo_cancelamento?.justificacao);

        if (pedido?.motivo_cancelamento !== null) {
          setPrecoJustificado(pedido?.motivo_cancelamento.preco);

          if (parseFloat(pedido?.motivo_cancelamento.preco) > 0) {
            setPrecoJustificado(
              parseFloat(pedido?.motivo_cancelamento.preco).toLocaleString(
                "pt-br",
                {
                  minimumFractionDigits: 2,
                }
              )
            );
          }
        }

        setSaldo(saldo);
        setLimiteCredito(limite_credito);
        setTolerancia(pedido.cadastro.dados_cobranca.tolerancia_maxima);
        setPrazoPedido(pedido.prazo);

        setShowScreen(true);
      } catch (error) {
        console.log(error);
        setShowScreen(true);
      }
    })();
  }, [distancia, history, user, listaMenorPreco]);


  const handleViewOrdersProgramed = useCallback(() => {
    history.push("/orders/painel/program", { numeroPedido });
  }, [history, numeroPedido]);

  // const handleReenviarEmail = useCallback(async () => {
  //   setLoadingEmail(true);
  //   try {
  //     await api.get(`/email/pedido-aprovado/${cotacaoId}`);
  //     setLoadingEmail(false);
  //     toast.error("E-mails de confirmação de pedido reenviados com sucesso.");

  //   } catch (error) {
  //     toast.error("Erro no envio do e-mails da confirmação do pedido.");
  //     setLoadingEmail(false);
  //   }
  // }, [cotacaoId]);

  return (
    <>
      <Container>
        {showScreen ? (
          <CreateForm>
            <header>
              <h4>
                <FormattedMessage id="pedidoCotacao" />
              </h4>
            </header>

            <form>
              {parseInt(user.cadastro_tipo_id) === 1 ? (
                <>
                  <HeaderOrder>
                    {/* {statusPedido === "Aprovado" ||
                      statusPedido === "Aguardando Faturamento" ||
                      statusPedido === "Faturado" ? (
                        <div style={{ width: "150px" }}>
                          <Button
                            type="button"
                            onClick={handleReenviarEmail}
                            loading={loadingEmail}
                          >
                            Reenviar Email
                          </Button>
                        </div>
                      ) : (
                        <div></div>
                      )} */}

                    {parseInt(user.cadastro_tipo_id) !== 2 &&
                    hasProgramacao.length > 0 ? (
                      <div style={{ width: "150px" }}>
                        <Button
                          type="button"
                          onClick={handleViewOrdersProgramed}
                        >
                          {<FormattedMessage id="pedidosProgramado" />}
                        </Button>
                      </div>
                    ) : (
                      <div></div>
                    )}

                    <div style={{ float: "right" }}>
                      <InfoOrder>
                        <div>
                          <p>
                            <FormattedMessage id="limiteCreditoDisponivel" />
                          </p>
                          <strong>
                            {isNaN(limiteCredito)
                              ? (0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : parseFloat(
                                  limiteCredito * (tolerancia / 100 + 1) || 0
                                ).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })}
                          </strong>
                        </div>
                        <div>
                          <p>
                            <FormattedMessage id="seuLimiteSaldoDisponivel" />
                          </p>
                          <strong>
                            {isNaN(saldo)
                              ? (0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })
                              : parseFloat(saldo || 0).toLocaleString("pt-br", {
                                  minimumFractionDigits: 2,
                                  style: "currency",
                                  currency: "BRL",
                                })}
                          </strong>
                        </div>

                        <div style={{ width: "100px", marginBottom: "-5px" }}>
                          <h5>Status</h5>
                          <h5>{cotacao}</h5>
                        </div>
                      </InfoOrder>
                    </div>
                  </HeaderOrder>
                </>
              ) : parseInt(user.cadastro_tipo_id) === 3 ? (
                <HeaderOrder>
                  {parseInt(user.cadastro_tipo_id) !== 2 &&
                  hasProgramacao.length > 0 ? (
                    <div style={{ width: "150px" }}>
                      <Button type="button" onClick={handleViewOrdersProgramed}>
                        {<FormattedMessage id="pedidosProgramado" />}
                      </Button>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </HeaderOrder>
              ) : (
                <></>
              )}
              <div className="sideBySide">
                <div>
                  <header className="header-two">
                    <h4>
                      <FormattedMessage id="dadosPedido" />
                    </h4>
                  </header>
                </div>

                {parseInt(user.cadastro_tipo_id) === 2
                  ? (statusPedido === "Aprovado" ||
                      statusPedido === "Aguardando Faturamento" ||
                      statusPedido === "Faturado") && (
                      <ListSelect
                        labelPlaceholder={<FormattedMessage id="cliente" />}
                        value={cliente}
                        isDisabled={true}
                        readOnly
                      />
                    )
                  : null}

                {parseInt(user.cadastro_tipo_id) === 1 ? (
                  <ListSelect
                    labelPlaceholder={<FormattedMessage id="cliente" />}
                    value={cliente}
                    isDisabled={true}
                    readOnly
                  />
                ) : (
                  <InputLabel
                    name="cotacao"
                    label={"Status"}
                    type="text"
                    value={cotacao}
                    readOnly
                  />
                )}

                <InputMaskDefault
                  mask={"int15"}
                  label={<FormattedMessage id="numPedidoSistema" />}
                  type="text"
                  value={numeroPedido}
                  readOnly
                />

                <InputLabel
                  label={<FormattedMessage id="numeroCotacao" />}
                  type="text"
                  value={numeroCotacao}
                  readOnly
                />

                <InputMaskDefault
                  mask={"int15"}
                  label={<FormattedMessage id="numeroPedidoCliente" />}
                  type="text"
                  value={numeroPedidoCliente}
                  readOnly={true}
                />

                {/* {parseInt(user.cadastro_tipo_id) !== 3 && (
                  <InputMaskDefault
                    mask={"int15"}
                    label={<FormattedMessage id="nfDistribuidorTRR" />}
                    type="text"
                    readOnly={true}
                  />
                )} */}

                <TextArea
                  maxLength={"200"}
                  placeholder={"Observações"}
                  value={observacoes}
                  onChange={(e) => setObservacoes(e.target.value)}
                  readOnly={true}
                />

                {parseInt(user.cadastro_tipo_id) === 1 ? (
                  <>
                    <InputMaskDefault
                      mask={"currency7"}
                      label={taxaAdministrativaPlaceholder + " R$:"}
                      type="text"
                      value={taxaAdministrativa}
                      readOnly
                    />
                  </>
                ) : null}
              </div>

              <div className="sideBySideTwo">
                <div>
                  <header className="header-two">
                    <h4>
                      <FormattedMessage id="dadosEntrega" />
                    </h4>
                  </header>
                </div>

                <InputLabel
                  name="date"
                  label={<FormattedMessage id="dataEntregaDesejada" />}
                  type="date"
                  style={{ color: "hsl(0,0%,50%)" }}
                  value={date}
                  onChange={(e) => setDate(e)}
                  readOnly
                />

                <div>
                  <ListSelect
                    labelPlaceholder={<FormattedMessage id="frete" />}
                    value={frete}
                    isDisabled={true}
                    onChange={(e) => setFrete(e)}
                  />
                  {frete?.value === "FOB (Retira)" && (
                    <Button
                      type="button"
                      onClick={() => {
                        setShowEndereco(false);
                        setShowInfoMotorista(!showInfoMotorista);
                      }}
                    >
                      {<FormattedMessage id="infoMotorista" />}
                    </Button>
                  )}
                </div>

                <ListSelect
                  labelPlaceholder={<FormattedMessage id="modoAbastecimento" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  value={abastecimento}
                  cacheOptions
                  defaultOptions
                  onChange={(e) => setAbastecimento(e)}
                  isDisabled={true}
                />

                <StyleButton>
                  <p>
                    <FormattedMessage id="endereco" />:
                  </p>
                  <div>
                    <ButtonIcon
                      Icon={FaAngleDoubleRight}
                      backgroundColor={"#ff7375"}
                      type={"button"}
                      onClick={() => {
                        setShowInfoMotorista(false);
                        setShowEndereco(!showEndereco);
                      }}
                    />
                  </div>
                </StyleButton>

                <ListSelect
                  labelPlaceholder={<FormattedMessage id="periodoDesejado" />}
                  placeholder={<FormattedMessage id="selecionar" />}
                  value={periodo}
                  onChange={(e) => setPeriodo(e)}
                  isDisabled={true}
                />

                <InputLabel
                  label={<FormattedMessage id="horarioDesejado" />}
                  value={horario}
                  mask={MaskInput.hour}
                  onChange={(e) => setHorario(e)}
                  readOnly
                />

                {parseInt(user.cadastro_tipo_id) === 3 ? (
                  (statusPedido === "Aprovado" ||
                    statusPedido === "Aguardando Faturamento" ||
                    statusPedido === "Faturado") && (
                    <>
                      <AsyncListSelect
                        value={baseDist}
                        labelPlaceholder={
                          <FormattedMessage id="baseDistribuicao" />
                        }
                        placeholder={<FormattedMessage id="selecionar" />}
                        cacheOptions
                        defaultOptions
                        isDisabled={true}
                      />

                      <AsyncListSelect
                        value={distribuidorPreferencia}
                        labelPlaceholder={
                          <FormattedMessage id="distribuidores" />
                        }
                        placeholder={<FormattedMessage id="selecionar" />}
                        isDisabled={true}
                      />
                    </>
                  )
                ) : (
                  <>
                    <AsyncListSelect
                      value={baseDist}
                      labelPlaceholder={
                        <FormattedMessage id="baseDistribuicao" />
                      }
                      placeholder={<FormattedMessage id="selecionar" />}
                      cacheOptions
                      defaultOptions
                      isDisabled={true}
                    />

                    <AsyncListSelect
                      value={distribuidorPreferencia}
                      labelPlaceholder={
                        <FormattedMessage id="distribuidores" />
                      }
                      placeholder={<FormattedMessage id="selecionar" />}
                      isDisabled={true}
                    />
                  </>
                )}
              </div>
            </form>

            <br />

            {listaProdutos.map((item, index) => {
              if (user.cadastro_tipo_id === 2) {
                if (prazoPedido === item.prazo && statusPedido === "Aprovado") {
                } else if (index > 0) {
                  return null;
                } else {
                }
              }

              return (
                <FormTax key={index}>
                  <CardProduct
                    data={item}
                    //onChange={(e) => handleChangeTaxaFinanceira(e, item)}
                    sizeArray={listaProdutos.length}
                    index={index}
                    statusPedido={statusPedido}
                    statusCotacao={cotacao}
                    prazoPedido={prazoPedido}
                  >
                    <main>
                      <table>
                        <thead>
                          <tr>
                            {parseInt(user.cadastro_tipo_id) === 2 ? (
                              <th className="disponivel">
                                <TitleTax>Disponivel?</TitleTax>
                              </th>
                            ) : (
                              parseInt(user.cadastro_tipo_id) === 1 && (
                                <th className="disponivel">
                                  <TitleTax>Melhor Preço?</TitleTax>
                                </th>
                              )
                            )}

                            <th className="produto">
                              <TitleTax>
                                <FormattedMessage id="produto" />
                              </TitleTax>
                            </th>
                            {user.cadastro_tipo_id !== 2 && (
                              <th className="prazo">
                                <TitleTax>
                                  <FormattedMessage id="prazoPagamento" />
                                </TitleTax>
                              </th>
                            )}
                            <th className="quantidade">
                              <TitleTax>{quantidadePlaceholder} (M³)</TitleTax>
                            </th>
                            {/* <th className="precoRef">
                              <TitleTax>
                                <FormattedMessage id="precoReferenciaLitro" />
                              </TitleTax>
                            </th> */}
                            <th className="precoNegociado">
                              <TitleTax>
                                <FormattedMessage id="precoNegociadoLitro" />
                              </TitleTax>
                            </th>
                            <th className="precoFrete">
                              <TitleTax>
                                <FormattedMessage id="precoFreteLitro" />
                              </TitleTax>
                            </th>
                            <th className="precoFinalSemTaxa">
                              {user.cadastro_tipo_id === 2 ? (
                                <TitleTax>Preço Final</TitleTax>
                              ) : user.cadastro_tipo_id === 3 ? (
                                <TitleTax>Preço Final (Litro)</TitleTax>
                              ) : (
                                <TitleTax>Preço Final Sem Taxa</TitleTax>
                              )}
                            </th>

                            {user.cadastro_tipo_id === 3 ||
                            user.cadastro_tipo_id === 2 ? null : (
                              <th className="precoFinalComTaxa">
                                <TitleTax>Preço Final Com Taxa</TitleTax>
                              </th>
                            )}

                            {user.cadastro_tipo_id !== 3 && (
                              <th className="TotalSemTaxa">
                                {user.cadastro_tipo_id === 2 ? (
                                  <TitleTax>Total</TitleTax>
                                ) : (
                                  <TitleTax>Total sem Taxa</TitleTax>
                                )}
                              </th>
                            )}

                            {user.cadastro_tipo_id === 3 && (
                              <th className="TotalComTaxa">
                                <TitleTax>Valor Taxa Serviço</TitleTax>
                              </th>
                            )}

                            {user.cadastro_tipo_id === 3 ? (
                              <th className="TotalComTaxa">
                                <TitleTax>Valor Total</TitleTax>
                              </th>
                            ) : user.cadastro_tipo_id === 2 ? null : (
                              <th className="TotalComTaxa">
                                <TitleTax>Total com Taxa</TitleTax>
                              </th>
                            )}
                          </tr>
                        </thead>

                        <tbody>
                          {item.produtos.map((data, index) => {
                            return (
                              <tr key={index}>
                         {parseInt(user.cadastro_tipo_id) === 2 && (
                                  <td>
                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        data?.disponibilidade_distribuidor ===
                                        "s"
                                          ? true
                                          : false
                                      }
                                      value={data?.id_produto}
                                      disabled={
                                        true
                                      }
                                    />
                                  </td>
                                )}

                                {parseInt(user.cadastro_tipo_id) === 1 && (
                                  <td>

                                    <input
                                      type="checkbox"
                                      defaultChecked={
                                        listaMenorPreco?.find( id => id === data?.id_cotacao_produto ) ? true : false
                                      }
                                      value={data?.id_produto}
                                      disabled={
                                       true
                                      }
                                    />
                                  </td>
                                )}
                                <td>
                                  {/* <InputLabel
                                  type="text"
                                  value={data?.descricao}
                                  readOnly
                                /> */}
                                  <strong>{data?.descricao}</strong>
                                </td>
                                {user.cadastro_tipo_id !== 2 && (
                                  <td>
                                    <InputMaskDefault
                                      type="text"
                                      name="prazo"
                                      value={data?.prazo}
                                      readOnly
                                    />
                                  </td>
                                )}
                                <td>
                                  <InputMaskDefault
                                    mask={"currency4"}
                                    type="text"
                                    name="quantidade"
                                    value={data?.qtdInput}
                                    readOnly
                                  />
                                </td>
                                {/* <td>
                                  <InputMaskDefault
                                    mask={"currency4"}
                                    type="text"
                                    name="valor_referencia"
                                    value={data?.valor_referencia}
                                    readOnly
                                  />
                                </td> */}
                                {parseInt(user.cadastro_tipo_id) === 1 ||
                                parseInt(user.cadastro_tipo_id) === 3 ? (
                                  <>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        value={data?.valor_unitario}
                                        readOnly
                                      />
                                    </td>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        value={data?.valor_frete}
                                        onChange={() => {}}
                                        readOnly
                                      />
                                    </td>
                                  </>
                                ) : (
                                  <>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        defaultValue={data?.valor_unitario}
                                        readOnly
                                      />
                                    </td>
                                    <td>
                                      <InputMaskDefault
                                        mask={"currency4"}
                                        type="text"
                                        defaultValue={data?.valor_frete}
                                        readOnly
                                      />
                                    </td>
                                  </>
                                )}
                                <td>
                                  <InputMaskDefault
                                    mask={"currency4"}
                                    type="text"
                                    name="preco_final"
                                    value={data?.preco_total_produto_sem_taxa}
                                    readOnly
                                  />
                                </td>
                                {user.cadastro_tipo_id === 3 ||
                                user.cadastro_tipo_id === 2 ? null : (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency4"}
                                      type="text"
                                      name="preco_final"
                                      value={data?.preco_litro_final}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id !== 3 && (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency4"}
                                      type="text"
                                      name="preco_final"
                                      value={data?.valor_total_produto_sem_taxa}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id === 3 && (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total_servico}
                                      readOnly
                                    />
                                  </td>
                                )}

                                {user.cadastro_tipo_id === 3 ? (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total}
                                      readOnly
                                    />
                                  </td>
                                ) : user.cadastro_tipo_id === 2 ? null : (
                                  <td>
                                    <InputMaskDefault
                                      mask={"currency2"}
                                      type="text"
                                      name="total"
                                      value={data?.total}
                                      readOnly
                                    />
                                  </td>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </main>
                  </CardProduct>
                </FormTax>
              );
            })}

            <hr />

            <CreateFooter>
              <div>
                <Button
                  type="button"
                  themeType="back"
                  onClick={handleBackButton}
                >
                  <FormattedMessage id="voltar" />
                </Button>
              </div>

              {parseInt(user.cadastro_tipo_id) === 3 && cotacao === "Análise Mesa de Operação" && (
                <>
                  <h5 style={{ lineHeight: "50px", color: "#221c46" }}>
                    Seu pedido está sendo analisado pela Mesa de Operação, assim que validado os valores serão atualizados.
                  </h5>
                </>
              )}
              {/* Seleciona Botões conforme as visões Sodexo e Distribuidores e Cliente */}
              {parseInt(user.cadastro_tipo_id) === 1 ? (
                <>
                  {cotacao === "Análise Cliente" ||
                  cotacao === "Aprovado" ||
                  cotacao === "Entregue" ? (
                    <>
                    <h5 style={{ lineHeight: "30px", color: "#221c46", textAlign: "right"}}>
                      <FormattedMessage id="cotacaoEnviadaCliente" />
                      {motivoLiberacao !== null ?
                       <div
                       style={{
                         flexDirection: "column",
                         width: "100%",
                         wordBreak: "break-word",
                         justifyContent: "space-around",
                         alignItems: "center",
                         padding: 0,
                       }}
                     >
                      Liberado produto mais caro: {motivoLiberacao}
                     </div>
                     : ""
                    }
                    </h5>

                    </>

                  ) : cotacao === "Cancelado" || cotacao === "Reprovado" ? (
                    <>
                      {(motivo !== "" || motivoCotacao !== "") && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px",
                            wordBreak: "break-word",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <h5 style={{ color: "#221c46" }}>
                            {(cotacao === "Cancelado" && motivoCotacao) ||
                            (cotacao === "Cancelado" && motivo)
                              ? `Motivo Cancelamento: ${
                                  motivoCotacao || motivo
                                }`
                              : ((cotacao === "Reprovado" && motivoCotacao) ||
                                  (cotacao === "Reprovado" && motivo)) &&
                                `Motivo Reprovação:${motivoCotacao || motivo}`}
                          </h5>
                          {precoJustificado &&
                            precoJustificado !== "0.0000" && (
                              <strong>Valor: {precoJustificado}</strong>
                            )}
                          {motivoCotacao === "Outros" ? (
                            <strong>{justificativaCotacao}</strong>
                          ) : (
                            motivo === "Outros" && (
                              <strong>{justificativa}</strong>
                            )
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ marginLeft: "auto", width: "250px" }}>
                      <div style={{ float: "left", marginRight: "5px" }}></div>
                      <div style={{ display: "inline-block" }}></div>
                    </div>
                  )}
                </>
              ) : parseInt(user.cadastro_tipo_id) === 2 ? (
                <>
                  {cotacao === "Análise Distribuidor/TRR" ||
                  cotacao === "Reanálise Distribuidor/TRR" ? (
                    <div style={{ float: "left", marginRight: "5px" }}></div>
                  ) : (
                    <>
                      {(cotacao === "Análise Cliente" ||
                        cotacao === "Aprovado" ||
                        cotacao === "Faturado" ||
                        cotacao === "Entregue") && (
                        <h5
                          style={{
                            lineHeight: "50px",
                            color: "#221c46",
                          }}
                        >
                          <FormattedMessage id="cotacaoEnviadaCliente" />
                        </h5>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {cotacao === "Análise Cliente" ? (
                    <div style={{ marginLeft: "auto", width: "250px" }}></div>
                  ) : cotacao === "Cancelado" || cotacao === "Reprovado" ? (
                    <>
                      {(motivo || motivoCotacao) && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "250px",
                            wordBreak: "break-word",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: 0,
                          }}
                        >
                          <h5 style={{ color: "#221c46" }}>
                            {(cotacao === "Cancelado" && motivoCotacao) ||
                            (cotacao === "Cancelado" && motivo)
                              ? `Motivo Cancelamento: ${
                                  motivoCotacao || motivo
                                }`
                              : ((cotacao === "Reprovado" && motivoCotacao) ||
                                  (cotacao === "Reprovado" && motivo)) &&
                                `Motivo Reprovação:${motivoCotacao || motivo}`}
                          </h5>
                          {precoJustificado &&
                            precoJustificado !== "0.0000" && (
                              <strong>Valor: {precoJustificado}</strong>
                            )}
                          {motivoCotacao === "Outros" ? (
                            <strong>{justificativaCotacao}</strong>
                          ) : (
                            motivo === "Outros" && (
                              <strong>{justificativa}</strong>
                            )
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    cotacao === "Análise Distribuidor/TRR" && (
                      <>
                        <h5 style={{ lineHeight: "50px", color: "#221c46" }}>
                          Cotações em Análise
                        </h5>
                      </>
                    )
                  )}
                </>
              )}
            </CreateFooter>
          </CreateForm>
        ) : (
          <ContentSpinner />
        )}
      </Container>

      {showEndereco && (
        <ActionContainer>
          <header>
            <h4>
              <FormattedMessage id="informacoesEndereco" />
            </h4>
            <FiXCircle onClick={() => setShowEndereco(!showEndereco)} />
          </header>
          <main>
            <InputLabel
              label={<FormattedMessage id="logradouro" />}
              value={logradouro}
              onChange={(e) => setLogradouro(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="numero" />}
              value={numero}
              onChange={(e) => setNumero(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="complemento" />}
              value={complemento}
              onChange={(e) => setComplemento(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="bairro" />}
              value={bairro}
              onChange={(e) => setBairro(e)}
              readOnly
            />
            <InputLabel
              label={"UF:"}
              value={UF}
              onChange={(e) => setUF(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="cidade" />}
              value={cidade}
              onChange={(e) => setCidade(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="cep" />}
              value={cep}
              mask={MaskInput.CEP}
              onChange={(e) => setCep(e)}
              readOnly
            />
            <InputLabel
              label={<FormattedMessage id="distanciaKM" />}
              value={distancia}
              readOnly
            />
          </main>
          <br />
        </ActionContainer>
      )}
      {showInfoMotorista && (
        <InfoMotoristaContainer>
          <header>
            <h4>{<FormattedMessage id="infoMotorista" />}</h4>
            <FiXCircle
              onClick={() => setShowInfoMotorista(!showInfoMotorista)}
            />
          </header>
          <main>
            <InputLabel
              label={<FormattedMessage id="nomeMotorista" />}
              value={nomeMotorista}
              onChange={(e) => setMotorista(e.target.value)}
              readOnly={true}
              maxLength={50}
            />

            <InputLabel
              label={<FormattedMessage id="rgMotorista" />}
              value={RgMotorista}
              onChange={(e) => setRgMotorista(e.target.value)}
              readOnly={true}
              mask={MaskInput.RG}
            />
            <InputLabel
              label={<FormattedMessage id="cpfMotorista" />}
              value={cpfMotorista}
              onChange={(e) => setCpfMotorista(e.target.value)}
              readOnly={true}
              mask={MaskInput.CPF}
            />
            <InputLabel
              label={<FormattedMessage id="placaVeiculo" />}
              value={placaVeiculo}
              onChange={(e) => setPlacaVeiculo(e.target.value)}
              readOnly={true}
              maxLength={8}
            />
            <InputLabel
              label={<FormattedMessage id="celular" />}
              value={celular}
              onChange={(e) => setCelular(e.target.value)}
              mask={MaskInput.cellphone}
              readOnly={true}
            />
          </main>
          <br />
        </InfoMotoristaContainer>
      )}
    </>
  );
};

export default injectIntl(EditPainelOrders);

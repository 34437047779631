import React, { useContext, createContext, useCallback } from "react";
import AuthContext from "./../../auth";
import api from "./../../../services/api";

const QueriesContext = createContext();

export const CustomersListSelect = ({ children }) => {
  const { user } = useContext(AuthContext);
  const timeoutRef = React.useRef(null);

  const customersOptions = useCallback(
    async (inputValue) => {
      // Função retorna a listagem de clientes para o preenchimento do ListSelect ("Combobox")
      // conforme cada visão Sodexo, Cliente, Distribuidor.
      try {
        // let empresasFormatted = [];

        if (
          parseInt(user?.cadastro_tipo_id) === 1 ||
          parseInt(user?.cadastro_tipo_id) === 2
        ) {
          var response;

          let dataFilter = {
            cadastro_tipo_id: 3,
            ativo: 1,
          };

          if (!isNaN(inputValue)) {
            Object.assign(dataFilter, { cpf_cnpj: inputValue });
          } else {
            Object.assign(dataFilter, { razao_social: inputValue });
          }

              let empresasFormatted = [];

              if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
              }

              if (inputValue !== "") {
                const cliente = () => new Promise((resolve, reject) => {
                  timeoutRef.current = setTimeout(async ()=> {
                    timeoutRef.current = null;

                    response = await api.post(`/cadastro/filter`, dataFilter);
                    var resp;
                    if (typeof response.data.data != "undefined") {
                      resp = response.data.data;
                    }

                    empresasFormatted = resp.map((empresa) => {
                      const objeto = {
                        value: empresa.id,
                        label: empresa.cpf_cnpj + " - " + empresa.razao_social,
                      };

                      return objeto;
                    });

                    resolve(empresasFormatted)

                  }, 1000);

                }).then(
                  resolve => {
                    return resolve
                  }
                ).catch(
                  reject => {
                    console.log(reject)
                  }
                )

                await cliente()

                return empresasFormatted

              } else {

                response = await api.post(`/cadastro/filter`, dataFilter);
                var resp;
                if (typeof response.data.data != "undefined") {
                  resp = response.data.data;
                } else {
                  resp = response.data;
                }

                empresasFormatted = resp.map((empresa) => {
                  const objeto = {
                    value: empresa.id,
                    label: empresa.cpf_cnpj + " - " + empresa.razao_social,
                  };

                  return objeto;
                });

                return empresasFormatted
              }
        }

        if (parseInt(user?.cadastro_tipo_id) === 3) {
          var responseEmpresa;

          let dataFilter = {
            // ativo: 1,
            id: user?.user_id,
          };

          if (!isNaN(inputValue)) {
            Object.assign(dataFilter, { cpf_cnpj: inputValue });
          } else {
            Object.assign(dataFilter, { razao_social: inputValue });
          }

              let empresasFormatted = [];

              if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current);
              }

              if (inputValue !== "") {
                const cliente = () => new Promise((resolve, reject) => {
                  timeoutRef.current = setTimeout(async ()=> {
                    timeoutRef.current = null;

                    responseEmpresa = await api.get(
                      `usuario/filter-user-cadastro`,
                      {
                        params: {
                          id: user?.user_id,
                        },
                      }
                    );
                    var resp;
                    if (typeof responseEmpresa.data.data != "undefined") {
                      resp = responseEmpresa.data.data;
                    }

                    empresasFormatted = resp.map((empresa) => {
                      const objeto = {
                        value: empresa.id,
                        label: empresa.cpf_cnpj + " - " + empresa.razao_social,
                      };

                      return objeto;
                    });

                    resolve(empresasFormatted)

                  }, 1000);

                }).then(
                  resolve => {
                    return resolve
                  }
                )

                await cliente()

                return new Promise((resolve) => {
                  return resolve(
                    empresasFormatted.filter((i) =>
                      i.label.toLowerCase().includes(inputValue.toLowerCase())
                    )
                  );
                });

              } else {

                response = await api.get(
                  `usuario/filter-user-cadastro`,
                  {
                    params: {
                      id: user?.user_id,
                    },
                  }
                );
                // var resp;
                if (typeof response.data.data != "undefined") {
                  resp = response.data.data;
                } else {
                  resp = response.data;
                }

                empresasFormatted = resp.map((empresa) => {
                  const objeto = {
                    value: empresa.cadastro_id,
                    label: empresa.cpf_cnpj + " - " + empresa.razao_social,
                  };

                  return objeto;
                });

                return empresasFormatted
              }
        }
      } catch (error) {
        console.log(error);
      }
    },
    [user]
  );

  return (
    <QueriesContext.Provider value={{ customersOptions }}>
      {children}
    </QueriesContext.Provider>
  );
};

export default QueriesContext;

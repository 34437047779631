import React, { memo, useContext } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { PageSettings } from "./../../config/page-settings.js";
import SidebarProfile from "./sidebar-profile.jsx";
import SidebarNav from "./sidebar-nav.jsx";

const Sidebar = () => {
  const context = useContext(PageSettings);
  return (
    <PageSettings.Consumer>
      {({
        toggleSidebarMinify,
        toggleMobileSidebar,
        pageSidebarTransparent,
      }) => (
        <React.Fragment>
          <div
            id="sidebar"
            className={
              "sidebar " + (pageSidebarTransparent ? "sidebar-transparent" : "")
            }
          >
            <PerfectScrollbar
              className="height-full"
              options={{ suppressScrollX: true }}
            >
              {!context.pageSidebarSearch && <SidebarProfile />}
              <SidebarNav />

              {(window.matchMedia("(max-width: 700px)").matches) ? "" : 
                <Link
                  to="/home"
                  className="sidebar-minify-btn"
                  onClick={toggleSidebarMinify}
                >
                  <i className="fa fa-angle-double-left"></i>
                </Link>
              }
            </PerfectScrollbar>
          </div>

          <div className="sidebar-bg"></div>
          <div
            className="sidebar-mobile-dismiss"
            onClick={toggleMobileSidebar}
          ></div>
        </React.Fragment>
      )}
    </PageSettings.Consumer>
  );
};

export default memo(Sidebar);

import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";
import { FormattedMessage, injectIntl } from "react-intl";

import api from "./../../../services/api";

import { Container, CreateForm, CreateFooter, ContainerRole } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import LoaderContent from "./../../../components/Spinner/Content";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

export const DisplayUser = ({ intl }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nivel, setNivel] = useState("");

  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });

  const [userTipos, setUserTipos] = useState("");

  const [empresas, setEmpresas] = useState([
    { value: "todos", label: <FormattedMessage id="todos" /> },
  ]);

  useEffect(() => {
    async function loadUser() {
      if (!history.location.state?.id) {
        return history.push("/access/users/customer/list");
      }
      /** id do user que vem da tela anterior */
      const userId = history.location.state?.id;

      try {
        const { data: response } = await api.get(`/usuario/show/${userId}`);
        /** aba dados Cadastrais */
        var cadastros = response?.usuario[0].cadastros;
        var arrayEmpresasSelecionadas = [];
        cadastros.forEach(function (item, indice, array) {
          arrayEmpresasSelecionadas.push({
            value: item.id,
            label: item.cpf_cnpj + " - " + item.razao_social,
          });
        });

        arrayEmpresasSelecionadas = arrayEmpresasSelecionadas.map((item) => {
          const arr = response?.rolesPerfis.filter(
            (role) => role.cadastro_id === item.value
          );

          arr.map((element) => {
            item.role = element.role_id;
            item.empresa = element.label;

            return element;
          });

          return item;
        });

        setEmpresas(arrayEmpresasSelecionadas);
        setName(response?.usuario[0].name);
        setEmail(response?.usuario[0].email);
        setCelular(response?.usuario[0].celular);
        setTelefone(response?.usuario[0].telefone);
        setNivel({
          value: response?.usuario[0].user_tipo.id,
          label: response?.usuario[0].user_tipo.nome,
        });
        setStatus({
          value: response?.usuario[0].ativo,
          label: response?.usuario[0].ativo === 1 ? "Ativo" : "Inativo",
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    loadUser();

    async function loadUserTiposDB() {
      try {
        const { data: response } = await api.get("usuario/list-types");
        const levelOptions = [];
        const resp = response.userTipo;
        resp.map((tipouser) => {
          return levelOptions.push({
            value: tipouser.id,
            label: tipouser.nome,
          });
        });
        setUserTipos(levelOptions);
      } catch (error) {}
    }
    loadUserTiposDB();

    async function loadRolesDB() {
      try {
        const { data: response } = await api.post(`/role/filter`, {
          cadastro_tipo_id: 3,
        });
        const roles = [];
        const resp = response.data;
        resp.map((role) => {
          return roles.push({ value: role.name, label: role.name });
        });
      } catch (error) {}
    }
    loadRolesDB();
  }, [history]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <Container>
      {loading ? (
        <LoaderContent />
      ) : (
        <CreateForm>
          <header>
            <h4>
              <FormattedMessage id="editarUsuario" />
            </h4>
          </header>

          <form>
            <AsyncListSelect
              labelPlaceholder={empresaPlaceholder}
              isMulti
              cacheOptions
              defaultOptions
              value={empresas}
              isDisabled={true}
            />
            <InputLabel
              name="name"
              type="text"
              label={<FormattedMessage id="nome" />}
              value={name}
              maxLength={100}
              readOnly
            />
            <InputLabel
              name="email"
              type="text"
              label={"E-mail"}
              value={email}
              maxLength={80}
              readOnly
            />
            <InputLabel
              name="celular"
              label={<FormattedMessage id="celular" />}
              type="text"
              mask={MaskInput.cellphone}
              value={celular || ""}
              readOnly
            />
            <InputLabel
              name="telefone"
              label={<FormattedMessage id="telefone" />}
              type="text"
              mask={MaskInput.phone}
              value={telefone || ""}
              readOnly
            />
            <ListSelect
              labelPlaceholder={nivelPlaceholder}
              value={nivel}
              options={userTipos}
              placeholder={<FormattedMessage id="selecione" />}
              isDisabled={true}
            />

            {/* ********************************************************************** */}
            {nivel.value !== 1 && nivel.value !== undefined && (
              <ContainerRole gridColumn={empresas.length}>
                {empresas.length === 0 && (
                  <h5>Selecione uma empresa para inserir os perfis</h5>
                )}
                {empresas.map((item, index) => {
                  let empresaName = item.label.split(" - ");

                  if (empresaName[1]) {
                    empresaName = empresaName[1];
                  } else {
                    empresaName = "";
                  }

                  return (
                    <AsyncListSelect
                      key={index}
                      labelPlaceholder={`${perfilPlaceholder}: ${empresaName}`}
                      isDisabled={true}
                      cacheOptions
                      placeholder={<FormattedMessage id="selecionar" />}
                      defaultOptions
                      defaultValue={{ value: item?.role, label: item?.empresa }}
                    />
                  );
                })}
              </ContainerRole>
            )}
            {/* ********************************************************************** */}

            <ListSelect
              labelPlaceholder={statusPlaceholder}
              value={status}
              isDisabled
            />
          </form>
          <hr />
          <CreateFooter>
            <div>
              <Button type="button" themeType="back" onClick={handleBackButton}>
                <FormattedMessage id="voltar" />
              </Button>
            </div>
          </CreateFooter>
        </CreateForm>
      )}
    </Container>
  );
};

export default injectIntl(DisplayUser);

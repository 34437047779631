import React from "react";
import TreeItem from "./../TreeItem";

import { TreeList } from "./styles";

const TreeComponent = ({ data, children, ...rest }) => {
  const item = data.map((item) => {
    return (
      <TreeList key={item.title.props?.id}>
        <TreeItem {...rest} item={item} />
      </TreeList>
    );
  });
  return item;
};

export default TreeComponent;

import React, { useCallback, useState, useContext } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

// import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

import AuthContext from "./../../../context/auth";

export const RefundDistributors = ({ intl }) => {
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [distribuidor, setDistribuidor] = useState("");
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  // const [status, setStatus] = useState("");

  const [numeroPedido, setNumeroPedido] = useState("");
  const [numeroCotacao, setNumeroCotacao] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const { user } = useContext(AuthContext);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    try {
      const data = {
        dataCadastroDe: dataInicial,
        dataCadastroAte: dataFinal,
        saida: "excel",
        filtroDistribuidor: distribuidor?.value,
        baseDistribuicao: baseDistribuicao?.label,
        // statusPedido: status?.value,
        numeroPedido,
        numeroCotacao,
      };

        await api
          .get(`/relatorio/reembolso`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Relatorio-Reembolso.xlsx");
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          })
          .catch((err) => console.log(err));

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    baseDistribuicao,
    dataFinal,
    dataInicial,
    distribuidor,
    numeroCotacao,
    numeroPedido,
    // status,
  ]);

  const handleSearchBasesDistributionsAsDistributors = useCallback(async () => {
    try {
      const { data: response } = await api.post("/cadastro/filter", {
        cpf_cnpj: distribuidor?.value,
      });

      const result = response?.data[0]?.base_distribuicao.map((item) => {
        const obj = {};

        Object.assign(obj, {
          value: item.id,
          label: item.nome,
        });

        return obj;
      });

      return result;
    } catch (error) {
      console.log(error);
    }
  }, [distribuidor]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioExtratoReembolsoDistribuidoresTRRs" />
          </h4>
        </header>

        <form>
          <div className="sideBySide">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataInicio" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />

            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setDistribuidor(e);
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <InputLabel
              name="nf"
              label={<FormattedMessage id="numPedidoSistema" />}
              type='number'
              value={numeroPedido}
              maxLength={20}
              onChange={(e) => setNumeroPedido(e.target.value)}
            />

            <InputLabel
              name="nf"
              type='number'
              label={<FormattedMessage id="numeroNFDistribuidores" />}
              // defaultValue={"Aguardando integração"}
              maxLength={20}
              // onChange={(e) => setDataInicial(e.target.value)}
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataFim" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />

            {parseInt(user.cadastro_tipo_id) === 1 && (
              <AsyncListSelect
                value={baseDistribuicao}
                labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                placeholder={todosPlaceholder}
                onChange={(e) => {
                  setBaseDistribuicao(e);
                }}
                myOptions={"basesDistribuição"}
                cacheOptions
                defaultOptions
                isClearable
              />
            )}

            {parseInt(user.cadastro_tipo_id) === 2 && (
              <AsyncListSelect
                key={distribuidor}
                value={baseDistribuicao}
                labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
                placeholder={<FormattedMessage id="selecionar" />}
                onChange={(e) => {
                  setBaseDistribuicao(e);
                }}
                loadOptions={handleSearchBasesDistributionsAsDistributors}
                cacheOptions
                defaultOptions
                isClearable
              />
            )}

            <InputLabel
              name="numeroCotacao"
              label={<FormattedMessage id="numeroCotacao" />}
              type="text"
              value={numeroCotacao}
              maxLength={20}
              onChange={(e) => setNumeroCotacao(e.target.value)}
            />

            {/* <ListSelect
              name="frete"
              labelPlaceholder={"Status"}
              placeholder={<FormattedMessage id="todos" />}
              cacheOptions
              defaultOptions
              options={statusOptions}
              value={status}
              onChange={(e) => setStatus(e)}
              isClearable
            /> */}
          </div>
        </form>
        <br/>
        <br/>
        <br/>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="gerar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(RefundDistributors);

import React, {
  useCallback,
  useEffect,
  useState,
  createRef,
  useContext,
} from "react";
import { useTable, usePagination } from "react-table";
import { format, parseISO } from "date-fns";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { FilterContainer } from "./styles";
import Alert from "./../../../../assets/images/alert.png";

import { FormattedMessage, injectIntl } from "react-intl";

import {
  FiEdit,
  FiSave,
  FiPlus,
  FiX,
  FiXCircle,
} from "react-icons/fi";
//import ContentSpinner from "./../../../components/Spinner/Content";
import iconFiltro from "./../../../../assets/images/icone-filtro.png";
import { Container } from "./styles";
import api from "./../../../../services/api";

import MaskInput from "./../../../../config/input-mask";

import ExportTableToExcel from "./../../../../components/ExportTableToExcel";

import ButtonIcon from "./../../../../components/ButtonIcon";
import iconVoltar from "./../../../../assets/images/icone-voltar.svg";

import ButtonImage from "./../../../../components/ButtonImage";

import InputLabel from "./../../../../components/InputLabel";
import InputMaskDefault from "./../../../../components/InputMaskDefault";

import ContentSpinner from "./../../../../components/Spinner/Content";

import ExcelLogo from "./../../../../assets/images/logo-excel.png";
import AuthContext from "./../../../../context/auth";

import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";

import Button from "../../../../components/ButtonDefault";

const ListPrices = ({ intl }) => {
  const [showFilter, setShowFilter] = useState(false);

  const { user } = useContext(AuthContext);

  const [precos, setPrecos] = useState([]);

  const [data, setData] = useState([]);

  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const [loadingList, setLoadingList] = useState(true);
  const [noData, setNoData] = useState(false);

  //const [loadingButton, setLoadingButton] = useState(false);
  //const [loadingTable, setLoadingTable] = useState(false);

  const [listChanges, setListChanges] = useState([]);

  const history = useHistory();
  const refExcel = createRef(null);

  const [disableGrid, setDisableGrid] = useState(true);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [editedList, setEditList] = useState([]);

  // Campos Filtros

  const [distribuidor, setDistribuidor] = useState(() => {
    if (user.cadastro_tipo_id === 1 || user.cadastro_tipo_id === 3) {
      return;
    } else {
      return {
        value: user.cadastro_id,
        label: user.razao_social,
      };
    }
  });
  const [baseDistribuicao, setBaseDistribuicao] = useState("");
  const [produto, setProduto] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [listaEstados] = useState("uf");
  const [listaCidades, setListaCidades] = useState("cidade");
  const [cidadeFilter, setCidadeFilter] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const dataTraducao = intl.formatMessage({ id: "data" });
  const baseTraducao = intl.formatMessage({ id: "base" });
  const distribuidorTraducao = intl.formatMessage({ id: "distribuidor" });

  useEffect(() => {
    (async () => {
      try {
        var response;

        if (user.cadastro_tipo_id !== 2) {
          // Exibe todos os preços
          response = await api.get("/preco");
        } else {
          // Exibe somente os preços do distribuidor logado
          response = await api.post("/preco/filter", {
            cadastro_id: user.cadastro_id,
          });
        }

        let column = [];
        let bases = [];
        let precosItems = [];
        var obj = {};
        var itemsEditList = [];

        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        response.data.map((item) => {
          itemsEditList.push({
            id: item.id,
            preco: item.preco,
            data: item.data,
          });
          column.push(item.produto.descricao);
          bases.push(
            item.base_distribuicao?.nome +
              "@Artium@" +
              item.cadastro.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.cadastro.razao_social +
              "@Artium@" +
              format(parseISO(item.data), "dd/MM/yyyy") +
              "@Artium@" +
              item.base_distribuicao?.id
          );

          precosItems.push({
            base:
              item.base_distribuicao?.nome +
              "@Artium@" +
              item.cadastro.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.cadastro.razao_social +
              "@Artium@" +
              format(parseISO(item.data), "dd/MM/yyyy") +
              "@Artium@" +
              item.base_distribuicao?.id,
            produto: item.produto.descricao,
            preco: item.preco,
            id: item.id,
          });

          return { column, bases };
        });

        //Estrutura somente sa parte de valores.
        column = column.filter(onlyUnique);

        //Estrutura sem a parte de valores, Data, Base e Distribuidores
        bases = bases.filter(onlyUnique);

        const basesEDistribuidores = bases.map((base) => {
          let stringSplit = base.split("@Artium@");

          obj = {
            [dataTraducao]: stringSplit[2],
            [baseTraducao]: stringSplit[0],
            // [distribuidorTraducao]: stringSplit[1],
            base_id: stringSplit[3],
          };
          if (user.cadastro_tipo_id !== 3) {
            Object.assign(obj, { [distribuidorTraducao]: stringSplit[1] });
          }
          const produtoId = [];
          column.map((prod) => {
            const preco = precosItems.filter((element) => {
              return element.base === base && element.produto === prod;
            });

            produtoId.push(preco[0]?.id);

            return (obj[prod] = preco[0]?.preco.replace(".", ",") || 0); // "Não Importado"
          });

          return Object.assign(obj, { produtoIds: produtoId });
        });

        if (response.length === 0) {
          setNoData(true);
        }

        setListChanges(response?.data || []);

        setEditList(itemsEditList);
        setPrecos(basesEDistribuidores);
        setData(basesEDistribuidores);

        setLoadingList(false);
      } catch (error) {
        console.log(error);
        setLoadingList(false);
        setNoData(true);
      }
    })();
  }, [
    baseTraducao,
    dataTraducao,
    distribuidorTraducao,
    user.cadastro_id,
    user.cadastro_tipo_id,
  ]);

  const handleCreate = useCallback(() => {
    history.push("/orders/price/create");
  }, [history]);

  const handleExportToExcel = useCallback(() => {
    refExcel.current.handleDownload();
  }, [refExcel]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        } else {
          return row;
        }
      })
    );
  };

  const handleSaveEdit = useCallback(async () => {
    try {
      setLoadingList(true);

      await api.put("/preco", editedList);
      toast.success("Preços editado com sucesso.");
      setShowSaveButton(!showSaveButton);
      setDisableGrid(!disableGrid);
      try {

        var response;

        if (user.cadastro_tipo_id === 1) {
          response =  { data: response } = await api.get("/preco");
        } else {
          response =  { data: response } = await api.post("/preco/filter", {
            cadastro_id: user.cadastro_id,
          });
        }

        let column = [];
        let bases = [];
        let precosItems = [];
        var obj = {};
        var itemsEditList = [];

        function onlyUnique(value, index, self) {
          return self.indexOf(value) === index;
        }

        response.map((item) => {
          itemsEditList.push({
            id: item.id,
            preco: item.preco,
            data: item.data,
          });
          column.push(item.produto.descricao);
          bases.push(
            item.base_distribuicao["nome"] +
              "@Artium@" +
              item.cadastro.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.cadastro.razao_social +
              "@Artium@" +
              format(parseISO(item.data), "dd/MM/yyyy") +
              "@Artium@" +
              item.base_distribuicao["id"]
          );

          precosItems.push({
            base:
              item.base_distribuicao["nome"] +
              "@Artium@" +
              item.cadastro.cpf_cnpj.replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              ) +
              " - " +
              item.cadastro.razao_social +
              "@Artium@" +
              format(parseISO(item.data), "dd/MM/yyyy") +
              "@Artium@" +
              item.base_distribuicao["id"],
            produto: item.produto.descricao,
            preco: item.preco,
            id: item.id,
          });
          return { column, bases };
        });

        //Estrutura somente sa parte de valores.
        column = column.filter(onlyUnique);

        //Estrutura sem a parte de valores, Data, Base e Distribuidores
        bases = bases.filter(onlyUnique);

        const basesEDistribuidores = bases.map((base) => {
          let stringSplit = base.split("@Artium@");

          obj = {
            [dataTraducao]: stringSplit[2],
            [baseTraducao]: stringSplit[0],
            // [distribuidorTraducao]: stringSplit[1],
            base_id: stringSplit[3],
          };
          if (user.cadastro_tipo_id !== 3) {
            Object.assign(obj, { [distribuidorTraducao]: stringSplit[1] });
          }
          const produtoId = [];
          column.map((prod) => {
            const preco = precosItems.filter((element) => {
              return element.base === base && element.produto === prod;
            });

            produtoId.push(preco[0]?.id);

            return (obj[prod] = preco[0]?.preco.replace(".", ",") || 0);
          });

          return Object.assign(obj, { produtoIds: produtoId });
        });

        setListChanges(response);
        setEditList(itemsEditList);
        setPrecos(basesEDistribuidores);
        setData(basesEDistribuidores);
        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
        return;
      }
    } catch (error) {
      setLoadingList(false);
      toast.error("Erro ao editar preços.");
    }
  }, [
    baseTraducao,
    dataTraducao,
    disableGrid,
    distribuidorTraducao,
    editedList,
    showSaveButton,
    user,
  ]);

  const handleEditGrid = useCallback(() => {
    setShowSaveButton(!showSaveButton);
    setDisableGrid(!disableGrid);
  }, [disableGrid, showSaveButton]);

  const columns = React.useMemo(() => {
    var cols;
    var headers = [];

    if (precos.length > 0) {
      cols = Object.keys(precos[0]);
      cols = cols.filter((c) => c !== "produtoIds" && c !== "base_id");
      headers = cols.map((c) => {
        // console.log(precos);
        // console.log(c);
        return {
          Header: c,
          accessor: c,
        };
      });
    }
    return headers;
  }, [precos]);

  const EditableCell = ({
    value: initialValue,
    row: indexRow,
    column: { id },

    updateMyData,
  }) => {
    const [oldValue, setOldValue] = useState(initialValue);
    const [value, setValue] = useState(initialValue);

    const [read, setRead] = useState(true);

    const onChange = (e) => {
      let newValue;

      const checkValueFormat = e?.target?.value.split(",");
      if (checkValueFormat[1]?.length < 4) {
        newValue = e?.target?.value + "0000";
      } else if (
        checkValueFormat[0].length <= 3 &&
        checkValueFormat[1]?.length < 4
      ) {
        newValue = checkValueFormat[0] + ",0000";
      } else if (checkValueFormat[0]?.length === 0) {
        newValue = "0,0000";
      } else {
        newValue = e?.target?.value;
      }

      setValue(newValue || e.target.value);
    };

    const onBlur = () => {
      //Verifica se está tentando editar as colunas de bases e distribuidores
      if (id === baseTraducao || id === distribuidorTraducao) {
        return;
      }

      // Verifica se os valores foram alterados
      if (oldValue === value) {
        return;
      }

      setOldValue(value);

      const DistribuidorToSearch =
        indexRow.values[distribuidorTraducao].split(" - ");

      const cnpj = DistribuidorToSearch[0];
      const originalData = indexRow.original;

      if (id === dataTraducao) {
        listChanges.forEach((prod) => {
          if (prod.cadastro?.cpf_cnpj === cnpj.replace(/[^\d]+/g, "")) {
            if (
              prod?.base_distribuicao_id === parseInt(originalData?.base_id)
            ) {
              editedList.forEach((elem) => {
                if (elem.id === prod.id) {
                  if (value.replace(/[^\d]+/g, "").length === 8) {
                    let dateFormatted = value.replaceAll("/", "-").split("-");
                    dateFormatted =
                      dateFormatted[2] +
                      "-" +
                      dateFormatted[1] +
                      "-" +
                      dateFormatted[0];

                    const t = new Date(dateFormatted);

                    if (!t) {
                      return;
                    }

                    return (elem.data = format(
                      parseISO(dateFormatted),
                      "yyyy-MM-dd"
                    ));
                  }
                }

                return elem;
              });

              setEditList(editedList);
            }
          }
        });

        return;
      }

      // Quando somente os preços for alterado na tabela

      listChanges.forEach((prod) => {
        if (prod.cadastro?.cpf_cnpj === cnpj.replace(/[^\d]+/g, "")) {
          if (prod?.base_distribuicao_id === parseInt(originalData?.base_id)) {
            if (prod.produto.descricao === id) {
              editedList.forEach((elem) => {
                if (elem.id === prod.id) {
                  elem.preco = value
                    ? value.replaceAll(".", "").replaceAll(",", ".")
                    : 0;
                }

                return elem;
              });

              setEditList(editedList);
            }
          }
        }
      });

      updateMyData(indexRow.index, id, value);
    };

    useEffect(() => {
      setValue(initialValue);
      //Deixa somente leitura as colunas de Base e Distribuidor
      setRead(() => id === baseTraducao || id === distribuidorTraducao);
    }, [initialValue, id]);

    if (id === dataTraducao) {
      return disableGrid ? (
        <span>{value}</span>
      ) : (
        <InputLabel
          mask={MaskInput.date}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={read}
          disabled={disableGrid}
          style={{
            margin: "0px",
            padding: "0px",
            height: "100%",
            paddingLeft: "10px",
            color: "#000",
            fontSize: "12px",
          }}
        />
      );
    } else if (id === baseTraducao || id === distribuidorTraducao) {
      return disableGrid ? (
        <span>{value}</span>
      ) : (
        <InputLabel
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={read}
          disabled={disableGrid}
          style={{
            margin: "0px",
            padding: "0px",
            height: "100%",
            paddingLeft: "10px",
            color: "#000",
            fontSize: "12px",
          }}
        />
      );
    } else {
      return disableGrid ? (
        <span>{value}</span>
      ) : (
        <InputMaskDefault
          mask={"precoCombustiveis"}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          readOnly={read}
          disabled={disableGrid}
          style={{
            margin: "0px",
            padding: "0px",
            height: "100%",
            paddingLeft: "10px",
            color: "#000",
            fontSize: "12px",
          }}
        />
      );
    }
  };
  // TESTE
  const defaultColumn = {
    Cell: EditableCell,
  };

  function Table({ columns, data, updateMyData, skipPageReset, ...rest }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      rows,

      // canPreviousPage,
      // canNextPage,
      // pageOptions,
      // pageCount,
      // gotoPage,
      // nextPage,
      // previousPage,
      // setPageSize,
      // state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // EditableCell,
        updateMyData,
      },
      usePagination
    );

    return (
      <>
        <ExportTableToExcel
          id="test-table-xls-button"
          table="table-to-xls"
          filename="Preços"
          sheet="Preços"
          ref={refExcel}
        />
        <table id="table-to-xls" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }

  const handleFilterUfByRegion = useCallback(async () => {
    try {
      const { data } = await api.get(`/estado`);

      const arr = data.map((uf) => {
        const obj = {};

        Object.assign(obj, {
          value: String(uf.id),
          label: uf.sigla,
        });

        return obj;
      });

      return arr;
    } catch (error) {}
  }, []);

  // Lista campos de Cidade
  const handleFilterCitiesByUf = useCallback(
    async (id) => {
      if (!id) {
        return;
      }

      try {
        const { data } = await api.post(`/municipio/filter`, {
          estado: id,
          nome: cidadeFilter,
        });

        const arr = data.map((municipio) => {
          const obj = {};

          Object.assign(obj, {
            value: String(municipio.id),
            label: municipio.nome,
          });

          return obj;
        });
        return arr;
      } catch (error) {}
    },
    [cidadeFilter]
  );

  const handleFilter = useCallback(async () => {
    setLoadingButton(true);

    try {
      const dataFilter = {
        cadastro_id: distribuidor?.value || "",
        base_distribuicao_id: baseDistribuicao?.value || "",
        municipio_id: cidade?.value || "",
        estado_id: estado?.value || "",
        produto_id: produto?.value || "",
      };

      const { data: response } = await api.post("/preco/filter", dataFilter);

      let column = [];
      let bases = [];
      let precosItems = [];
      var obj = {};
      var itemsEditList = [];

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      response.map((item) => {
        itemsEditList.push({ id: item.id, preco: item.preco, data: item.data });
        column.push(item.produto.descricao);
        bases.push(
          item.base_distribuicao["nome"] +
            "@Artium@" +
            item?.cadastro?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            item?.cadastro?.razao_social +
            "@Artium@" +
            format(parseISO(item.data), "dd/MM/yyyy") +
            "@Artium@" +
            item.base_distribuicao["id"]
        );

        precosItems.push({
          base:
            item.base_distribuicao["nome"] +
            "@Artium@" +
            item?.cadastro?.cpf_cnpj.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
              "$1.$2.$3/$4-$5"
            ) +
            " - " +
            item?.cadastro?.razao_social +
            "@Artium@" +
            format(parseISO(item.data), "dd/MM/yyyy") +
            "@Artium@" +
            item.base_distribuicao["id"],
          produto: item.produto.descricao,
          preco: item.preco,
          id: item.id,
        });

        return { column, bases };
      });

      //Estrutura somente sa parte de valores.
      column = column.filter(onlyUnique);

      //Estrutura sem a parte de valores, Data, Base e Distribuidores
      bases = bases.filter(onlyUnique);

      const basesEDistribuidores = bases.map((base) => {
        let stringSplit = base.split("@Artium@");

        obj = {
          [dataTraducao]: stringSplit[2],
          [baseTraducao]: stringSplit[0],
          //  [distribuidorTraducao]: stringSplit[1],
          base_id: stringSplit[3],
        };
        if (user.cadastro_tipo_id !== 3) {
          Object.assign(obj, { [distribuidorTraducao]: stringSplit[1] });
        }
        const produtoId = [];
        column.map((prod) => {
          const preco = precosItems.filter((element) => {
            return element.base === base && element.produto === prod;
          });

          produtoId.push(preco[0]?.id);

          return (obj[prod] = preco[0]?.preco.replace(".", ",") || 0); // "Não Importado"
        });

        return Object.assign(obj, { produtoIds: produtoId });
      });

      if (response.length === 0) {
        setNoData(true);
      }

      setListChanges(response);

      setEditList(itemsEditList);
      setPrecos(basesEDistribuidores);
      setData(basesEDistribuidores);
      setLoadingList(false);
      setLoadingButton(false);
      setShowFilter(false);
    } catch (error) {
      console.log(error);
      setLoadingList(false);
      setLoadingButton(false);
      setNoData(true);
      setShowFilter(false);
    }
  }, [
    baseDistribuicao?.value,
    baseTraducao,
    cidade?.value,
    dataTraducao,
    distribuidor?.value,
    distribuidorTraducao,
    estado?.value,
    produto?.value,
    user.cadastro_tipo_id,
  ]);
  return (
    <>
      <Container>
        <header>
          <nav>
            <ButtonImage
              ImageSrc={iconVoltar}
              border={true}
              color={"#fff"}
              onClick={handleGoBack}
              placeholder={<FormattedMessage id="voltar" />}
            />
            <h2>
              <FormattedMessage id="precosCombustiveis" />
            </h2>
          </nav>

          <div>
            {parseInt(user.cadastro_tipo_id) !== 3 && (
              <ButtonIcon
                Icon={FiPlus}
                backgroundColor={"#221c46"}
                onClick={handleCreate}
                placeholder={<FormattedMessage id="incluir" />}
                permission={
                  user.cadastro_tipo_id === 1
                    ? "preco-importar[1]"
                    : "preco-importar[2]"
                }
              />
            )}

            {parseInt(user.cadastro_tipo_id) !== 3 &&
              (editedList.length !== 0 ? (
                !showSaveButton ? (
                  <ButtonIcon
                    style={{border: "1px solid #221c46"}}
                    Icon={FiEdit}
                    backgroundColor={"#fff"}
                    color={"#221c46"}
                    placeholder={<FormattedMessage id="editar" />}
                    onClick={handleEditGrid}
                    permission={
                      user.cadastro_tipo_id === 1
                        ? "preco-update[1]"
                        : "preco-update[2]"
                    }
                  />
                ) : (
                  <>
                    <ButtonIcon
                      Icon={FiSave}
                      style={{border: "1px solid #221c46"}}
                      backgroundColor={"#fff"}
                      color={"#221c46"}
                      placeholder={<FormattedMessage id="salvar" />}
                      onClick={handleSaveEdit}
                    />
                    <ButtonIcon
                      Icon={FiX}
                      style={{border: "1px solid #221c46"}}
                      backgroundColor={"#fff"}
                      color={"#221c46"}
                      placeholder={<FormattedMessage id="cancelarEdicao" />}
                      onClick={handleEditGrid}
                    />
                  </>
                )
              ) : (
                ""
              ))}

                          <ButtonImage
                ImageSrc={iconFiltro}
                backgroundColor={"#221c46"}
                color={"#ffffff"}
              placeholder={<FormattedMessage id="filtrar" />}
              onClick={() => setTimeout(() => setShowFilter(!showFilter), 150)}
            />

            <ButtonImage
              ImageSrc={ExcelLogo}
              placeholder={<FormattedMessage id="exportarExcel" />}
              onClick={handleExportToExcel}
            ></ButtonImage>
          </div>
        </header>

        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
            <main>
              {!noData ? (
                <Table
                  columns={columns}
                  data={data}
                  updateMyData={updateMyData}
                  skipPageReset={skipPageReset}
                />
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "-4%",
                  }}
                >
                  <img src={Alert} alt={Alert} width={40}/>

                  <h3
                    style={{
                      color: "#b26019",
                      marginLeft: "-2%",
                      marginTop: "1%",
                    }}
                  >
                    ㅤ<FormattedMessage id="naoExistePrecosCadastrados" />
                  </h3>
                </div>
              )}
            </main>
          </>
        )}
      </Container>

      {showFilter && (
        <FilterContainer>
          <header>
            <h4>
              <FormattedMessage id="filtrar" />
            </h4>
            <FiXCircle onClick={() => setShowFilter(!showFilter)} />
          </header>

          <main>
            {user.cadastro_tipo_id !== 3 && (
              <AsyncListSelect
                value={distribuidor}
                labelPlaceholder={<FormattedMessage id="distribuidores" />}
                placeholder={<FormattedMessage id="todos" />}
                onChange={(e) => {
                  setDistribuidor(e);
                }}
                myOptions={"distribuidores"}
                cacheOptions
                defaultOptions
                isClearable
              />
            )}

            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={<FormattedMessage id="todos" />}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <AsyncListSelect
              key={listaEstados}
              onChange={(e) => {
                setCidade("");
                setListaCidades(e.label);
                setEstado(e);
              }}
              loadOptions={() => handleFilterUfByRegion(listaEstados)}
              cacheOptions
              defaultOptions
              labelPlaceholder={<FormattedMessage id="estado" />}
              placeholder={<FormattedMessage id="selecionar" />}
              noOptionsMessage={() => (
                <FormattedMessage id="selecioneRegiaoCarregar" />
              )}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              value={estado}
            />

            <AsyncListSelect
              key={listaCidades}
              cacheOptions
              defaultOptions
              loadOptions={() => {
                return handleFilterCitiesByUf(listaCidades);
              }}
              onChange={(e) => {
                setCidade(e);
              }}
              onInputChange={(e) => {
                setCidadeFilter(e);
              }}
              labelPlaceholder="Cidade"
              placeholder={<FormattedMessage id="selecionar" />}
              noOptionsMessage={() => (
                <FormattedMessage id="selecioneEstadoCarregar" />
              )}
              loadingMessage={() => <FormattedMessage id="carregando" />}
              value={cidade}
            />
          </main>

          <footer>
            <Button
              type="button"
              loading={loadingButton}
              onClick={handleFilter}
            >
              <FormattedMessage id="filtrar" />
            </Button>
          </footer>
        </FilterContainer>
      )}
    </>
  );
};

export default injectIntl(ListPrices);

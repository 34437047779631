import styled, { keyframes } from "styled-components";

const fadein = keyframes`
  from {
    width: 0px;
    opacity: 0;
  }

  to {
    width:  100%;
    opacity: 0.4;
  }
`;

export const Container = styled.div`
  width: 100%;

  height: max-content;
  display: flex;
  justify-content: center;

  animation: ${fadein} 0.3s linear;
  animation-delay: 150ms;
  animation-fill-mode: backwards;

  .sideBySide {
    width: 50%;
    float: left;
    padding: 5px;
  }

  .sideBySideTwo {
    display: inline-block;
    width: 50%;
    padding: 5px;
  }

  .header-two {
    width: 100%;
    height: 34px;
    background-color: #221c46!important;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      font-size: 13px;
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 27%;
    }
  }

  main {
    height: 300px;
    overflow: hidden;
    overflow-y: auto;
    padding: 0 40px;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
       /* border-radius: 5px; */
      background: #221c46;
    }
  }

  @media screen and (max-width: 865px) {
    .sideBySide {
      width: 100%;
    }
    .sideBySideTwo {
      width: 100%;
    }
  }
`;

export const CreateForm = styled.div`
  width: 100%;
  margin-top: 0px;
  /* background-color: #f8f8f8; */

  /* border-radius: 10px; */

  header {
    width: 100%;
    height: 34px;
    position: relative;
    background-color: #221c46;
    margin-bottom: 20px;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      width: max-content;
      height: max-content;
      margin-left: 10px;
      color: #f8f8f8;
      position: absolute;
      left: 0;
      top: 23%;
    }
  }

  form {
    margin-left: 30px;
    margin-right: 30px;
  }

  main {
    display: flex;
    flex-direction: row;
  }
`;

export const InfoOrder = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #e8e8e8 !important;
  border-radius: 5px !important;
  padding: 5px 10px;
  border: solid 1px #d0d0d0;
  div {
    p {
      font-weight: 700;
      white-space: nowrap;
    }
  }

  > :first-child {
    border-right: 1px solid #d0d0d0;
    text-align: center;
    padding: 0 10px;
    strong {
      font-size: 18px;
    }

    @media screen and (max-width: 665px) {
      border: none;
    }
  }

  > :first-child + div {
    border-right: 1px solid #d0d0d0;
    text-align: center;
    padding: 0 10px;
    strong {
      font-size: 18px;
    }

    @media screen and (max-width: 665px) {
      border: none;
    }
  }

  > div + div + div {
    width: 100% !important;
    margin: 0 10px;
    border: none;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-left: 3px;
  }

  @media screen and (max-width: 665px) {
    flex-direction: column;
  }
`;

export const StyleButton = styled.div`
  button {
    border: none;
  }
  p {
    padding-left: 10px;
    font-weight: bold;
    // margin-bottom: 5px!important;
  }
  div {
    margin-left: 55px;
    margin-bottom: 5px;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 5%;
  top: 67%;

  border: 1px solid #221c46;

  width: 450px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 101;

  @media (max-width: 700px) {
    width: 90% !important;
    margin-top: 270px;
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 765px) {
    top: 135%;
  }

  @media screen and (max-width: 865px) {
    top: 117%;
  }
`;

export const CreateFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  div {
    width: 120px;
  }
`;

export const FormTax = styled.div`
  width: auto;
  // padding: 6px 5px;
  margin-bottom: 20px;
  border: 1px solid #dbdbdb;
  /* border-radius: 10px; */
  display: block;
  margin: 0 5px;
  margin-bottom: 10px;

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;

    ::-webkit-scrollbar-track {
      background-color: #f4f4f4;
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: #f4f4f4;
    }
    ::-webkit-scrollbar-thumb {
       /* border-radius: 5px; */
      background: #221c46;
    }

    table {
      color: #221C46;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      position: relative;
      overflow-x: scroll;
      overflow-y: auto;

      thead {
        text-align: center;

        tr {
          .disponivel {
            width: 55px;
          }

          .produto {
            width: 130px;
          }
          .prazo {
            width: 75px;
          }
          .quantidade {
            width: 75px;
          }
          .precoRef {
            width: 95px;
          }
          .precoNegociado {
            width: 85px;
          }
          .precoFrete {
            width: 85px;
          }
          .precoFinalSemTaxa {
            width: 85px;
          }
          .precoFinalComTaxa {
            width: 85px;
          }
          .TotalSemTaxa {
            width: 115px;
          }
          .TotalComTaxa {
            width: 115px;
          }
        }
      }

      tbody {
        text-align: center;
        tr {
          td {
            padding: 2px;
            word-break: break-word;
            strong {
              white-space: nowrap;
            }
            > input {
              margin: auto;
            }
          }
        }
      }
    }
  }
  header {
    width: auto;
    height: 90px;
    position: relative;
    margin-bottom: 5px;
        /* border-radius: 10px 10px 0 0; */
    display: flex;
    justify-content: flex-start;

    div {
      width: auto;
      color: #221C46;
      margin: 0 5px;
      h1 {
        color: #221C46;
        margin-right: auto;
        padding-left: 20px;
        font-size: 17px;
        margin-top: 7px;
      }

      h1 {
        white-space: nowrap !important;
      }

      @media (max-width: 700px) {
        h1 {
          white-space: break-spaces !important;
          text-align: center;
          padding-left: 0px;
          font-size: 15px;
        }
      }

      div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;

        input:first-child {
          margin-right: 6px;
        }

        label {
          margin: 0;
          padding: 0;
          cursor: pointer;
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }

    h3 {
      position: absolute;
      right: 120px;
    }
  }
`;

export const HeaderInfo = styled.div`
  width: 100% !important;
  display: flex;
  flex-direction: row;
  /* justify-content: flex-start; */
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  position: relative;

  div {
    display: block !important;

    input {
      text-align: center;
    }

    p {
      white-space: nowrap;
      padding: 0;
    }
  }

  div + div {
    height: 55px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px !important;
    margin-left: 2% !important;
    white-space: nowrap !important;
    p {
      padding: 20px !important;
      margin-bottom: 10px;
    }

    h2 {
      color: #221C46;
      margin: auto;
    }
  }

  div + div + div {
    width: 100%;

    display: flex !important;
    align-items: flex-end !important;
    justify-content: center !important;

    padding-right: 5px;
  }
`;

export const TitleTax = styled.strong``;

export const SelectMotivos = styled.div`
   select {
      width: 100% !important;
      /* border-radius: 5px; */
      font-family: sans-serif;
      padding: 7px;
      font-size: 15px;
    }

    select option { padding:5px 0px; }
`;

export const HeaderOrder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`;

export const InfoMotoristaContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 5%;
  top: 47%;

  border: 1px solid #221c46;

  width: 450px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 101;

  @media (max-width: 700px) {
    width: 90% !important;
    margin-top: 170px;
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 765px) {
    top: 135%;
  }

  @media screen and (max-width: 865px) {
    top: 117%;
  }
`;


export const PrazoGrid = styled.div`
  display: grid;
  grid-template-columns: 30% 30% 30% ;
  gap: 5px;
  margin: 10px 0;
  height: 40px;

  b {
    font-size: 12px
  }

  li {
    align-self: center;
    justify-self: center;
    font-size: 14px;
  }
`;


export const ProdutoGrid = styled.div`
  display: grid;
  grid-template-columns: 24% 24% 24% 24% ;
  gap: 1$;
  margin: 10px 0;
  /* height: 40px; */

  b {
    font-size: 12px
  }

  li {
    align-self: center;
    justify-self: center;
    font-size: 14px;
    margin-bottom: 10px;
  }


`;

import styled from "styled-components";

export const Container = styled.div`
  height: 88vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    height: 30%;
    width: 30%;
    object-fit: contain;
  }

  h2 {
    margin-top: 15px;
    color: #B30000;
    text-align: center;
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 80%;
    }
  }
`;

import React, { useEffect, useState, useCallback} from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import moment from "moment";

import AsyncListSelect from "../../components/ListSelect/AsyncListSelect";
import { Search } from "./styles";

import { FormattedMessage } from "react-intl";


const SodexoFaturamento = ({ dataFilter }) => {

const timeoutRef = React.useRef(null);

  const [loadingList, setLoadingList] = useState(true);

  const [dataDeState, setDataDeState] = useState("");


  const [labels, setLabels] = useState([]);
  const [graph, setGraph] = useState([]);
  let loadGraph = {
    series: graph,
    options: {
      chart: {
        type: "line",
        height: 300,
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: true,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 4,
            maximumFractionDigits: 4
          })
        },
      },
      colors: ["#FE2E2E", "#FE9A2E", "#FACC2E", "#C8FE2E", "#64FE2E",
      "#2EFE64", "#00FF80", "#00FFBF", "#01A9DB", "#045FB4",
      "#2E2EFE", "#2F0B3A", "#5F04B4", "#FE2EF7",
      "#DF0174", "#585858", "#BDBDBD"],
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
      grid: {
        padding: {
          left: 45,
        },
      },
      stroke: {
        curve: "smooth",
        width: 3,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return "R$ " + (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4
            })
          }
        }
      },
    },
  };

  const loadGrupo = useCallback(async (dataDeState, e) => {
    try {
      const dataDeRandom = moment()
      .subtract(2, "months")
      .format("YYYY-MM-DD 00:00:00");
      const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
      const dataAte = moment().format("YYYY-MM-DD 23:59:59");


      let grupoFormatted = [];

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      if (e !== "") {
        const grupo = () => new Promise((resolve, reject) => {
          timeoutRef.current = setTimeout(async ()=> {
            timeoutRef.current = null;

            const { data: response } = await api.get("/utilitarios/get-grupos-economicos-faturado",
            {
              params: {
                data_de: !dataFilter?.dataDeFilter
                  ? dataDe
                  : dataFilter?.dataDeFilter + " 00:00:00",
                data_ate: !dataFilter?.dataAteFilter
                  ? dataAte
                  : dataFilter?.dataAteFilter + " 23:59:59",

              },
            }
          );

          grupoFormatted = response.map((grupo) => {
            const objeto = {
              value: grupo.grupo_economico,
              label: grupo.grupo_economico,
            };

            return objeto;
          });

            resolve(grupoFormatted)

          }, 1000);

        }).then(
          resolve => {
            return resolve
          }
        ).catch(
          reject => {
            console.log(reject)
          }
        )

        await grupo()

        return new Promise((resolve) => {
          return resolve(
            grupoFormatted?.filter((i) =>
              i?.label?.toLowerCase().includes(e?.toLowerCase())
            )
          );
        });
      } else {
        const { data: response } = await api.get("/utilitarios/get-grupos-economicos-faturado",
        {
          params: {
            data_de: !dataFilter?.dataDeFilter
              ? dataDe
              : dataFilter?.dataDeFilter + " 00:00:00",
            data_ate: !dataFilter?.dataAteFilter
              ? dataAte
              : dataFilter?.dataAteFilter + " 23:59:59",
          },
        }
      );

      grupoFormatted = response.map((grupo) => {
        const objeto = {
          value: grupo.grupo_economico,
          label: grupo.grupo_economico,
        };

        return objeto;
      });

      return new Promise((resolve) => {
        return resolve(
          grupoFormatted?.filter((i) =>
            i?.label?.toLowerCase().includes(e?.toLowerCase())
          )
        );
      });

      }
    } catch (error) {}
  }, [dataFilter]);

  useEffect(() => {
    (async () => {
      try {

        setDataDeState(dataFilter?.dataDeFilter)

        loadGrupo()

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, loadGrupo]);



    const handleFilter = useCallback(async (e) => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const { data: response } = await api.get(
          `/dashboard/precos-medios-litros-produtos-distribuidor`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter,
              grupo_economico: e.value,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

      const dist = Object.keys(response);

      let formattedListData = [];
      dist.map((item) => {
        let data = [];
        let name = [];
        response[item].forEach((i) => {
          data.push(i.preco_medio_litros);
          name.push(i.nome_produto)
        });

        return formattedListData.push({ name: name[0], data: data });
      });

      setGraph(formattedListData);

      let formattedListLabel = [];
      dist.map((item) => {
        let label = [];
        response[item].forEach((i) => {
          const date = i.data_group.split("-");
          label.push(
            format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
              locale: ptBR,
            })
          );
        });

        return formattedListLabel.push({ label });
      });

        setLabels(formattedListLabel[0].label);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
  }, [dataFilter])

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoDistPrecosMedios" />
            </PanelHeader>
            <PanelBody className="p-0">

            <Search>
              <AsyncListSelect
                key={dataDeState}
                placeholder={"Selecione um Grupo Econômico para carregar o gráfico"}
                onChange={(e) => {
                  handleFilter(e)
                }}
                loadOptions={(e) => {
                  return loadGrupo(dataDeState, e);
                }}
                cacheOptions
                defaultOptions
              />
            </Search>

              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="400px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default SodexoFaturamento;

import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 400px;

  position: relative;

  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-bottom: 10px;

    nav {
      display: flex;

      h2 {
        margin: 0;
        padding: 0;
        margin-left: 15px;
        text-align:center;
      }
    }

    div {
      display: flex;
      width: 200px;

      align-items: center;
      justify-content: center;

      button {
        height: 30px;
        width: 30px;
        /* margin: 5px; */
        margin-left: 5px;
        margin-right: 5px;
            /* border-radius: 50%; */
        border: none;
        outline: none;
        color: #fff;
        transition: all 0.2s;
      }

      button:last-child {
        margin: 0 5px;
        cursor: pointer;
        transition: all 0.4s;
        background-color: transparent;
        border-radius: 0;

        img {
          height: 30px;
          width: 30px;
        }


      }
    }
  }

  main {
    height: 450px;
    overflow: hidden;
    overflow-y: auto;
    table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
      position: relative;

      thead {
        tr {
          th {
            height: 40px;
            text-align: left;
            padding: 8px;
            position: -webkit-sticky;
            position: sticky;
            background-color: #221c46;
            color: #fff;
          }
          th:last-child {
            text-align: center;
            transition: width 1.5s ease, max-width 1.5s ease;
          }
        }
      }

      tbody {
        td {
          border: 1px solid #dddddd;
        }

        tr {
          height: 45px;
          background-color: #FCECEA;

          td {
            padding-left: 10px;
          }
        }

        tr:disabled {
          background-color: grey;
        }

        tr:nth-child(even) {
          background-color: #fff;
        }

        td:last-child {
          position: relative;
          padding: 0;
        }
      }

      th {
        top: 0;
        z-index: 5;
        background: #fff;
        border: 1px solid #dddddd;
      }
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;

  right: 9%;
  top: 10%;

  border: 1px solid #221c46;

  width: 450px !important;
  height: auto;

/* border-radius: 10px; */
  background-color: #fff;
  z-index: 101;

  > header {
    width: 100%;
    height: 45px;
    background-color: #221c46;
        /* border-radius: 10px 10px 0 0; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 0 10px;

    h4 {
      margin: auto 0;
      margin-left: 5px;
    }
    svg {
      width: 24px;
      height: 24px;
      transition: all 0.4s;
      color: #fff;

      :hover {
        color: #e05262;
      }
    }
  }

  main {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100% !important;

    padding: 0 15px;
    margin-top: 10px;
  }

  footer {
    width: 100%;
    margin-bottom: 10px;
    padding: 0 15px;
  }

  @media screen and (max-width: 530px) {
    top: 20%;
    left: 5%;
  }

  @media screen and (max-width: 765px) {
    top: 17%;
  }
`;

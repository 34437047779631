import styled from "styled-components";
import InputMask from "react-input-mask";

export const Container = styled.div``;

export const Input = styled(InputMask)`
  height: 45px;
  width: 100%;

  border: solid 1px #d9e0e7;
  /* border-radius: 5px; */
  padding-left: 15px;

  margin: 5px auto;
  color: #707478;
  outline: none;
  :focus {
    border: solid 1px #707478;
    background-color: #f8f8f8;
  }
`;

export const Error = styled.span`
  font-size: 12px;
  font-weight: bold;
  color: #221c46!important;
`;

import React, { memo, useState, useCallback } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Container, OpenOption } from "./styles";


const OptionsList = ({ children, style }) => {
  const [showOption, setShowOption] = useState(false);

  const handleShowOption = useCallback(() => {
    setShowOption(!showOption);
  }, [showOption]);

  return (
    <Container>
      {showOption ? (
        <OpenOption style={style}>
          <aside>
            <FiChevronRight onClick={handleShowOption} />
          </aside>
          <ul>{children}</ul>
        </OpenOption>
      ) : (
        <div className="fa fa-ellipsis-h fa-2x" onClick={handleShowOption} />
      )}
    </Container>
  );
};

export default memo(OptionsList);

import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  p {
    margin-bottom: 0px !important;
    padding-left: 10px;
    font-weight: bold;
  }
`;

export const MessageError = styled.div`
  width: 100%;
  height: 100%;
  display: block;

  align-items: center;
  height: 10px !important;
  transition: all 0.7s;
  margin-top: -10px !important;
  margin-bottom: 10px;

  p {
    width: max-content;
    height: 100%;
    font-size: 11px;
    color: #221c46;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
    width: max-content;
  }
`;

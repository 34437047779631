import React, {
  useState,
  useCallback,
  useContext,
  useEffect,
  memo,
} from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FormattedMessage } from "react-intl";

import LanguageContext from "./../../../context/language";

const DropdownLanguage = () => {
  const { language, handleSetLanguage } = useContext(LanguageContext);

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [flag, setFlag] = useState(language);

  useEffect(() => {
    if (language === "en") {
      setFlag("flag-icon-us");
    } else if (language === "pt_BR") {
      setFlag("flag-icon-br");
    } else if (language === "es") {
      setFlag("flag-icon-es");
    } else {
      setFlag("pt_BR");
    }
  }, [language]);

  const toggle = useCallback(() => {
    setDropDownOpen(!dropDownOpen);
  }, [dropDownOpen]);

  return (
    <Dropdown
      isOpen={dropDownOpen}
      toggle={toggle}
      className="dropdown navbar-languager"
      tag="li"
    >
      <DropdownToggle
        className="dropdown-toggle"
        tag="a"
        style={{ cursor: "pointer" }}
      >
        <span className={`flag-icon ${flag} m-r-5`} title="us"></span>
        <span className="name d-none d-sm-inline"></span>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
        <DropdownItem onClick={() => handleSetLanguage("en")}>
          <span className="flag-icon flag-icon-us m-r-5" title="us"></span>
          <FormattedMessage id="ingles" />
        </DropdownItem>
        <DropdownItem onClick={() => handleSetLanguage("pt_BR")}>
          <span className="flag-icon flag-icon-br m-r-5" title="br"></span>
          <FormattedMessage id="portugues" />
        </DropdownItem>
        <DropdownItem onClick={() => handleSetLanguage("es")}>
          <span className="flag-icon flag-icon-es m-r-5" title="es"></span>
          <FormattedMessage id="espanhol" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default memo(DropdownLanguage);

import React, { useCallback, useState, useRef, useContext } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import InputLabel from "./../../../../components/InputLabel";
import api from "./../../../../services/api";
import { Container, CreateForm, CreateFooter, CheckForm } from "./styles";
import Button from "./../../../../components/ButtonDefault";
import AsyncListSelect from "../../../../components/ListSelect/AsyncListSelect";
import AuthContext from "../../../../context/auth";

export const CreateOrders = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const carregandolaceholder = intl.formatMessage({ id: "carregando" });

  const [distribuidor, setDistribuidor] = useState("");
  const [distribuidores, setDistribuidores] = useState([]);
  const [preco] = useState();

  const [basesDistribuicao, setBaseDistribuicao] = useState([
    { value: "selecione", label: <FormattedMessage id="selecione" /> },
  ]);


  const [listaBases, setListaBases] = useState("");

  const { user } = useContext(AuthContext);

  const [file, setFile] = useState();
  const [errors, setErrors] = useState([]);

  const history = useHistory();

  const refInput = useRef();

  const handleCreate = useCallback(
    async (e) => {

      setLoadingButton(true);

      let formData = new FormData();
      formData.append("file", file);

      try {
        await api.post("/frete-excel/importar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        toast.success(<FormattedMessage id="arquivoImportadoComSucesso" />);

        setFile([]);
        document.getElementById("file").value = "";
        setLoadingButton(false);
      } catch (error) {
        setFile([]);
        document.getElementById("file").value = "";
        console.log(error);
        setLoadingButton(false);
        setErrors(error?.response?.data)
        toast.error(<FormattedMessage id="erroImportarRegistro" />);
      }
    },
    [file]
  );

  const handleChangeBase = useCallback((item) => {
    if (!item || item?.length === 0) {
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
      const basesDistribuicao = item.filter(
        (base) => base.value !== "selecione"
      );

      setBaseDistribuicao(basesDistribuicao);
    }
  }, []);

  const handleLoadBases = useCallback(async () => {
      if (!distribuidor.value) {
        return;
      }

      try {
        const { data: response } = await api.get(`/cadastro/${distribuidor.value}`);

        const arr = response?.base_distribuicao.map((base) => {
          const obj = {};

          Object.assign(obj, {
            label: base.nome,
            value: base.id,
          });

          return obj;
        });

        return arr;
      } catch (error) {}
    },
    [distribuidor]
  );

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleDownload = useCallback(async () => {
    if (user.cadastro_tipo_id === 1) {

      if (!distribuidor) {
        toast.error("Selecione um Distribuidor/TRR");
        return;
      }

      if (basesDistribuicao?.value === "selecione") {
        toast.error("Selecione pelo menos uma Base de Distribuição");
        return;
      }


      let bases = [];
      basesDistribuicao?.map((base) => {
        bases.push(base.value)
      });

      const data = {
        cadastros: [{
          cadastro_id: distribuidor.value,
          basesdisbuicao_ids: bases
        }]
      }

      await api
      .post(`/frete-excel/modelo-sodexo`, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PrecosFretes.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } else {
      if (!distribuidores) {
        toast.error("Selecione um Distribuidor/TRR");
        return;
      }

      let formattedDistribuidores = distribuidores?.map((distribuidor) => {

        let bases = [];
        distribuidor?.bases.map((base) => {
          bases.push(base.value)
        });

        return {
          cadastro_id: distribuidor.value,
          basesdisbuicao_ids: bases
        };
      });

      const data = {
        cadastros: formattedDistribuidores
      }

      await api
      .post(`/frete-excel/modelo-distribuidor`, data, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PrecosFretes.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    }

  }, [distribuidores, distribuidor, basesDistribuicao]);

  const handleChangeDistribuidor = useCallback((item) => {
    if (!item || item?.length === 0) {
      setBaseDistribuicao([
        { value: "selecione", label: <FormattedMessage id="selecione" /> },
      ]);
    } else {
        const distribuidores = item.filter(
          (distribuidor) => distribuidor.value !== "selecione"
        );

        setDistribuidores(distribuidores);
    }

  }, [distribuidores]);

  const handleLoadDistribuidores = useCallback(async () => {
    try {
      const { data: response } = await api.get(
        `usuario/filter-user-cadastro`,
        {
          params: {
            email: user.email,
            cadastro_tipo_id: user.cadastro_tipo_id,
            paginate: 500
          },
        }
      );

      const arr = response.data?.map((distribuidor) => {
        let formattedBases = distribuidor?.cadastros[0].base_distribuicao?.map((base) => {
          let obj2 = {};
          Object.assign(obj2, {
              value: base.id,
              label: base.nome,
              checked: true
          });
          return obj2
        });


        const obj = {};

        Object.assign(obj, {
          label: distribuidor.cadastros[0].cpf_cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            "$1.$2.$3/$4-$5"
          ) + " - " + distribuidor.cadastros[0].razao_social,
          value: distribuidor.cadastros[0].id,
          bases: formattedBases,
        });

        return obj;
      })

      return arr

    } catch (error) {
      // setLoadingList(false)
    }
  }, []);

const handleChangeBaseDistribuidor = useCallback((e, indexDistribuidor, indexBase) => {
  distribuidores[indexDistribuidor].bases[indexBase].checked = e;

  setDistribuidores([...distribuidores])

}, [distribuidores]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarPrecoFrete" />
          </h4>
        </header>

        <div style={{ marginLeft: "30px", marginRight: "30px" }}>

          {user.cadastro_tipo_id === 1 && (
            <>
               <AsyncListSelect
               labelPlaceholder={<FormattedMessage id="distribuidor" />}
               placeholder={<FormattedMessage id="selecionar" />}
               onChange={(e) => {
                 setDistribuidor(e)
                 setListaBases(e?.value);
                 setBaseDistribuicao({ value: "selecione", label: <FormattedMessage id="selecione" /> });
               }}
               value={distribuidor}
               myOptions={"distribuidores"}
               cacheOptions
               defaultOptions
             />

             <AsyncListSelect
              key={listaBases}
              labelPlaceholder={<FormattedMessage id="basesDistribuicao" />}
              placeholder={"Selecione"}
              isMulti
              isSearchable
              cacheOptions
              defaultOptions
              loadOptions={() => {
               return handleLoadBases(listaBases);
             }}
              value={basesDistribuicao}
              onChange={handleChangeBase}
              noOptionsMessage={() => (
                "Selecione um Distribuidor/TRR para carregar"
              )}
             loadingMessage={() => carregandolaceholder}
             />
           </>
          )}

          {user.cadastro_tipo_id === 2 && (
            <>
              <AsyncListSelect
               labelPlaceholder={<FormattedMessage id="distribuidores" />}
               placeholder={<FormattedMessage id="selecionar" />}
               onChange={handleChangeDistribuidor}
               isMulti
               value={distribuidores}
               loadOptions={() => {
                return handleLoadDistribuidores();
              }}
               cacheOptions
               defaultOptions
             />

               {distribuidores?.map((distribuidor, indexDistribuidor)=> (
                  <div key={distribuidor.value}>
                     <CheckForm>
                        <div>
                          <p>{distribuidor.label}</p>
                        </div>

                        <main>
                          {distribuidor.bases.map((base, indexBase) => (
                             <div key={base.value}>
                                <input
                                  id={'base'[base.value]}
                                  type="checkbox"
                                  value={base.checked}
                                  onChange={(e) => {
                                    handleChangeBaseDistribuidor(e.target.checked, indexDistribuidor, indexBase);
                                  }}
                                  checked={base.checked}
                                />
                                <label>{base.label}</label>
                            </div>
                          ))}

                        </main>
                      </CheckForm>
                    </div>
                  ))
               }
            </>
          )}
        </div>

        <form>
          <InputLabel
            id={"file"}
            ref={refInput}
            name="preco"
            type="file"
            label={<FormattedMessage id="precosFrete" />}
            value={preco}
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />

          <br />

          <div style={{ width: "160px" }}>
            <Button type="button" onClick={handleDownload} style={{fontSize: "11.5px"}}>
              <FormattedMessage id="exportarTemplateFrete" />
            </Button>
          </div>
        </form>

        <br />

        {errors.length > 0 && (
         <table id="table-to-xls">
              <thead>
                <tr>
                  <th>
                    Mensagem de erro
                  </th>
                  <th>
                    Linha
                  </th>
                </tr>
              </thead>

              {errors.map((error) => (
               <tbody>
                 <tr>
                   <td>{error.mensagem}</td>
                   <td>{error.linha}</td>
                 </tr>
               </tbody>
              ))}

            </table>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button onClick={handleCreate} loading={loadingButton}>
              <FormattedMessage id="enviar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateOrders);

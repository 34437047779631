import React from "react";
import { Container } from "./styles";
import Loader from "./../../../assets/images/loader.svg";


const Content = ({ children, style }) => {
  return (
    <Container style={style}>
      <img src={Loader} alt="loader" />
      {children}
    </Container>
  );
};

export default Content;

import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect/index";
import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

export const ReportCustomerRegister = ({ intl }) => {
  const [dateDe, setDateDe] = useState("");
  const [dateAte, setDateAte] = useState("");
  const [cliente, setCliente] = useState("");
  const [status, setStatus] = useState("");
  // const [saida, setSaida] = useState("");
  const [perfil, setPerfil] = useState("");
  const [matriz, setMatriz] = useState("");
  const [grupo, setGrupo] = useState("");
  const [classificação, setClassificação] = useState("");

  const [checkMatriz, setCheckMatriz] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const matrizOptions = [
    { value: "1", label: <FormattedMessage id="opcaoSim" /> },
    { value: "0", label: <FormattedMessage id="opcaoNao" /> },
  ];

  const perfilOptions = [
    { value: "Público", label: <FormattedMessage id="publico" /> },
    { value: "Privado", label: <FormattedMessage id="privado" /> },
  ];

  const statusOptions = [
    { value: "Ativo", label: <FormattedMessage id="ativo" /> },
    { value: "Inativo", label: <FormattedMessage id="inativo" /> },
  ];

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    const checkedStatus = !status?.value ? "" : status?.value;
    const checkedCliente = !cliente?.value ? "" : cliente?.value;
    const checkedPerfil = !perfil?.value ? "" : perfil?.value;
    const checkedClassificacao = !classificação?.value ? "" : classificação?.value;
    const checkedMatriz = !matriz?.value ? "" : matriz?.value;
    const checkMatrizOption = !checkMatriz?.value ? "" : checkMatriz?.value;


    const data = {
      dataDe: dateDe || "",
      dataAte: dateAte || "",
      filtroCliente: checkedCliente,
      grupoEconomico: grupo,
      perfil: checkedPerfil,
      classificacaoCliente: checkedClassificacao,
      dadosEmpresaMatriz: checkedMatriz,
      status: checkedStatus,
      saida: "excel",
      matriz: checkMatrizOption
    };

    try {

        await api
          .get(`/relatorio/cadastro-clientes`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Relatorio-Cadastro-Clientes.xlsx");
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          });

      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [
    classificação,
    cliente,
    dateAte,
    dateDe,
    grupo,
    matriz,
    perfil,
    status,
    checkMatriz
  ]);

  const loadEmpresasMatriz = useCallback(async (inputValue) => {
    try {

      const { data: response } = await api.post("/cadastro/filter", {
        cadastro_tipo_id: 3,
        matriz: 1,
        razao_social: inputValue

      });

      const empresasFormatted = response.data.map((empresa) => {
        const objeto = {
          value: empresa.id,
          label: empresa.cpf_cnpj + " - " + empresa.razao_social,
        };

        return objeto;
      });

      return empresasFormatted;
    } catch (error) {}
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioCadastroCliente" />
          </h4>
        </header>

        <form>
          <div className="sideBySide">
            <InputLabel
              name="date"
              label={"Data Cadastro Inicio"}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dateDe}
              onChange={(e) => setDateDe(e.target.value)}
            />

            <AsyncListSelect
              value={cliente}
              labelPlaceholder={<FormattedMessage id="cliente" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setCliente(e);
              }}
              myOptions={"clientes"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <ListSelect
              labelPlaceholder={<FormattedMessage id="perfil" />}
              placeholder={<FormattedMessage id="todos" />}
              cacheOptions
              defaultOptions
              options={perfilOptions}
              onChange={(e) => setPerfil(e)}
              value={perfil}
              isClearable
            />

            <ListSelect
              labelPlaceholder={<FormattedMessage id="matriz" />}
              placeholder={<FormattedMessage id="selecione" />}
              cacheOptions
              defaultOptions
              options={matrizOptions}
              onChange={(e) => {
                setCheckMatriz(e);
                setMatriz("");
              }}
              value={checkMatriz}
              isClearable
            />

            <ListSelect
              labelPlaceholder={"Status"}
              placeholder={todosPlaceholder}
              cacheOptions
              defaultOptions
              options={statusOptions}
              onChange={(e) => setStatus(e)}
              value={status}
              isClearable
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={"Data Cadastro Fim"}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dateAte}
              onChange={(e) => setDateAte(e.target.value)}
            />

            <InputLabel
              name="grupo"
              label={<FormattedMessage id="grupoEconomico" />}
              maxLength={50}
              onChange={(e) => setGrupo(e.target.value)}
              value={grupo}
            />

            <AsyncListSelect
              value={classificação}
              labelPlaceholder={<FormattedMessage id="classificacao" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setClassificação(e);
              }}
              myOptions={"classificaoClientes"}
              defaultOptions
              isClearable
            />

            <AsyncListSelect
              value={matriz}
              labelPlaceholder={<FormattedMessage id="empresaMatriz" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setMatriz(e);
              }}
              loadOptions={loadEmpresasMatriz}
              cacheOptions
              defaultOptions
              isDisabled={checkMatriz?.value !== "0" ? true : false}
              isClearable
            />
          </div>
        </form>

        <br/>
        <br/>
        <br/>
        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="gerar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(ReportCustomerRegister);

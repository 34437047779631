import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import MaskInput from "./../../../config/input-mask";
import { FormattedMessage, injectIntl } from "react-intl";

import { toast } from "react-toastify";

import api from "./../../../services/api";

import { Container, CreateForm, CreateFooter, ContainerRole } from "./styles";

import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";
import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";

import { getErrorsApiValidation } from "../../../utils/getErrorsValidation";

export const CreateUser = ({ intl }) => {
  const [loadingButton, setLoadingButton] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [celular, setCelular] = useState("");
  const [telefone, setTelefone] = useState("");
  const [nivel, setNivel] = useState("");
  // const [roles, setRoles] = useState("");
  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });
  const [errors, setErrors] = useState();

  const history = useHistory();

  /** obter os dados do usuario logado */

  //Placeholders internacionalizados
  const passwordPlaceholder = intl.formatMessage({ id: "senha" });
  const passwordConfirmationPlaceholder = intl.formatMessage({
    id: "confirmacaoSenha",
  });
  const empresaPlaceholder = intl.formatMessage({ id: "empresa" });
  const nivelPlaceholder = intl.formatMessage({ id: "nivel" });
  const perfilPlaceholder = intl.formatMessage({ id: "perfil" });
  const statusPlaceholder = intl.formatMessage({ id: "status" });
  const nomePlaceholder = intl.formatMessage({ id: "nome" });

  const [userTipos, setUserTipos] = useState("");

  const [listaEmpresasSelecionadas] = useState("");
  const [empresas, setEmpresas] = useState([]);

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  useEffect(() => {
    async function loadUserTipos() {
      try {
        const { data: response } = await api.get("usuario/list-types");
        const levelOptions = [];
        const resp = response.userTipo;
        resp.forEach((tipouser) => {
          levelOptions.push({ value: tipouser.id, label: tipouser.nome });
        });
        setUserTipos(levelOptions);
      } catch (error) {}
    }
    loadUserTipos();
  }, []);

  const handleCreateUser = useCallback(async () => {
    setLoadingButton(true);
    if (password !== passwordConfirmation) {
      setLoadingButton(false);
      toast.error(<FormattedMessage id="asPasswordsNaoCorrespondem" />);
    } else {
      let empresaid = empresas.map(function (empresa) {
        return empresa.value;
      });

      empresaid = empresaid.filter((i) => i !== 0);

      //> *******************************************************
      let arrayRoleId = empresas.map((empresa) => {
        return empresa?.role;
      });
      //< *******************************************************

      const data = {
        cadastro: empresas === [] ? listaEmpresasSelecionadas : empresaid,
        name: name,
        email: email,
        celular: celular,
        telefone: telefone,
        tipo_id: nivel.value,
        roles: nivel.value === 1 ? "" : arrayRoleId,
        password: password,
        ativo: status.value,
        cadastro_tipo_id: 2
      };

      // var IdCreatedUser = "";

      try {
        // const { data: response } =
        await api.post("/usuario/store", data);

        // IdCreatedUser = response?.dados?.id;

        setEmpresas([{ value: 0, label: <FormattedMessage id="selecione" /> }]);
        setName("");
        setEmail("");
        setPassword("");
        setPasswordConfirmation("");
        setCelular("");
        setTelefone("");
        setNivel("");
        setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
        // setRoles("");

        setLoadingButton(false);
        setErrors(null);

        toast.success(<FormattedMessage id="usuarioCriadoComSucesso" />);
      } catch (error) {
        setLoadingButton(false);
        setErrors(getErrorsApiValidation(error));

        if (error.response.data.erro_email) {
          setEmpresas([
            { value: 0, label: <FormattedMessage id="selecione" /> },
          ]);
          setName("");
          setEmail("");
          setPassword("");
          setPasswordConfirmation("");
          setCelular("");
          setTelefone("");
          setNivel("");
          setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });

          setLoadingButton(false);
          setErrors(null);
          toast.success(<FormattedMessage id="usuarioCriadoComSucesso" />);
          toast.error("Erro ao enviar o e-mail");
          return;
        }

        toast.error(<FormattedMessage id="erroDeCriarUsuario" />);
      }
    }
  }, [
    empresas,
    listaEmpresasSelecionadas,
    nivel,
    name,
    email,
    password,
    passwordConfirmation,
    celular,
    telefone,
    status,
  ]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  //> ***********************************************************************
  /** Para carregar os perfis cadastrados */
  const loadPerfis = useCallback(async (id) => {
    try {
      /** Se usuario logado for Sodexo listará todas as Empresas. Se não listará somente os tipos de empresas do tipo cadastro logado */
      var response;

      response = await api.post(`/role/filter`, {
        cadastro_id: `${id}`,
        ativo: 1,
      });

      var resp;
      resp = response.data.data;

      const perfis = resp.map((perfil) => {
        const objeto = {
          value: perfil.roleId,
          label: perfil.label,
        };
        return objeto;
      });

      return perfis;
    } catch (error) {
      console.log(error);
    }
  }, []);

  //< ***********************************************************************

  const handleChangeEmpresa = useCallback((item) => {
    if (!item || item?.length === 0) {
      setEmpresas([]);
    } else {
      if (item) {
        const empresas = item.filter((empresa) => empresa.value !== 0);
        setEmpresas(empresas);
      }
    }
  }, []); // teste

  //> ***********************************************************************
  const handleChangeRole = useCallback(
    (e, index) => {
      empresas[index].role = e.value;
      setEmpresas([...empresas]);
    },
    [empresas]
  );
  //< *************************************************************************

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="criarNovoUsuario" /> Distribuidor/TRR
          </h4>
        </header>

        <form>
          <AsyncListSelect
            labelPlaceholder={empresaPlaceholder}
            required={true}
            isMulti
            cacheOptions
            defaultOptions
            myOptions={"distribuidores"}
            value={empresas}
            onChange={handleChangeEmpresa}
            geterror={errors?.cadastro}
            placeholder={<FormattedMessage id="selecionar" />}
          />
          <InputLabel
            name="name"
            type="text"
            label={nomePlaceholder}
            required={true}
            onChange={(e) => setName(e.target.value)}
            value={name}
            geterror={errors?.name}
            maxLength={100}
          />
          <InputLabel
            name="email"
            type="text"
            label={"E-mail *"}
                  required={true}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            geterror={errors?.email}
            maxLength={80}
          />
          <InputLabel
            name="celular"
            label={<FormattedMessage id="celular" />}
            type="text"
            mask={MaskInput.cellphone}
            onChange={(e) => {
              return setCelular(e.target.value.replace(/[^\d]+/g, ""));
            }}
            value={celular}
          />
          <InputLabel
            name="telefone"
            label={<FormattedMessage id="telefone" />}
            type="text"
            mask={MaskInput.phone}
            onChange={(e) => setTelefone(e.target.value.replace(/[^\d]+/g, ""))}
            value={telefone}
          />
          <ListSelect
            labelPlaceholder={nivelPlaceholder}
            required={true}
            onChange={(e) => {
              setNivel(e);
              // setRoles("");
            }}
            options={userTipos}
            placeholder={<FormattedMessage id="selecionar" />}
            cacheOptions
            defaultOptions
            value={nivel}
            geterror={errors?.tipo_id}
          />

          {/* ********************************************************************** */}
          {nivel.value !== 1 && nivel.value !== undefined && (
            <ContainerRole gridColumn={empresas.length}>
              {empresas.length === 0 && (
                <h5>
                  <FormattedMessage id="selecioneEmpresaParaInserirPerfil" />
                </h5>
              )}
              {empresas.map((item, index) => {
                let empresaNameArray = item.label;

                var str = empresaNameArray.toString();
                let empresaName = str.split(" - ");

                if (empresaName[1]) {
                  empresaName = empresaName[1];
                } else {
                  empresaName = "";
                }

                return (
                  <AsyncListSelect
                    key={index}
                    labelPlaceholder={`${perfilPlaceholder}: ${empresaName}`}
                    onChange={(e) => {
                      return handleChangeRole(e, index);
                    }}
                    loadOptions={() => loadPerfis(item.value)}
                    isDisabled={nivel.value === 1 ? true : false}
                    cacheOptions
                    placeholder={<FormattedMessage id="selecionar" />}
                    defaultOptions
                    value={empresas?.role}
                    geterror={errors?.roles}
                  />
                );
              })}
            </ContainerRole>
          )}
          {/* ********************************************************************** */}

          <InputLabel
            name="password"
            type="password"
            label={passwordPlaceholder}
            required={true}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            maxLength={20}
            geterror={errors?.password}
          />
          <InputLabel
            name="password_confirmation"
            type="password"
            label={passwordConfirmationPlaceholder}
            required={true}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
            value={passwordConfirmation}
            maxLength={20}
          />
          <ListSelect
            labelPlaceholder={statusPlaceholder}
            onChange={(e) => setStatus(e)}
            options={statusOptions}
            value={status}
          />
        </form>
        <hr />
        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="submit"
              onClick={handleCreateUser}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateUser);

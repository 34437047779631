import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import InputLabel from "./../../../components/InputLabel";

import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";

import Button from "./../../../components/ButtonDefault";

import AsyncListSelect from "../../../components/ListSelect/AsyncListSelect";
import { toast } from "react-toastify";

// Relatório de preço médio
export const Saving = ({ intl }) => {
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");

  const [cliente, setCliente] = useState("");
  const [distribuidor, setDistribuidor] = useState("");
  const [produto, setProduto] = useState([]);
  const [baseDistribuicao, setBaseDistribuicao] = useState("");

  const [loadingButton, setLoadingButton] = useState(false);

  const history = useHistory();

  const todosPlaceholder = intl.formatMessage({
    id: "todos",
  });

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    let produtoFormmated;
    if (produto.length > 0) {
      produtoFormmated = produto.map((prod) => prod.value);
    }

    try {
      const data = {
        dataDe: dataInicial,
        dataAte: dataFinal,
        filtroCliente: cliente?.value || "",
        produto: produtoFormmated?.toString() || "",
        filtroDistribuidor: distribuidor?.value || "",
        baseDistribuicao: baseDistribuicao?.value || "",
        saida: "excel",
      };

        await api
          .get(`/relatorio/saving`, {
            params: data,
            responseType: "blob",
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Relatorio-Saving.xlsx");
            document.body.appendChild(link);
            link.click();

            toast.success(<FormattedMessage id="relatorioCriadoComSucesso" />);
          })
          .catch((err) => console.log(err));
      setLoadingButton(false);
    } catch (error) {
      setLoadingButton(false);
      console.log(error);

      toast.error(<FormattedMessage id="errorExportarRelatorio" />);
    }
  }, [baseDistribuicao, cliente, dataFinal, dataInicial, distribuidor, produto]);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="relatorioSaving" />
          </h4>
        </header>

        <form>
          <div className="sideBySide">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataInicio" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />

            <AsyncListSelect
              value={cliente}
              labelPlaceholder={<FormattedMessage id="cliente" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setCliente(e);
              }}
              myOptions={"clientes"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <AsyncListSelect
              value={baseDistribuicao}
              labelPlaceholder={<FormattedMessage id="baseDistribuicao" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setBaseDistribuicao(e);
              }}
              myOptions={"basesDistribuição"}
              cacheOptions
              defaultOptions
              isClearable
            />
          </div>

          <div className="sideBySideTwo">
            <InputLabel
              name="date"
              label={<FormattedMessage id="dataFim" />}
              type="date"
              style={{ color: "hsl(0,0%,50%)" }}
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />

            <AsyncListSelect
              value={distribuidor}
              labelPlaceholder={<FormattedMessage id="distribuidores" />}
              placeholder={todosPlaceholder}
              onChange={(e) => {
                setDistribuidor(e);
              }}
              myOptions={"distribuidores"}
              cacheOptions
              defaultOptions
              isClearable
            />

            <AsyncListSelect
              value={produto}
              labelPlaceholder={<FormattedMessage id="produto" />}
              placeholder={<FormattedMessage id="selecionar" />}
              onChange={(e) => {
                setProduto(e);
              }}
              myOptions={"produtos"}
              cacheOptions
              defaultOptions
              isMulti
            />
          </div>
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="gerar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(Saving);

import React, { useEffect, useState, useContext } from "react";
import Chart from "react-apexcharts";
import api from "../../services/api";
import ContentSpinner from "../../components/Spinner/Content";
import {
  Panel,
  PanelHeader,
  PanelBody,
} from "./../../components/panel/panel.jsx";

import { format } from "date-fns";

import moment from "moment";

import { FormattedMessage, injectIntl } from "react-intl";

import { ptBR, enAU, es } from "date-fns/locale";

import Language from "../../context/language";

const GraphReceita = ({ dataFilter, intl }) => {
  const [loadingList, setLoadingList] = useState(true);

  const [labels, setLabels] = useState([]);
  const [graphADM, setGraphADM] = useState([]);
  const [graphFI, setGraphFI] = useState([]);

  const { language } = useContext(Language);

  //Placeholders internacionalizados
  const taxaAdministrativaPlaceholder = intl.formatMessage({
    id: "taxaAdministrativa",
  });
  const taxaFinanceiraPlaceholder = intl.formatMessage({
    id: "taxaFinanceira",
  });

  let loadGraph = {
    series: [
      // {
      //   name: taxaMediaPlaceholder,
      //   data: graphSoma,
      // },
      {
        name: taxaAdministrativaPlaceholder,
        data: graphADM,
      },
      {
        name: taxaFinanceiraPlaceholder,
        data: graphFI,
      },
    ],
    options: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF7375", "#221C46"],
      dataLabels: {
        enabled: true,
        enabledOnSeries: undefined,
        formatter: function (val) {
          return (parseFloat(val)).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        },
        textAnchor: 'middle',
        style: {
            fontSize: '11px',
            fontWeight: 'bold',
            colors: undefined
        },
        background: {
          enabled: true,
          foreColor: '#fff',
          padding: 4,
          opacity: 0.9,
          zIndex: 1
        },
        dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: '#000',
            opacity: 0.45
        }
      },
      yaxis: {
        labels: {
          formatter: function(val, index) {
            return (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })
          }
        }
      },
      stroke: {
        curve: 'smooth',
        width: 3,
      },
      xaxis: {
        categories: labels,
      },
      grid: {
        padding: {
          left: 45,
        },
      },
      tooltip: {
        y: {
          show: true,
          formatter: function(val, { series, seriesIndex, dataPointIndex, w }) {
            return  (parseFloat(val)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }) + "%"
          }
        },
      },
    },
  };

  useEffect(() => {
    (async () => {
      try {
        const dataDeRandom = moment()
        .subtract(2, "months")
        .format("YYYY-MM-DD 00:00:00");
        const dataDe = moment(dataDeRandom).startOf('month').format('YYYY-MM-DD 00:00:00');
        const dataAte = moment().format("YYYY-MM-DD 23:59:59");

        const dataADM = [];
        const dataFI = [];
        const labels = [];

        const response = await api.get(
          `/dashboard/precos-media-clientes`,
          {
            params: {
              data_de: !dataFilter?.dataDeFilter
                ? dataDe
                : dataFilter?.dataDeFilter + " 00:00:00",
              data_ate: !dataFilter?.dataAteFilter
                ? dataAte
                : dataFilter?.dataAteFilter + " 23:59:59",
              cpf_cnpj: dataFilter?.cnpjFilter
                ?.replaceAll(".", "")
                ?.replaceAll("/", "")
                ?.replaceAll("-", "")
                ?.replaceAll("_", ""),
              grupo_economico: dataFilter?.grupoEconomicoFilter,
              base_distribuicao_id: dataFilter?.bases?.value,
              municipio_id: dataFilter?.cidadeBasesDist?.value,
              estado_id: dataFilter?.estadoBasesDist?.value,
            },
          }
        );

        let setLanguage = ptBR;
        if (language === "en") {
          setLanguage = enAU;
        } else if (language === "es") {
          setLanguage = es;
        } else if (language === "pt_BR") {
          setLanguage = ptBR;
        }

        Object.values(response.data).map((item, index) => {
          const obj = {};
          const date = item.data_group.split("-");

          Object.assign(obj, {
            dataADM: dataADM.push(item.taxa_administrativa_porcentagem),
            dataFI: dataFI.push(item.taxa_financeira_porcentagem),
            labels: labels.push(
              format(new Date(date[0], date[1] - 1, 1), "MMMM/yyyy", {
                locale: setLanguage,
              })
            ),
          });
          return obj
        });

        // setGraphSoma(dataSoma);
        setGraphADM(dataADM);
        setGraphFI(dataFI);
        setLabels(labels);

        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
      }
    })();
  }, [dataFilter, language]);

  return (
    <>
      {loadingList ? (
        <ContentSpinner />
      ) : (
        <>
          <Panel>
            <PanelHeader>
              <FormattedMessage id="grupoGeralTaxasMediasClientesPorcentagem" />
            </PanelHeader>
            <PanelBody className="p-0">
              <Chart
                options={loadGraph.options}
                series={loadGraph.series}
                type="line"
                width="100%"
                height="300px"
                className="graph"
              />
            </PanelBody>
          </Panel>
        </>
      )}
    </>
  );
};

export default injectIntl(GraphReceita);

import React, { useContext } from "react";

import { Container, ToolTip } from "./styles";

import AuthContext from "./../../context/auth";

const ButtonImage = ({ ImageSrc, alt, placeholder, border, permission, ...rest }) => {
  const { user } = useContext(AuthContext);

  const checkPermission = user.permission.filter(
    (p) => p.namePermission === permission
  );

  if (permission) {
    if (user.tipo_id === 2 && checkPermission.length === 0) {
      return <></>;
    }
  }

  return (
    <Container {...rest} border={border}>
      {placeholder && <ToolTip>{placeholder}</ToolTip>}
      <img src={ImageSrc} alt={alt} />
    </Container>
  );
};

export default ButtonImage;

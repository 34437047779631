import React, { useContext } from "react";
import { FiLoader } from "react-icons/fi";

import { Container, ToolTip } from "./styles";
import AuthContext from "./../../../context/auth";

const IconDefault = ({
  loading = false,
  Icon,
  placeholder,
  permission,
  image,
  ...rest
}) => {
  const { user } = useContext(AuthContext);

  const checkPermission = user.permission.filter(
    (p) => p.namePermission === permission
  );

  if (permission) {
    if (user.tipo_id === 2 && checkPermission.length === 0) {
      return <></>;
    }
  }

  return (
    <Container {...rest} disabled={loading}>
      {loading ? (
        <FiLoader />
      ) : (
        <>
          {placeholder && <ToolTip>{placeholder}</ToolTip>}
          {image ? (
            <img src={Icon} alt={Icon} />
          ) : (
            <Icon />
          )}
        </>
      )}
    </Container>
  );
};

export default IconDefault;

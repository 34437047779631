import React, { createRef, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";

import ListSelect from "./../../../components/ListSelect";
import InputLabel from "./../../../components/InputLabel";
import CheckboxDefault from "./../../../components/CheckboxDefault";
import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import ContentSpinner from "./../../../components/Spinner/Content";

import api from "./../../../services/api";
import {
  Container,
  CreateForm,
  CreateFooter,
  ContainerOptions,
  ButtonSelect,
} from "./styles";
import Button from "./../../../components/ButtonDefault";
import { toast } from "react-toastify";
import swal from "sweetalert";


export const VersionCustomer = ({ intl }) => {

  const voceTemCerteza = intl.formatMessage({ id: "voceTemCerteza" });
  const opcaoSim = intl.formatMessage({ id: "opcaoSim" });
  const cancelar = intl.formatMessage({ id: "cancelar" });

  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loadingList, setLoadingList] = useState(true);
  const [customersList, setCustomersList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [version, setVersion] = useState([]);

  const versaoOptions = [
    { value: "v1", label: "Trade V1 – Modelo Reativo" },
    { value: "v2", label: "Trade V2 – Modelo Proativo" },
  ];

  const history = useHistory();

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);

  useEffect(() => {
    (async () => {
      setLoadingList(true);
      try {
        const { data: response } = await api.post("/cadastro/filter", {
          cadastro_tipo_id: 3,
          ativo: 1,
          limit: 1000
        });

        const formattedList = response.data.map((cliente) => {
          const obj = {};

          const cnpjLength = 14 - cliente.cpf_cnpj.length;
          let novoCnpj = cliente.cpf_cnpj;
          for (let index = 0; index < cnpjLength; index++) {
            novoCnpj += "0";
          }

          Object.assign(obj, {
            id: cliente.id,
            cnpj: cliente.cpf_cnpj,
            razaoSocial: cliente.razao_social,
            versao: cliente.versao_sistema === "v1" ? "Trade V1 – Modelo Reativo" : "Trade V2 – Modelo Proativo",
            selected: false,
            show: true
          });

          return obj;
        });

        setCustomersList(formattedList);
        setLoadingList(false);
      } catch (error) {
        setLoadingList(false);
        return () => <h3>Erro ao carregar a lista</h3>;
      }
    })();
  }, []);

  const handleSearch = (e) => {

    var newList = customersList.map((customer) => {
      if (customer.cnpj.includes(e) || (customer.razaoSocial.toLowerCase()).includes(e.toLowerCase())) {
        customer.show = true
      } else {
        customer.show = false
      }
      return customer
    })
    setCustomersList(newList)
  };

  const handleSelected = (index) => {
      customersList[index].selected = !customersList[index].selected;
      setCustomersList([...customersList])
  };

  const handleSelectedAll = () => {
    var newList = customersList.map((customer) => {
      if (customer.show === true) {
        customer.selected = true;
      }
      return customer
    })

    setCustomersList(newList)
  };

  const handleEdit = useCallback(async () => {
    if (version.label === undefined) {
      toast.error("Selecione uma versão.");
      return
    }

    setLoadingButton(true);

      const willDelete = await swal({
        title: voceTemCerteza,
        text:  `Deseja mesmo alterar o modo de operação de todos os clientes para a versão ` + version.label ,
        icon: "warning",
        buttons: {
          cancel: {
            text: cancelar,
            value: null,
            visible: true,
            closeModal: true,
            className: "teste",
          },
          confirm: {
            text: opcaoSim,
            value: true,
            visible: true,
            closeModal: true,
          },
        },
        dangerMode: true,
      });

      if (willDelete) {
        try {

          var cadastros = customersList.filter((customer) => customer.selected === true);

          var ids = [];
          cadastros.map((cadastro) => {
            ids.push(cadastro.id)
          })

          try {
            await api.put("/cadastro-settings", {
              ids: ids,
              versao_sistema: version?.value
            });

            toast.success("Versão editada com sucesso.");
            handleBackButton();
            setLoadingButton(false);


          } catch (error) {
            toast.error("Erro ao alterar versão.");
            setLoadingButton(false);

          }

        } catch (err) {
          toast.error("Erro ao processar operação.");
          loadingButton(false);
        }
      }
    },
    [voceTemCerteza, opcaoSim, cancelar, version]
  );


  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            Selecionar Versão Clientes
          </h4>
        </header>
        {loadingList ? (
          <ContentSpinner />
        ) : (
          <>
             <ContainerOptions>
              <main>
                <InputLabel
                  label={"Pesquisar"}
                  placeholder={"CNPJ ou Razão Social"}
                  type="text"
                  value={customers}
                  onChange={(e) => {
                    setCustomers(e.target.value)
                    handleSearch(e.target.value)
                  }}
                />

                <ButtonSelect
                  type="button"
                  onClick={handleSelectedAll}
                  loading={loadingButton}
                >
                  Selecionar todos
                </ButtonSelect>

                <ListSelect
                  labelPlaceholder={"Alterar versão para:"}
                  placeholder={"Selecionar versão"}
                  options={versaoOptions}
                  value={version}
                  onChange={(e) => setVersion(e)}
                />

                <ButtonSelect
                  type="button"
                  onClick={handleEdit}
                  loading={loadingButton}
                >
                  Salvar
                </ButtonSelect>
              </main>

              <div>
                <span />
                <li>
                  <p>CNPJ</p>
                </li>
                <li>
                <p>Razão Social</p>

                </li>
                <li>
                <p>Versão Atual</p>

                </li>
              </div>


              <ul>
                {customersList.map((customer, index) => {
                  return (
                    customer.show === true && (
                      <div key={index}>
                      <input
                        type="checkbox"
                        value={customer?.selected}
                        checked={customer?.selected === true ? true : false}
                        onChange={() => {
                          handleSelected(index)
                        }}
                      />
                      <li>
                        <p>{(customer.cnpj).replace(
                            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                            "$1.$2.$3/$4-$5"
                          )}
                        </p>
                      </li>
                      <li>
                        <p>{customer.razaoSocial}</p>
                      </li>
                      <li>
                        <p>{customer.versao}</p>
                      </li>
                    </div>
                    )
                  );
                })}
              </ul>
             </ContainerOptions>

          </>
        )}

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleEdit}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(VersionCustomer);

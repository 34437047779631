import React, { useCallback, useState } from "react";

import { useHistory } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { toast } from "react-toastify";

import ListSelect from "./../../../components/ListSelect";
import AsyncListSelect from "./../../../components/ListSelect/AsyncListSelect";
import CreatableListSelect from "./../../../components/ListSelect/CreatableListSelect";

import { getErrorsApiValidation } from "./../../../utils/getErrorsValidation";
import api from "./../../../services/api";
import { Container, CreateForm, CreateFooter } from "./styles";
import InputLabel from "./../../../components/InputLabel";
import Button from "./../../../components/ButtonDefault";

export const CreateProduct = ({ intl }) => {
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [ncm, setNcm] = useState([]);
  const [ncmInputValue, setNcmInputValue] = useState("");
  const [tipoUnitario, setTipoUnitario] = useState([]);
  const [marca, setMarca] = useState([]);
  const [categoria, setCategoria] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [file, setFile] = useState([]);

  const [status, setStatus] = useState({
    value: 1,
    label: <FormattedMessage id="ativo" />,
  });

  const digiteNCMPlaceholder = intl.formatMessage({ id: "digiteNcM" });

  const [loadingButton, setLoadingButton] = useState(false);
  const [errors, setErrors] = useState(null);

  const history = useHistory();

  const statusOptions = [
    { value: 1, label: <FormattedMessage id="ativo" /> },
    { value: 0, label: <FormattedMessage id="inativo" /> },
  ];

  const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const handleCreate = useCallback(async () => {
    setLoadingButton(true);

    const ncmArray = []
    ncm.map((n) => ncmArray.push(n.value));


    var convertFile = ""
    if (file.length !== 0){
      convertFile = await toBase64(file);
    }

    const data = {
      descricao,
      ncm: ncmArray,
      nome,
      imagem: convertFile ? convertFile : "",
      codigo: codigo,
      tipo_unitario: tipoUnitario?.value,
      marca_id: marca?.value,
      categoria_id: categoria?.value,
      tipo_produto: "outros",
      ativo: status.value,
    };

    try {
      await api.post("/produto", data);
      toast.success(<FormattedMessage id="registroCriadoComSucesso" />);
      setDescricao("");
      setNome("")
      setStatus({ value: 1, label: <FormattedMessage id="ativo" /> });
      setNcm([]);
      setCodigo("");
      setTipoUnitario([]);
      setMarca([]);
      setCategoria([])
      setFile([]);
      document.getElementById("file").value = "";

      setErrors(null);
      setLoadingButton(false);
    } catch (error) {
      setErrors(getErrorsApiValidation(error));
      setLoadingButton(false);

      toast.error(<FormattedMessage id="erroCriarRegistro" />);
    }
  }, [descricao, nome, file, codigo, tipoUnitario, marca, categoria, status, ncm]);

  const handleBackButton = useCallback(() => {
    history.goBack();
  }, [history]);


  const createOptionNCM = useCallback(
    (label) => ({
      label,
      value: label,
    }),
    []
  );

  const handleLoadBrands = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/produto-marca?ativo=1`);

      const arr = response.data.map((marca) => {
        const obj = {};

        Object.assign(obj, {
          value: marca.id,
          label: marca.nome,
        });

        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  const handleLoadCategory = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/produto-categoria?ativo=1`);

      const arr = response.data.map((categoria) => {
        const obj = {};

        Object.assign(obj, {
          value: categoria.id,
          label: categoria.nome,
        });

        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  const handleLoadType = useCallback(async () => {
    try {
      const { data: response } = await api.get(`/produto-tipo-unitarios`);

      const arr = response.map((tipo) => {
        const obj = {};

        Object.assign(obj, {
          value: tipo.id,
          label: tipo.descricao,
        });

        return obj;
      });
      return arr;
    } catch (error) {}
  }, []);

  return (
    <Container>
      <CreateForm>
        <header>
          <h4>
            <FormattedMessage id="cadastrarProduto" />
          </h4>
        </header>

        <form>
          <InputLabel
            name="nome"
            type="text"
            label={<FormattedMessage id="nome" />}
            placeholder={"Nome"}
            value={nome}
            onChange={(e) => setNome(e.target.value)}
            geterror={errors?.nome}
          />

          <InputLabel
            name="descricao"
            type="text"
            label={<FormattedMessage id="descricao" />}
            placeholder={"Descrição"}
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            geterror={errors?.descricao}
          />

          <CreatableListSelect
            isMulti
            // formatCreateLabel={() => "Criar NCM"}
            placeholder={digiteNCMPlaceholder}
            labelPlaceholder="NCM * - Pressione Enter para inserir (8 digitos)"
            inputValue={ncmInputValue}
            onChange={(newValue, actionMeta) => {
              setNcm(newValue);
            }}
            onInputChange={(inputValue, actionMeta) => {
              if (inputValue.length < 9) {
              setNcmInputValue(inputValue.replace(/[^\d]+/g, ""));
              }
            }}
            onKeyDown={(inputValue) => {
              try {
                if (inputValue.keyCode === 32 || inputValue.keyCode === 13) {
                  if (ncmInputValue.replace(" ", "").length === 8) {
                    setNcm([...ncm, createOptionNCM(ncmInputValue)]);
                    setNcmInputValue("");
                  }
                }
              } catch (error) {
                setNcm([createOptionNCM(ncmInputValue)]);
                setNcmInputValue("");
              }
            }}
            value={ncm}
            geterror={errors?.["ncm.0"]}
            menuIsOpen={false}
          />

          <AsyncListSelect
            value={tipoUnitario}
            onChange={(e) => setTipoUnitario(e)}
            labelPlaceholder={<FormattedMessage id="tipoUnitario" />}
            placeholder={<FormattedMessage id="tipoUnitario" />}
            loadOptions={handleLoadType}
            geterror={errors?.tipo_unitario}
          />

          <AsyncListSelect
            value={marca}
            onChange={(e) => setMarca(e)}
            labelPlaceholder={<FormattedMessage id="marca" />}
            placeholder={<FormattedMessage id="marca" />}
            loadOptions={handleLoadBrands}
            geterror={errors?.marca_id}
          />

          <AsyncListSelect
            value={categoria}
            onChange={(e) => setCategoria(e)}
            labelPlaceholder={<FormattedMessage id="categoria" />}
            placeholder={<FormattedMessage id="categoria" />}
            loadOptions={handleLoadCategory}
            geterror={errors?.categoria_id}
          />

          <InputLabel
            name="codigo"
            type="text"
            label={"Código de Barras"}
            placeholder={"Código de Barras"}
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            geterror={errors?.codigo}
          />

          <InputLabel
            id={"file"}
            label={<FormattedMessage id="imagem" />}
            name="file"
            type="file"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
            geterror={errors?.imagem}
          />

          <ListSelect
            value={status}
            onChange={(e) => setStatus(e)}
            labelPlaceholder="Status"
            placeholder={"Status  "}
            options={statusOptions}
          />
        </form>

        <hr />

        <CreateFooter>
          <div>
            <Button type="button" themeType="back" onClick={handleBackButton}>
              <FormattedMessage id="voltar" />
            </Button>
          </div>
          <div>
            <Button
              type="button"
              onClick={handleCreate}
              loading={loadingButton}
            >
              <FormattedMessage id="salvar" />
            </Button>
          </div>
        </CreateFooter>
      </CreateForm>
    </Container>
  );
};

export default injectIntl(CreateProduct);

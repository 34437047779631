import React from "react";
import { AuthProvider } from "./auth";
import { SideBarMenu } from "./menu";
import QueriesSelect from "./QueriesSelect";
import { RoutesProvider } from "./routes";

const AppProvider = ({ children }) => {
  return (
    <AuthProvider>
      <RoutesProvider>
        <SideBarMenu>
          <QueriesSelect>{children}</QueriesSelect>
        </SideBarMenu>
      </RoutesProvider>
    </AuthProvider>
  );
};

export default AppProvider;
